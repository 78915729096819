import { Box, withStyles } from '@material-ui/core'

const InfoBox = withStyles(() => {
    return {
        root: {
            background: 'transparent',
            color: '#F27535',
            fontWeight: '700',
            border: '1px solid #F27535',
            borderRadius: 15,
            fontSize: 16,
            lineHeight: '19px',
            textAlign: 'center',
            padding: 10,
            marginBottom: 20
        }
    }
})(Box)

export default InfoBox
