import React from 'react'
import './extensionEntry.css'

import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../util/routes'

function ExtensionEntry() {
    const history = useHistory()
    const [istermsAccepted, setIstermsAccepted] = React.useState(false)
    const [error, setError] = React.useState('')

    return (
        <div className="extensionEntry">
            <div className="text">Please click on the button below to start the survey</div>
            <button
                className="buttonOrange"
                onClick={() => {
                    if (istermsAccepted)
                        history.push({
                            pathname: ROUTES.loginCarbonSurvey
                        })
                    else setError('Please accept the privacy policy and terms and conditions to take the survey')
                }}
            >
                <Typography>Start</Typography>
            </button>
            <Typography style={{ marginTop: '24px', color: 'red' }}>{error}</Typography>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '24px' }}>
                <input
                    type="checkbox"
                    id="checkbox"
                    value={istermsAccepted}
                    onChange={() => {
                        if (!istermsAccepted === true) {
                            setError('')
                        }
                        setIstermsAccepted(!istermsAccepted)
                    }}
                    style={{ margin: '6px' }}
                />
                <label htmlFor="checkbox">
                    <Typography>
                        I have read and agreed to the{' '}
                        <span style={{ color: '#f27535' }} onClick={() => history.push(ROUTES.privacy)}>
                            privacy policy
                        </span>{' '}
                        and
                        <span style={{ color: '#f27535' }} onClick={() => history.push(ROUTES.termsAndCondtions)}>
                            {' '}
                            terms and conditions
                        </span>{' '}
                    </Typography>
                </label>
            </div>

            <Typography style={{ marginTop: '180px', textAlign: 'center' }}>
                © Copyright 2021, Tata Consultancy Services Limited. All Rights Reserved.
            </Typography>
        </div>
    )
}

export default ExtensionEntry
