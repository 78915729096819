
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SnackbarContext } from '../../Context/SnackbarContext'
import { setPageLoader } from '../../reducer/applicationInformation'
import submitCarbonSurveyThunk from '../../redux-thunk/submitCarbonSurveyThunk'
import {
    hasFollowUpQuestions,
    isArray,
    isCheckboxQuestionAnswered,
    isCheckTypeQuestion,
    isRadioQuestionAnswered,
    isRadioTypeQuestion,
    isTextQuestionAnswered,
    isTextTypeQuestion
} from '../../util/isCheckTypeQuestion'
import { ROUTES } from '../../util/routes'

export function useSurveyActionHook({ surveyQuestions, submitSurvey, surveyKey, surveyName }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const { handleClick } = useContext(SnackbarContext)

    // To track the current selected Question
    const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0)

    // To make a clone of passed survey questions, which will be updated and passed to sevivce
    const [surevyClone, setSurveyClone] = useState(null)
    const [error, setError] = useState('')

    const defaultSurveyValues = useSelector((state) => state.applicationInfo.defaultSurveyValues)

    const { defaultCarbonSurveyValues } = useSelector((state) => state.extension)

    const isDigizen = useSelector((state) => state.applicationInfo.inDigizen)
    // Effect hook to updated the cloned question set that we need to pass
    // NOTE: Not expecting to get survey update once a survey has been started
    useEffect(() => {
        if ((surevyClone === null || surevyClone.length <= 0) && surveyQuestions) {
            setSurveyClone(surveyQuestions)
            setCurrentQuestionIdx(0)
        }
    }, [surveyQuestions, surevyClone, setCurrentQuestionIdx])

    // Callback function to update the survey array
    // Expext  the updated Question Object, and will match the id key with same.
    const isQuestionAnswered = useCallback((singleQuestion) => {
        let isValid = true
        if (!isArray(singleQuestion)) {
            if (isCheckTypeQuestion(singleQuestion)) {
                if (
                    !isCheckboxQuestionAnswered(singleQuestion) ||
                    !singleQuestion.questionOptions.every((option) =>
                        option.isSelected
                            ? hasFollowUpQuestions(option)
                                ? option.isFollowUp.every(isQuestionAnswered)
                                : true
                            : true
                    )
                ) {
                    isValid = false
                }
            } else if (isRadioTypeQuestion(singleQuestion)) {
                if (
                    !isRadioQuestionAnswered(singleQuestion) ||
                    !singleQuestion.questionOptions.every((option) =>
                        option.isSelected
                            ? hasFollowUpQuestions(option)
                                ? option.isFollowUp.every(isQuestionAnswered)
                                : true
                            : true
                    )
                ) {
                    isValid = false
                }
            } else if (isTextTypeQuestion(singleQuestion) && !isTextQuestionAnswered(singleQuestion)) {
                isValid = false
            }
        } else {
            isValid = singleQuestion.every(isQuestionAnswered)
        }
        return isValid
    }, [])
    const isSurveyAnswered = useCallback(
        (survey) => {
            return survey.every(isQuestionAnswered)
        },
        [isQuestionAnswered]
    )
    const moveBack = useCallback(() => {
        setCurrentQuestionIdx((count) => {
            const value = count - 1
            if (value < 0) {
                if (isDigizen) {
                    history.push(ROUTES.surveyList)
                } else {
                    history.push(ROUTES.extensionList)
                }
                return count
            }
            setError('')
            return value
        })
    }, [history, isDigizen])

    const moveNext = useCallback(() => {
        setCurrentQuestionIdx((count) => {
            if (isQuestionAnswered(surevyClone[count])) {
                setError('')
                return Math.min(count + 1, surevyClone.length)
            } else {
                setError('Please answer this question')
                return count
            }
        })
    }, [isQuestionAnswered, surevyClone])

    const onSkip = useCallback(() => {
        console.log(defaultSurveyValues, surveyName);
        setCurrentQuestionIdx((count) => {
            if (isDigizen) {
                console.log('line 122');
                surevyClone[count] = defaultSurveyValues[surveyName][count]
            } else {
                console.log('line 125');
                surevyClone[count] = defaultCarbonSurveyValues[count]
            }
            setError('')
            return Math.min(count + 1, surevyClone.length)
        })
    }, [surevyClone, defaultSurveyValues, defaultCarbonSurveyValues, isDigizen, surveyName])

    const onSubmit = useCallback((surveyClick) => {
        if (isSurveyAnswered(surevyClone)) {
            if (isDigizen) {
                submitSurvey(surevyClone)
                    .then(() => {
                        setSurveyClone(null)
                        setError('')
                        console.log('hitting diz block')
                        if (surveyClick === 'from plugin') {
                            console.log('hitting diz block from plugin')
                            history.push("/mini");
                           
                            // history.push({
                            //     pathname: ROUTES.pledgeList
                            // })


                        }
                        else {
                            console.log('hitting diz block from c4l app')
                            history.push({
                                pathname: ROUTES.eachResult,
                                state: {
                                    type: surveyName.split(' ')[0].toLowerCase(),
                                    id: surveyKey
                                }
                            })

                        }
                    })
                    .catch((err) => {
                        handleClick(err.message)
                    })
                    .finally(() => {
                        dispatch(setPageLoader(false))
                    })
            } else {
                submitSurvey(surevyClone)
                dispatch(submitCarbonSurveyThunk())
                    .then((isC4LUser) => {
                        setSurveyClone(null)
                        setError('')
                        if (isC4LUser) {
                            history.push(ROUTES.verifyCarbonSurvey)
                        } else {
                            history.push(ROUTES.extensionResult)
                        }
                    })
                    .catch((err) => {
                        setError('Something Went Wrong!!!')
                    })
            }
        } else {
            setError('Please answer the Question')
            dispatch(setPageLoader(false))
        }
    }, [surevyClone, submitSurvey, isSurveyAnswered, surveyKey, history, surveyName, handleClick, isDigizen, dispatch])

    const setCheckboxOption = useCallback(
        (questionId) => (optionId) => () => () =>
            setSurveyClone((preivoiusSurveyQuestions) =>
                preivoiusSurveyQuestions.map((mainPQ) => {
                    return {
                        ...mainPQ,
                        ...(mainPQ.hasOwnProperty('questionOptions')
                            ? {
                                questionOptions:
                                    mainPQ.id === questionId
                                        ? [...mainPQ.questionOptions].map((mainPrevOption) => {
                                            return {
                                                ...mainPrevOption,
                                                isSelected:
                                                    mainPrevOption.id === optionId
                                                        ? !mainPrevOption.isSelected
                                                        : mainPrevOption.isSelected
                                            }
                                        })
                                        : [...mainPQ.questionOptions]
                            }
                            : {})
                    }
                })
            ),
        []
    )
    const setRadioOption = useCallback(
        (questionId) => (optionId) => () => {
            return setSurveyClone((preivoiusSurveyQuestions) =>
                preivoiusSurveyQuestions.map((mainPQ) => {
                    return {
                        ...mainPQ,
                        ...(mainPQ.hasOwnProperty('questionOptions')
                            ? {
                                questionOptions:
                                    mainPQ.id === questionId
                                        ? [...mainPQ.questionOptions].map((mainPrevOption) => {
                                            return {
                                                ...mainPrevOption,
                                                isSelected: mainPrevOption.id === optionId ? true : false
                                            }
                                        })
                                        : [...mainPQ.questionOptions]
                            }
                            : {})
                    }
                })
            )
        },
        []
    )
    const setTextOption = useCallback(
        (questionId) => (value) => {
            return setSurveyClone((preivoiusSurveyQuestions) =>
                preivoiusSurveyQuestions.map((mainPQ) => {
                    return {
                        ...mainPQ,
                        ...(mainPQ.hasOwnProperty('answer')
                            ? {
                                answer: mainPQ.id === questionId ? value : mainPQ.answer
                            }
                            : {})
                    }
                })
            )
        },
        []
    )

    const selectFUQRadio = useCallback(
        (questionId) => (optionId) => (fuqId) => (fuqOptionId) => {
            // console.log(questionId, optionId, fuqId, fuqOptionId)
            return setSurveyClone((preivoiusSurveyQuestions) =>
                preivoiusSurveyQuestions.map((mainPQ) => {
                    return {
                        ...mainPQ,
                        ...(mainPQ.hasOwnProperty('questionOptions')
                            ? {
                                questionOptions:
                                    mainPQ.id === questionId
                                        ? [...mainPQ.questionOptions].map((mainPrevOption) => {
                                            return {
                                                ...mainPrevOption,
                                                ...(mainPrevOption.id === optionId && mainPrevOption.isFollowUp
                                                    ? {
                                                        isFollowUp: mainPrevOption.isFollowUp.map(
                                                            (singleFUQ) => ({
                                                                ...singleFUQ,
                                                                ...(singleFUQ.hasOwnProperty('questionOptions')
                                                                    ? {
                                                                        questionOptions:
                                                                            singleFUQ.id === fuqId
                                                                                ? singleFUQ.questionOptions.map(
                                                                                    (singleFuqOption) => {
                                                                                        //   console.log(
                                                                                        //       '>>',
                                                                                        //       singleFuqOption
                                                                                        //   )

                                                                                        if (
                                                                                            singleFuqOption.id ===
                                                                                            fuqOptionId
                                                                                        ) {
                                                                                            //   console.log(
                                                                                            //       singleFuqOption
                                                                                            //   )
                                                                                        }
                                                                                        return {
                                                                                            ...singleFuqOption,
                                                                                            isSelected:
                                                                                                singleFuqOption.id ===
                                                                                                    fuqOptionId
                                                                                                    ? true
                                                                                                    : false
                                                                                        }
                                                                                    }
                                                                                )
                                                                                : singleFUQ.questionOptions
                                                                    }
                                                                    : {})
                                                            })
                                                        )
                                                    }
                                                    : {})
                                            }
                                        })
                                        : [...mainPQ.questionOptions]
                            }
                            : {})
                    }
                })
            )
        },
        []
    )

    const setFUQText = useCallback(
        (questionId) => (optionId) => (fuqId) => (answerValue) => {
            return setSurveyClone((preivoiusSurveyQuestions) =>
                preivoiusSurveyQuestions.map((mainPQ) => {
                    return {
                        ...mainPQ,
                        ...(mainPQ.hasOwnProperty('questionOptions')
                            ? {
                                questionOptions:
                                    mainPQ.id === questionId
                                        ? [...mainPQ.questionOptions].map((mainPrevOption) => ({
                                            ...mainPrevOption,
                                            ...(mainPrevOption.id === optionId && mainPrevOption.isFollowUp
                                                ? {
                                                    isFollowUp: mainPrevOption.isFollowUp.map((singleFUQ) => ({
                                                        ...singleFUQ,
                                                        ...(singleFUQ.hasOwnProperty('answer')
                                                            ? {
                                                                answer:
                                                                    singleFUQ.id === fuqId
                                                                        ? answerValue
                                                                        : singleFUQ.answer
                                                            }
                                                            : {})
                                                    }))
                                                }
                                                : {})
                                        }))
                                        : [...mainPQ.questionOptions]
                            }
                            : {})
                    }
                })
            )
        },
        []
    )

    const handleArrayQuestions = useCallback(
        (activeQuestionIdx) => (questionId) => (checkBoxId) => (value) => {
            // console.log(activeQuestionIdx, questionId, checkBoxId, value)
            setSurveyClone((preivoiusSurveyQuestions) =>
                preivoiusSurveyQuestions.map((mainPQ, mainPQIdx) =>
                    !isArray(mainPQ[0]) && activeQuestionIdx === mainPQIdx
                        ? mainPQ.map((singleQuestion) =>
                            singleQuestion.id === questionId
                                ? {
                                    ...singleQuestion,
                                    ...(singleQuestion.hasOwnProperty('answer')
                                        ? { answer: value }
                                        : {
                                            questionOptions: singleQuestion.questionOptions.map(
                                                (singleOption) => ({
                                                    ...singleOption,
                                                    isSelected: singleOption.id === checkBoxId ? true : false
                                                })
                                            )
                                        })
                                }
                                : singleQuestion
                        )
                        : mainPQ
                )
            )
        },
        []
    )
    useEffect(() => {
        // console.log(surevyClone)
    }, [surevyClone])

    return {
        currentQuestionIdx,
        error,
        setError,
        setFUQText,
        selectFUQRadio,
        setRadioOption,
        setTextOption,
        setCheckboxOption,
        handleArrayQuestions,
        surevyClone,
        onSubmit,
        onSkip,
        moveNext,
        moveBack
    }
}
