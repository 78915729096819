let initialState = {}

const surveyList = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGOUT':
            return initialState
        default:
            return state
    }
}

export default surveyList
