import { Box, Typography } from '@material-ui/core'
import React from 'react'
import CompleteCustomAccordian from '../Customized'
import { HandleRadioFUQ } from './HandleRadioFUQ'
import { HandleTextOptions } from './HandleTextOptions'

export const FollowUpQuestion = ({ fuq, selectFUQRadio, setFUQText }) => {
    if (fuq.optionType.toLowerCase() === 'radio') {
        return (
            <HandleRadioFUQ
                withWrapper
                questionText={fuq.questionName}
                questionOptions={fuq.questionOptions}
                key={fuq.id}
                onChange={selectFUQRadio}
            />
        )
    }
    if (fuq.optionType.toLowerCase() === 'text') {
        return (
            <>
                <div style={{ height: 2 }}></div>
                <CompleteCustomAccordian flag={true} withSelectedFlag={false} onChange={() => {}}>
                    <Box style={{ flex: 'column', width: '100%' }}>
                        <Box style={{ paddingBottom: 10 }}>
                            <Typography
                                variant="body1"
                                style={{
                                    font: 'normal normal normal 16px/30px Roboto',
                                    letterSpacing: 0,
                                    color: '#333333',
                                    fontWeight: '700',
                                    margin: '0px'
                                }}
                            >
                                {fuq.questionName}
                            </Typography>
                        </Box>
                        <Box>
                            <HandleTextOptions withSelectedFlag={false} onChange={setFUQText} question={fuq} />
                        </Box>
                    </Box>
                </CompleteCustomAccordian>
            </>
        )
    }
    return null
}
