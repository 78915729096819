import React from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../util/routes'
import EmployeeCommute from './img/Employee-Commute.png'
import SustainabilityCourse from './img/Sustainability-Course.png'
import './banner.css'
function Banner() {
    const history = useHistory()
    return (
        <div className='banner-height-adjuster-mobile' >
            <div className="styledimages">
                <img
                    src={EmployeeCommute}
                    alt="EmployeeCommute"
                    className="singleImg"
                    onClick={() => {
                        history.push(ROUTES.banner + '/EmployeeCommute')
                    }}
                />
                <img
                    src={SustainabilityCourse}
                    alt="SustainabilityCourse"
                    className="singleImg"
                    onClick={() => {
                        history.push(ROUTES.banner + '/SustainabilityCourse')
                    }}
                />
            </div>
        </div>
    )
}

export default Banner
