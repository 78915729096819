import axios from 'axios'
import {
    savedefaultSurveyValues,
    saveFootprintRanges,
    saveFootprintSurveys,
    saveGlobalFootprintResult
} from '../reducer/applicationInformation'
import { setSessionToken } from '../reducer/session'
import { addUniqueId } from '../util/addUniqueId'
import { API_ROUTES } from '../util/routes'

function getFootprintSurveyListThunk(session) {
    return async function (dispatch, getState, { endpoint }) {
        const token = session
        // const token = getState().session.token
        const { data } = await axios.get(endpoint + API_ROUTES.FETCH_SURVEY_QUESTIONS, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (data.status !== 'success') {
            throw new Error('Reponse Status Invalid')
        }
        let defaultSurveyValues = data.defaultSurveyValues

        for (const x in defaultSurveyValues) {
            if (defaultSurveyValues.hasOwnProperty(x)) {
                defaultSurveyValues[x] = addUniqueId(defaultSurveyValues[x])
            }
        }

        // console.log('data>>>', data)
        dispatch(saveFootprintSurveys(addUniqueId(data.surveyList)))
        dispatch(saveFootprintRanges(data.footprintRanges))
        dispatch(savedefaultSurveyValues(defaultSurveyValues))
        dispatch(saveGlobalFootprintResult(data.footprint))
        dispatch(setSessionToken(token))
        return data
    }
}

export default getFootprintSurveyListThunk
