import { applyMiddleware, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { firebaseConfig } from '..'
import rootReducer from '../reducer'

require('dotenv').config()

// console.log(require('dotenv').config())
const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['surveyList', 'layout', 'session']
}

const projectId = firebaseConfig.projectId
const persistedReducer = persistReducer(persistConfig, rootReducer)
let store = createStore(
    persistedReducer,
    applyMiddleware(
        thunk.withExtraArgument({
            endpoint: `https://us-central1-${projectId}.cloudfunctions.net/`
            // https://us-central1-tcs-salsa.cloudfunctions.net/
            //https://us-central1-tcs-salsa.cloudfunctions.net/
             //backend endpoint
        })
    )
)

store.subscribe(() => {
    console.log('>>', store.getState())
})
let persistor = persistStore(store)

const getStore = () => {
    return { store, persistor }
}

export default getStore
