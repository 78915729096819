import CryptoJS from 'crypto-js'
import { firebaseAuth, firebaseRemoteConfig } from '..'
import { setemailId, setuid } from '../reducer/session'
function verifyUserThunk(dispatch, sessiontoken) {
    // const dispatch = useDispatch()
    async function returnValue(sessiontoken) {
        await firebaseRemoteConfig.fetchAndActivate()
        const ENCRYPTION_KEY = firebaseRemoteConfig.getValue('aes_key').asString()
        const ENCRYPTION_IV = firebaseRemoteConfig.getValue('aes_iv').asString()

        // signin with custom token
        var userRef = await firebaseAuth.signInWithCustomToken(sessiontoken)
        console.log(userRef.user.email)
        dispatch(setemailId(userRef.user.email))
        dispatch(setuid(userRef.user.uid))
        // get the token
        var token = await userRef.user.getIdToken()
        // encrypt the token

        // let cipher = crypto.createCipheriv('aes-256-cbc', ENCRYPTION_KEY, ENCRYPTION_IV);
        // let encrypted = cipher.update(token, 'utf8', 'base64');
        // encrypted += cipher.final('base64');
        // console.log(encrypted)
        var encryptedAES = CryptoJS.AES.encrypt(token, CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY), {
            mode: CryptoJS.mode.CBC,
            iv: CryptoJS.enc.Utf8.parse(ENCRYPTION_IV)
        })
        // console.log(encryptedAES.ciphertext.toString(CryptoJS.enc.Base64))
        const finalToken = encryptedAES.ciphertext.toString(CryptoJS.enc.Base64)
        var TokenArray = finalToken.split('/')
        var encryptedToken = TokenArray.join('*c4l*')
        return encryptedToken
    }
    return returnValue(sessiontoken)
}

export default verifyUserThunk
