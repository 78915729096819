import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Appstore from '../assets/homepage_assets/Appstore.svg'
import welcome_banner from '../assets/homepage_assets/Banner Top.png'
import c4l_loader from '../assets/homepage_assets/C4L_Loader.gif'
import Googleplay from '../assets/homepage_assets/Googleplay.svg'
import Mock_Image from '../assets/homepage_assets/Mocks.png'
import Podcast_Image from '../assets/homepage_assets/Podcast_Feature.png'
import footer_image from '../assets/homepage_assets/footer_image.png'
import underline_red from '../assets/homepage_assets/underline_red.svg'
import Header from '../components/homepage_components/Header/Header'
import Text from '../components/homepage_components/Text'
import Features from './Features'
import './features.css'
import './landingScreen.css'
import EngagementScreen from './EngagementsTab/EngagementScreen'
import blogs_1 from '../assets/homepage_assets/get_sustainable_image_1.png'
import blogs_2 from '../assets/homepage_assets/get_sustainable_image_2.png'
import co2 from '../assets/homepage_assets/co2_emission.svg'
import trees from '../assets/homepage_assets/trees_planted.svg'
import { ROUTES } from '../util/routes'

const LandingScreen = () => {
    const [loading, setLoading] = useState(false)

    const date = new Date()

    const year = date.getFullYear()

    const card_list = [
        { image: co2, value: '145 Tones', text: 'of CO2 Offset', name: 'co2' },
        {
            image: trees,
            value: '100K',
            text: 'of Trees to be planted',
            name: 'Plant'
        }
    ]

    return (
        <div className="main_div">
            {!loading ? (
                <div className="welcome_banner_container">
                    {' '}
                    <div className="welcome_banner">
                        <Header className="header_div" />
                        <img
                            className="welcome_banner_image"
                            // src={homepage.homePasgeBackgroundImgUrl}
                            src={welcome_banner}
                            alt="welcome_banner"
                        />
                        <div className="welcome_banner_text">
                            <Text
                                $weight="600"
                                $size="clamp(1.5rem,4.5vw,3rem)"
                                $line_height="clamp(2.5rem,4.5vw,4rem)"
                                $font="Houschka Rounded Alt"
                                $color="#FFFFFF"
                            >
                                Start your sustainability journey through TCS Circle4Life.
                            </Text>
                            <Text
                                $color="#FFFFFF"
                                $font="Poppins"
                                // $size="18px"
                                // $line_height="30px"
                                $size="clamp(1rem,1.25vw,1.5rem)"
                                $line_height="clamp(1.5rem,3vw,2rem)"
                            >
                                It’s never too late to contribute to a better future. Circle4Life is a platform to help
                                you choose a more sustainable lifestyle, for yourself and the planet. It lets you
                                calculate and track your ecological footprint, take pledges, plant trees and register
                                it.
                            </Text>
                            <Text $size="15px" $line_height="26px" $font="Poppins" $color="#FFF">
                                The mobile application is only available for TCS Employees.
                            </Text>
                            <div className="download_redirect_container">
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.tcs.circle4life"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={Googleplay} alt="Google playstore link" />
                                </a>
                                <a
                                    href="https://apps.apple.com/in/app/tcs-circle4life/id1593154523"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={Appstore} alt="Appstore link" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="offset_container">
                        {card_list.map((card, id) => (
                            <div className="white_card" key={id}>
                                <div className="icon_div">
                                    {' '}
                                    <img src={card.image} alt="Icon" />
                                </div>
                                <div className="card_text">
                                    <Text
                                        $weight="600"
                                        $size="30px"
                                        $line_height="47px"
                                        $font="Houschka Rounded Alt"
                                        $color="#8F8F8F"
                                    >
                                        <span className="units">{card.value}</span>
                                        {card.text}
                                    </Text>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="loader_containers">
                    <img className="loader" src={c4l_loader} alt="loader" />
                </div>
            )}
            <Features />
            {/* <EngagementScreen /> */}
            <div className="app_features_div">
                <img className="podcast_feature_image" src={Podcast_Image} alt="Podcast feature" />
                <img className="mock_feature_image" src={Mock_Image} alt="app featutes" />
            </div>
            <div className="get_sustainable_container">
                <div className="get_sustainable_content">
                    <Text $weight="600" $size="37px" $line_height="45px" $font="Houschka Rounded Alt" $color="#000000">
                        Get Sustaina
                        <span className="decorated_text">
                            ble
                            <img className="underline_image" alt="red underline for design" src={underline_red} />
                        </span>{' '}
                        life with Circle4Life
                    </Text>
                    <Text $weight="400" $size="18px" $line_height="27px" $font="Houschka Rounded Alt" $color="#707070">
                        Embrace sustainability effortlessly with our app designed to support your eco-friendly
                        lifestyle. Discover sustainable products, access tips for reducing waste, and track your
                        environmental impact with ease. By making small changes in your daily habits, you can contribute
                        to a greener future for generations to come. Download now and join the movement towards a more
                        sustainable lifestyle.
                    </Text>
                </div>
                <div className="get_sustainable_image">
                    <div className="image_left_container">
                        <img
                            height="100%"
                            className="sustainable_image"
                            src={blogs_1}
                            alt="A screenshot from Circle4Life application showing blogs"
                        />
                    </div>
                    <div className="image_right_container">
                        <img
                            height="100%"
                            className="sustainable_image"
                            src={blogs_2}
                            alt="A screenshot from Circle4Life application showing blogs"
                        />
                    </div>
                </div>
            </div>
            <div className="footer_contanier">
                <div className="footer_upper">
                    <div className="footer_image_container">
                        <img className="footer_image" src={footer_image} alt="C4L mobile application snapshots" />
                    </div>
                    <div className="footer_content_container">
                        <Text
                            $weight="600"
                            $size="37px"
                            $line_height="45px"
                            $font="Houschka Rounded Alt"
                            $color="#000000"
                        >
                            Download Ci
                            <span className="decorated_text">
                                rcle4Life
                                <img className="underline_image" alt="red underline for design" src={underline_red} />
                            </span>{' '}
                            App from
                        </Text>
                        <div className="links_container">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.tcs.circle4life"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={Googleplay} alt="Google playstore link" />
                            </a>
                            <a
                                href="https://apps.apple.com/in/app/tcs-circle4life/id1593154523"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={Appstore} alt="Appstore link" />
                            </a>
                        </div>
                        <Text
                            $weight="400"
                            $size="17px"
                            $line_height="26px"
                            $font="Poppins"
                            $color="#999999"
                            $alignment="center"
                            style={{ width: '80%' }}
                        >
                            The mobile application is only available for TCS Employees.
                        </Text>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="footer_content">
                        <div className="contact_us">
                            <Text
                                $weight="600"
                                $size="20px"
                                $line_height="30px"
                                $font="Poppins"
                                $color="#FFFFFF"
                                $alignment="center"
                            >
                                Contact Us
                            </Text>
                            <Text
                                $weight="400"
                                $size="16px"
                                $line_height="24px"
                                $font="Poppins"
                                $color="#FFFFFF"
                                $alignment="left"
                            >
                                We’re happy to answer your queries. Please drop us an email at
                            </Text>
                            <Text $weight="400" $size="14px" $line_height="20px" $font="Poppins" $color="#DDDDDD">
                                <a
                                    style={{ textDecoration: 'none', color: '#DDDDDD' }}
                                    href="mailto:cirlce4life.support@tcs.com"
                                >
                                    cirlce4life.support@tcs.com
                                </a>
                            </Text>
                        </div>

                        <div className="data_privacy">
                            <Text
                                $weight="400"
                                $size="20px"
                                $line_height="30px"
                                $font="Poppins"
                                $color="#FFFFFF"
                                $alignment="center"
                            >
                                Data Privacy
                            </Text>
                            <Text
                                $weight="400"
                                $size="14px"
                                $line_height="20px"
                                $font="Poppins"
                                $color="#DDDDDD"
                                // style={{ cursor: "pointer" }}
                            >
                                <Link style={{ textDecoration: 'none', color: '#DDDDDD' }} to={ROUTES.PRIVACY}>
                                    Privacy Policy
                                </Link>
                            </Text>
                            <Text $weight="400" $size="14px" $line_height="20px" $font="Poppins" $color="#DDDDDD">
                                <Link style={{ textDecoration: 'none', color: '#DDDDDD' }} to={ROUTES.TERMS}>
                                    Terms and Conditions
                                </Link>
                            </Text>
                        </div>
                    </div>
                    <div className="copyright">
                        <div style={{ marginTop: 'auto', marginBottom: '5px' }}>
                            <Text $weight="400" $size="14px" $line_height="20px" $font="Poppins" $color="#DDDDDD">
                                {`© Copyright ${year}, Tata Consultancy Services Limited. All Rights
                Reserved.`}{' '}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingScreen
