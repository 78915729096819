import { Accordion, withStyles } from '@material-ui/core'

const CustomAccordion = withStyles(() => ({
    root: {
        width: '100%',
        boxShadow: 'none',
        border: 'none',
        borderRadius: 0,
        background: 'transparent',
        margin: '4px 0px',

        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: '4px 0px',
            '&:last-child': {
                marginBottom: 4
            }
        }
    },
    expanded: {
        background: 'transparent'
    }
}))(Accordion)

export default CustomAccordion
