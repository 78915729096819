import React from 'react'
const CardConsumptionIcon = ({ status = 'inactive', size = 30 }) => {
    if (status === 'active') {
        return (
            <svg
                viewBox="0 0 48.43 48.43"
                width={size}
                height={size}
                stroke="white"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <defs></defs>
                <circle fill="#4E84C4" strokeWidth="2" cx="24.21" cy="24.21" r="22.5" />
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path
                            class="cls-1"
                            fill="#fff"
                            d="M3,9.42a65.25,65.25,0,0,0,1.11,9.35c.66,5.29-4.14,4.93-3.32,0,.73-5.44,1-5.8,1-9.35A2.25,2.25,0,0,1,0,7.1l.37-6C.51.58.37.14,1,.14V7c0,.29.59.29.59,0V0h.51V7c0,.29.59.29.59,0V0h.52V7c0,.29.66.29.66,0L3.69.14c.59,0,.59.44.59,1l.37,6A2.19,2.19,0,0,1,3,9.42Z"
                        />
                        <path
                            class="cls-1"
                            fill="#fff"
                            fillRule="evenodd"
                            d="M24.92,20.15a39.3,39.3,0,0,0,.81-5.95c.07-.65-.08-1.16-1.26-1.52-.07-.72-.07-1.38-.14-2.1C24.11,4.78,25.14,0,26.61,0a1.12,1.12,0,0,1,.45.14,64.15,64.15,0,0,0,0,7.61,75.79,75.79,0,0,0,1.18,12.4C29,23.55,24.11,23.34,24.92,20.15Z"
                        />
                        <polygon
                            class="cls-2"
                            fill="#fff"
                            points="19.09 10 14.76 10.1 17.3 5.02 9.18 12.13 13.56 12.11 11.1 17.26 19.09 10"
                        />
                        <circle
                            class="cls-3"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="1.05px"
                            cx="14.14"
                            cy="11.15"
                            r="8.09"
                        />
                        <circle
                            class="cls-4"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="0.49px"
                            cx="14.14"
                            cy="11.14"
                            r="9.38"
                        />
                    </g>
                </g>
            </svg>
        )
    }
    if (status === 'completed') {
        return (
            <svg
                viewBox="0 0 48.43 48.43"
                width={size}
                height={size}
                stroke="white"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <circle fill="#12AF7B" strokeWidth="2" cx="24.21" cy="24.21" r="22.5" />
                        <polygon
                            strokeWidth="1"
                            stroke="#12AF7B"
                            points="18.63 28.88 17.08 27.55 21.42 27.55 18.63 28.88"
                        />
                        <path
                            strokeWidth="1"
                            stroke="#12AF7B"
                            d="M23.21,27.55H34v1a2.39,2.39,0,0,1-.54,1.72,2.35,2.35,0,0,1-1.59.86H16.43a2.35,2.35,0,0,1-1.59-.86,2.38,2.38,0,0,1-.53-1.72v-1h1.57l2.44,2.08a.4.4,0,0,0,.2.09.41.41,0,0,0,.22,0Z"
                        />
                        <path
                            strokeWidth="1"
                            stroke="#12AF7B"
                            d="M24.15,13.35c-5.6,0-10.16,3.31-10.16,7.39,0,.11,0,.22,0,.33H34.28a1.83,1.83,0,0,0,0-.33C34.3,16.66,29.75,13.35,24.15,13.35Zm-6.83,5.87a1.6,1.6,0,0,1-1,.63,1.52,1.52,0,0,1,0-1.15,1.54,1.54,0,0,1,1-.63A1.57,1.57,0,0,1,17.32,19.22ZM19.7,15.6a1.56,1.56,0,0,1,1-.63,1.58,1.58,0,0,1,0,1.15,1.58,1.58,0,0,1-1,.63A1.52,1.52,0,0,1,19.7,15.6Zm1.22,4.21a1.56,1.56,0,0,1-.93-.67A1.57,1.57,0,0,1,20,18a1.56,1.56,0,0,1,.93.67A1.52,1.52,0,0,1,20.92,19.81Zm2.32-2.06a1.51,1.51,0,0,1-.65-.94A1.48,1.48,0,0,1,23,15.74a1.62,1.62,0,0,1,.66.94A1.53,1.53,0,0,1,23.24,17.75Zm2.48,1.71a1.56,1.56,0,0,1-1,.63,1.53,1.53,0,0,1,0-1.15,1.55,1.55,0,0,1,1-.63A1.57,1.57,0,0,1,25.72,19.46Zm1.39-3.34a1.6,1.6,0,0,1-1,.63,1.57,1.57,0,0,1,0-1.15,1.56,1.56,0,0,1,1-.63A1.57,1.57,0,0,1,27.11,16.12Zm2.94,3.61a1.54,1.54,0,0,1-1,.62,1.57,1.57,0,0,1,0-1.15,1.59,1.59,0,0,1,1-.62A1.57,1.57,0,0,1,30.05,19.73Zm.16-2.28a1.57,1.57,0,0,1-.93-.68,1.58,1.58,0,0,1,.07-1.15,1.58,1.58,0,0,1,.92.68A1.63,1.63,0,0,1,30.21,17.45Z"
                        />
                        <path
                            strokeWidth="1"
                            stroke="#12AF7B"
                            d="M12.15,22.22a.35.35,0,0,1,0-.08l.08-.05a1.37,1.37,0,0,1,.4-.09l.17,0a1.31,1.31,0,0,1,.28,0,1.22,1.22,0,0,0,.63-.11H34.28a1,1,0,0,0,.2.31.84.84,0,0,0,.3.22c.3.14.33.23.33.23a.7.7,0,0,1-.37.17,6.78,6.78,0,0,1-1.62.14.92.92,0,0,0-.95.55l-.08.11a1.92,1.92,0,0,1-.57.6,2,2,0,0,1-.77.29h0a.85.85,0,0,1-.46-.28,1.88,1.88,0,0,0-.63-.4,1.81,1.81,0,0,0-.73-.12,2.4,2.4,0,0,0-.83.24,4.17,4.17,0,0,0-1.06.57l-.28.25a2.26,2.26,0,0,1-.29.25,1.06,1.06,0,0,1-.49.2,1.16,1.16,0,0,1-.52-.05c-.7-.5-2-1.45-3.28-.84a4.68,4.68,0,0,0-.54.3,3.78,3.78,0,0,1-.43.26.89.89,0,0,1-.36.11.73.73,0,0,1-.36,0,1.33,1.33,0,0,1-.26-.21l-.05-.05a4.63,4.63,0,0,0-.9-.68,1.43,1.43,0,0,0-.72-.26,1.62,1.62,0,0,0-.88.29l-.07,0a2.91,2.91,0,0,1-1.35.4,1.67,1.67,0,0,1-.62-.12A1.59,1.59,0,0,1,15.1,24L15,23.77a2.43,2.43,0,0,0-.24-.32,2.93,2.93,0,0,0-1.2-.68l-1-.37C12.16,22.29,12.15,22.22,12.15,22.22Z"
                        />
                        <path
                            strokeWidth="1"
                            stroke="#12AF7B"
                            d="M36.15,25.69a1.13,1.13,0,0,1-.86,1.09H13a1.1,1.1,0,0,1-.61-.4,1.07,1.07,0,0,1-.24-.69,1.09,1.09,0,0,1,.24-.69,1.08,1.08,0,0,1,.61-.39h1.65a2.28,2.28,0,0,0,1.59.61,3.65,3.65,0,0,0,1.73-.5l.07,0a1.14,1.14,0,0,1,.47-.19.73.73,0,0,1,.35.15,4.39,4.39,0,0,1,.78.58l0,.05a2,2,0,0,0,.43.33,1.58,1.58,0,0,0,.71.14,1.61,1.61,0,0,0,.71-.2l.51-.29a2.51,2.51,0,0,1,.45-.26c.73-.34,1.53.06,2.5.76a1.25,1.25,0,0,0,.75.22,2.1,2.1,0,0,0,1.17-.36,2.35,2.35,0,0,0,.37-.33l.23-.2a3.3,3.3,0,0,1,.87-.46,2.05,2.05,0,0,1,.58-.18,1.09,1.09,0,0,1,.4.07,1.39,1.39,0,0,1,.41.27,1.53,1.53,0,0,0,.95.46,2.26,2.26,0,0,0,1.48-.63h3.09a1.11,1.11,0,0,1,.62.39A1.15,1.15,0,0,1,36.15,25.69Z"
                        />
                    </g>
                </g>
            </svg>
        )
    } else {
        return (
            <svg
                viewBox="0 0 48.43 48.43"
                width={size}
                height={size}
                stroke="#999999"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <circle fill="white" strokeWidth="2" cx="24.21" cy="24.21" r="22.5" />
                        <polygon
                            strokeWidth="1"
                            stroke="#999999"
                            points="18.63 28.88 17.08 27.55 21.42 27.55 18.63 28.88"
                        />
                        <path
                            strokeWidth="1"
                            stroke="#999999"
                            d="M23.21,27.55H34v1a2.39,2.39,0,0,1-.54,1.72,2.35,2.35,0,0,1-1.59.86H16.43a2.35,2.35,0,0,1-1.59-.86,2.38,2.38,0,0,1-.53-1.72v-1h1.57l2.44,2.08a.4.4,0,0,0,.2.09.41.41,0,0,0,.22,0Z"
                        />
                        <path
                            strokeWidth="1"
                            stroke="#999999"
                            d="M24.15,13.35c-5.6,0-10.16,3.31-10.16,7.39,0,.11,0,.22,0,.33H34.28a1.83,1.83,0,0,0,0-.33C34.3,16.66,29.75,13.35,24.15,13.35Zm-6.83,5.87a1.6,1.6,0,0,1-1,.63,1.52,1.52,0,0,1,0-1.15,1.54,1.54,0,0,1,1-.63A1.57,1.57,0,0,1,17.32,19.22ZM19.7,15.6a1.56,1.56,0,0,1,1-.63,1.58,1.58,0,0,1,0,1.15,1.58,1.58,0,0,1-1,.63A1.52,1.52,0,0,1,19.7,15.6Zm1.22,4.21a1.56,1.56,0,0,1-.93-.67A1.57,1.57,0,0,1,20,18a1.56,1.56,0,0,1,.93.67A1.52,1.52,0,0,1,20.92,19.81Zm2.32-2.06a1.51,1.51,0,0,1-.65-.94A1.48,1.48,0,0,1,23,15.74a1.62,1.62,0,0,1,.66.94A1.53,1.53,0,0,1,23.24,17.75Zm2.48,1.71a1.56,1.56,0,0,1-1,.63,1.53,1.53,0,0,1,0-1.15,1.55,1.55,0,0,1,1-.63A1.57,1.57,0,0,1,25.72,19.46Zm1.39-3.34a1.6,1.6,0,0,1-1,.63,1.57,1.57,0,0,1,0-1.15,1.56,1.56,0,0,1,1-.63A1.57,1.57,0,0,1,27.11,16.12Zm2.94,3.61a1.54,1.54,0,0,1-1,.62,1.57,1.57,0,0,1,0-1.15,1.59,1.59,0,0,1,1-.62A1.57,1.57,0,0,1,30.05,19.73Zm.16-2.28a1.57,1.57,0,0,1-.93-.68,1.58,1.58,0,0,1,.07-1.15,1.58,1.58,0,0,1,.92.68A1.63,1.63,0,0,1,30.21,17.45Z"
                        />
                        <path
                            strokeWidth="1"
                            stroke="#999999"
                            d="M12.15,22.22a.35.35,0,0,1,0-.08l.08-.05a1.37,1.37,0,0,1,.4-.09l.17,0a1.31,1.31,0,0,1,.28,0,1.22,1.22,0,0,0,.63-.11H34.28a1,1,0,0,0,.2.31.84.84,0,0,0,.3.22c.3.14.33.23.33.23a.7.7,0,0,1-.37.17,6.78,6.78,0,0,1-1.62.14.92.92,0,0,0-.95.55l-.08.11a1.92,1.92,0,0,1-.57.6,2,2,0,0,1-.77.29h0a.85.85,0,0,1-.46-.28,1.88,1.88,0,0,0-.63-.4,1.81,1.81,0,0,0-.73-.12,2.4,2.4,0,0,0-.83.24,4.17,4.17,0,0,0-1.06.57l-.28.25a2.26,2.26,0,0,1-.29.25,1.06,1.06,0,0,1-.49.2,1.16,1.16,0,0,1-.52-.05c-.7-.5-2-1.45-3.28-.84a4.68,4.68,0,0,0-.54.3,3.78,3.78,0,0,1-.43.26.89.89,0,0,1-.36.11.73.73,0,0,1-.36,0,1.33,1.33,0,0,1-.26-.21l-.05-.05a4.63,4.63,0,0,0-.9-.68,1.43,1.43,0,0,0-.72-.26,1.62,1.62,0,0,0-.88.29l-.07,0a2.91,2.91,0,0,1-1.35.4,1.67,1.67,0,0,1-.62-.12A1.59,1.59,0,0,1,15.1,24L15,23.77a2.43,2.43,0,0,0-.24-.32,2.93,2.93,0,0,0-1.2-.68l-1-.37C12.16,22.29,12.15,22.22,12.15,22.22Z"
                        />
                        <path
                            strokeWidth="1"
                            stroke="#999999"
                            d="M36.15,25.69a1.13,1.13,0,0,1-.86,1.09H13a1.1,1.1,0,0,1-.61-.4,1.07,1.07,0,0,1-.24-.69,1.09,1.09,0,0,1,.24-.69,1.08,1.08,0,0,1,.61-.39h1.65a2.28,2.28,0,0,0,1.59.61,3.65,3.65,0,0,0,1.73-.5l.07,0a1.14,1.14,0,0,1,.47-.19.73.73,0,0,1,.35.15,4.39,4.39,0,0,1,.78.58l0,.05a2,2,0,0,0,.43.33,1.58,1.58,0,0,0,.71.14,1.61,1.61,0,0,0,.71-.2l.51-.29a2.51,2.51,0,0,1,.45-.26c.73-.34,1.53.06,2.5.76a1.25,1.25,0,0,0,.75.22,2.1,2.1,0,0,0,1.17-.36,2.35,2.35,0,0,0,.37-.33l.23-.2a3.3,3.3,0,0,1,.87-.46,2.05,2.05,0,0,1,.58-.18,1.09,1.09,0,0,1,.4.07,1.39,1.39,0,0,1,.41.27,1.53,1.53,0,0,0,.95.46,2.26,2.26,0,0,0,1.48-.63h3.09a1.11,1.11,0,0,1,.62.39A1.15,1.15,0,0,1,36.15,25.69Z"
                        />
                    </g>
                </g>
            </svg>
        )
    }
}
export default CardConsumptionIcon
