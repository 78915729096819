import React from 'react'
const CardPlasticIcon = ({ status = 'inactive', size = 30 }) => {
    if (status === 'active') {
        return (
            <svg
                viewBox="0 0 48.43 48.43"
                width={size}
                height={size}
                stroke="white"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <circle fill="#4E84C4" strokeWidth="2" cx="24.21" cy="24.21" r="22.5" />
                        <path
                            strokeWidth="0.5"
                            stroke="#4E84C4"
                            d="M30.38,11.84a1.26,1.26,0,0,0-.89-.37h-.78a1.26,1.26,0,0,0-1.25,1.26v5.21A1.26,1.26,0,0,1,26.2,19.2H21.8a1.3,1.3,0,0,1-.89-.37,1.26,1.26,0,0,1-.37-.89V12.73a1.26,1.26,0,0,0-1.26-1.26H18.5a1.26,1.26,0,0,0-1.25,1.26V34.21a1.24,1.24,0,0,0,.37.89,1.22,1.22,0,0,0,.88.37h11a1.22,1.22,0,0,0,.89-.37,1.24,1.24,0,0,0,.37-.89V12.73A1.26,1.26,0,0,0,30.38,11.84Zm-7,18.38a.6.6,0,0,1-.38.17H20.17a.6.6,0,0,1-.29-.08.7.7,0,0,1-.2-.22.54.54,0,0,1-.06-.3.56.56,0,0,1,.1-.28l1.14-2a.49.49,0,0,1,.33-.25.56.56,0,0,1,.42.05.54.54,0,0,1,.26.33.55.55,0,0,1-.05.42l-.68,1.18H23a.6.6,0,0,1,.38.17.56.56,0,0,1,0,.78ZM23,26a.52.52,0,0,1-.33.25.55.55,0,0,1-.42-.05.59.59,0,0,1-.26-.34.53.53,0,0,1,0-.41l1.46-2.53a.58.58,0,0,1,.2-.21.55.55,0,0,1,.28-.07.53.53,0,0,1,.28.07.45.45,0,0,1,.2.21l1.46,2.52a.56.56,0,0,1,0,.42.54.54,0,0,1-.26.33.49.49,0,0,1-.41.06A.58.58,0,0,1,25,26l-1-1.7Zm5.29,4.1a.58.58,0,0,1-.21.2.53.53,0,0,1-.27.08H25a.57.57,0,0,1-.38-.18.55.55,0,0,1-.16-.38.55.55,0,0,1,.16-.39.6.6,0,0,1,.38-.17h1.82l-.68-1.18a.56.56,0,0,1-.05-.42.54.54,0,0,1,.26-.33.62.62,0,0,1,.42-.06.54.54,0,0,1,.33.26l1.17,2a.59.59,0,0,1,0,.56Z"
                        />
                    </g>
                </g>
            </svg>
        )
    }
    if (status === 'completed') {
        return (
            <svg
                viewBox="0 0 48.43 48.43"
                width={size}
                height={size}
                stroke="white"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <circle fill="#12AF7B" strokeWidth="2" cx="24.21" cy="24.21" r="22.5" />
                        <path
                            strokeWidth="0.5"
                            stroke="#12AF7B"
                            d="M30.38,11.84a1.26,1.26,0,0,0-.89-.37h-.78a1.26,1.26,0,0,0-1.25,1.26v5.21A1.26,1.26,0,0,1,26.2,19.2H21.8a1.3,1.3,0,0,1-.89-.37,1.26,1.26,0,0,1-.37-.89V12.73a1.26,1.26,0,0,0-1.26-1.26H18.5a1.26,1.26,0,0,0-1.25,1.26V34.21a1.24,1.24,0,0,0,.37.89,1.22,1.22,0,0,0,.88.37h11a1.22,1.22,0,0,0,.89-.37,1.24,1.24,0,0,0,.37-.89V12.73A1.26,1.26,0,0,0,30.38,11.84Zm-7,18.38a.6.6,0,0,1-.38.17H20.17a.6.6,0,0,1-.29-.08.7.7,0,0,1-.2-.22.54.54,0,0,1-.06-.3.56.56,0,0,1,.1-.28l1.14-2a.49.49,0,0,1,.33-.25.56.56,0,0,1,.42.05.54.54,0,0,1,.26.33.55.55,0,0,1-.05.42l-.68,1.18H23a.6.6,0,0,1,.38.17.56.56,0,0,1,0,.78ZM23,26a.52.52,0,0,1-.33.25.55.55,0,0,1-.42-.05.59.59,0,0,1-.26-.34.53.53,0,0,1,0-.41l1.46-2.53a.58.58,0,0,1,.2-.21.55.55,0,0,1,.28-.07.53.53,0,0,1,.28.07.45.45,0,0,1,.2.21l1.46,2.52a.56.56,0,0,1,0,.42.54.54,0,0,1-.26.33.49.49,0,0,1-.41.06A.58.58,0,0,1,25,26l-1-1.7Zm5.29,4.1a.58.58,0,0,1-.21.2.53.53,0,0,1-.27.08H25a.57.57,0,0,1-.38-.18.55.55,0,0,1-.16-.38.55.55,0,0,1,.16-.39.6.6,0,0,1,.38-.17h1.82l-.68-1.18a.56.56,0,0,1-.05-.42.54.54,0,0,1,.26-.33.62.62,0,0,1,.42-.06.54.54,0,0,1,.33.26l1.17,2a.59.59,0,0,1,0,.56Z"
                        />
                    </g>
                </g>
            </svg>
        )
    } else {
        return (
            <svg
                viewBox="0 0 48.43 48.43"
                width={size}
                height={size}
                stroke="#999999"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <circle fill="white" strokeWidth="2" cx="24.21" cy="24.21" r="22.5" />
                        <path
                            strokeWidth="1"
                            stroke="#999999"
                            d="M30.38,11.84a1.26,1.26,0,0,0-.89-.37h-.78a1.26,1.26,0,0,0-1.25,1.26v5.21A1.26,1.26,0,0,1,26.2,19.2H21.8a1.3,1.3,0,0,1-.89-.37,1.26,1.26,0,0,1-.37-.89V12.73a1.26,1.26,0,0,0-1.26-1.26H18.5a1.26,1.26,0,0,0-1.25,1.26V34.21a1.24,1.24,0,0,0,.37.89,1.22,1.22,0,0,0,.88.37h11a1.22,1.22,0,0,0,.89-.37,1.24,1.24,0,0,0,.37-.89V12.73A1.26,1.26,0,0,0,30.38,11.84Zm-7,18.38a.6.6,0,0,1-.38.17H20.17a.6.6,0,0,1-.29-.08.7.7,0,0,1-.2-.22.54.54,0,0,1-.06-.3.56.56,0,0,1,.1-.28l1.14-2a.49.49,0,0,1,.33-.25.56.56,0,0,1,.42.05.54.54,0,0,1,.26.33.55.55,0,0,1-.05.42l-.68,1.18H23a.6.6,0,0,1,.38.17.56.56,0,0,1,0,.78ZM23,26a.52.52,0,0,1-.33.25.55.55,0,0,1-.42-.05.59.59,0,0,1-.26-.34.53.53,0,0,1,0-.41l1.46-2.53a.58.58,0,0,1,.2-.21.55.55,0,0,1,.28-.07.53.53,0,0,1,.28.07.45.45,0,0,1,.2.21l1.46,2.52a.56.56,0,0,1,0,.42.54.54,0,0,1-.26.33.49.49,0,0,1-.41.06A.58.58,0,0,1,25,26l-1-1.7Zm5.29,4.1a.58.58,0,0,1-.21.2.53.53,0,0,1-.27.08H25a.57.57,0,0,1-.38-.18.55.55,0,0,1-.16-.38.55.55,0,0,1,.16-.39.6.6,0,0,1,.38-.17h1.82l-.68-1.18a.56.56,0,0,1-.05-.42.54.54,0,0,1,.26-.33.62.62,0,0,1,.42-.06.54.54,0,0,1,.33.26l1.17,2a.59.59,0,0,1,0,.56Z"
                        />
                    </g>
                </g>
            </svg>
        )
    }
}
export default CardPlasticIcon
