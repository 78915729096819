import React from 'react'
const MiniCardConsumptionIcon = ({ status = 'inactive', size = 24 }) => {
    // console.log({ status })
    if (status === 'active') {
        return (
            <svg
                viewBox="0 0 28 20"
                width={size + 10}
                height={size}
                strokeWidth="0.5"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path
                            class="cls-1"
                            fill="#fff"
                            d="M3,9.42a65.25,65.25,0,0,0,1.11,9.35c.66,5.29-4.14,4.93-3.32,0,.73-5.44,1-5.8,1-9.35A2.25,2.25,0,0,1,0,7.1l.37-6C.51.58.37.14,1,.14V7c0,.29.59.29.59,0V0h.51V7c0,.29.59.29.59,0V0h.52V7c0,.29.66.29.66,0L3.69.14c.59,0,.59.44.59,1l.37,6A2.19,2.19,0,0,1,3,9.42Z"
                        />
                        <path
                            class="cls-1"
                            fill="#fff"
                            fillRule="evenodd"
                            d="M24.92,20.15a39.3,39.3,0,0,0,.81-5.95c.07-.65-.08-1.16-1.26-1.52-.07-.72-.07-1.38-.14-2.1C24.11,4.78,25.14,0,26.61,0a1.12,1.12,0,0,1,.45.14,64.15,64.15,0,0,0,0,7.61,75.79,75.79,0,0,0,1.18,12.4C29,23.55,24.11,23.34,24.92,20.15Z"
                        />
                        <polygon
                            class="cls-2"
                            fill="#fff"
                            points="19.09 10 14.76 10.1 17.3 5.02 9.18 12.13 13.56 12.11 11.1 17.26 19.09 10"
                        />
                        <circle
                            class="cls-3"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="1.05px"
                            cx="14.14"
                            cy="11.15"
                            r="8.09"
                        />
                        <circle
                            class="cls-4"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="0.49px"
                            cx="14.14"
                            cy="11.14"
                            r="9.38"
                        />
                    </g>
                </g>
            </svg>
        )
    }
    if (status === 'completed') {
        return (
            <svg
                viewBox="0 0 28 20"
                width={size + 10}
                height={size}
                strokeWidth="0.5"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path
                            class="cls-1"
                            fill="#fff"
                            d="M3,9.42a65.25,65.25,0,0,0,1.11,9.35c.66,5.29-4.14,4.93-3.32,0,.73-5.44,1-5.8,1-9.35A2.25,2.25,0,0,1,0,7.1l.37-6C.51.58.37.14,1,.14V7c0,.29.59.29.59,0V0h.51V7c0,.29.59.29.59,0V0h.52V7c0,.29.66.29.66,0L3.69.14c.59,0,.59.44.59,1l.37,6A2.19,2.19,0,0,1,3,9.42Z"
                        />
                        <path
                            class="cls-1"
                            fill="#fff"
                            fillRule="evenodd"
                            d="M24.92,20.15a39.3,39.3,0,0,0,.81-5.95c.07-.65-.08-1.16-1.26-1.52-.07-.72-.07-1.38-.14-2.1C24.11,4.78,25.14,0,26.61,0a1.12,1.12,0,0,1,.45.14,64.15,64.15,0,0,0,0,7.61,75.79,75.79,0,0,0,1.18,12.4C29,23.55,24.11,23.34,24.92,20.15Z"
                        />
                        <polygon
                            class="cls-2"
                            fill="#fff"
                            points="19.09 10 14.76 10.1 17.3 5.02 9.18 12.13 13.56 12.11 11.1 17.26 19.09 10"
                        />
                        <circle
                            class="cls-3"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="1.05px"
                            cx="14.14"
                            cy="11.15"
                            r="8.09"
                        />
                        <circle
                            class="cls-4"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="0.49px"
                            cx="14.14"
                            cy="11.14"
                            r="9.38"
                        />
                    </g>
                </g>
            </svg>
        )
    } else {
        return (
            <svg
                viewBox="0 0 28 20"
                width={size + 10}
                height={size}
                strokeWidth="0.5"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path
                            class="cls-1"
                            fill="#fff"
                            stroke="#999999"
                            d="M3,9.42a65.25,65.25,0,0,0,1.11,9.35c.66,5.29-4.14,4.93-3.32,0,.73-5.44,1-5.8,1-9.35A2.25,2.25,0,0,1,0,7.1l.37-6C.51.58.37.14,1,.14V7c0,.29.59.29.59,0V0h.51V7c0,.29.59.29.59,0V0h.52V7c0,.29.66.29.66,0L3.69.14c.59,0,.59.44.59,1l.37,6A2.19,2.19,0,0,1,3,9.42Z"
                        />
                        <path
                            class="cls-1"
                            fill="#fff"
                            stroke="#999999"
                            fillRule="evenodd"
                            d="M24.92,20.15a39.3,39.3,0,0,0,.81-5.95c.07-.65-.08-1.16-1.26-1.52-.07-.72-.07-1.38-.14-2.1C24.11,4.78,25.14,0,26.61,0a1.12,1.12,0,0,1,.45.14,64.15,64.15,0,0,0,0,7.61,75.79,75.79,0,0,0,1.18,12.4C29,23.55,24.11,23.34,24.92,20.15Z"
                        />
                        <polygon
                            class="cls-2"
                            fill="#fff"
                            stroke="#999999"
                            points="19.09 10 14.76 10.1 17.3 5.02 9.18 12.13 13.56 12.11 11.1 17.26 19.09 10"
                        />
                        <circle
                            class="cls-3"
                            fill="none"
                            stroke="#999999"
                            strokeWidth="1.05px"
                            cx="14.14"
                            cy="11.15"
                            r="8.09"
                        />
                        <circle
                            class="cls-4"
                            fill="none"
                            stroke="#999999"
                            strokeWidth="0.49px"
                            cx="14.14"
                            cy="11.14"
                            r="9.38"
                        />
                    </g>
                </g>
            </svg>
        )
    }
}
export default MiniCardConsumptionIcon
