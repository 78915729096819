const GreenTick = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="#01af7b"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path strokeLinejoin="round" d="M6.22,10.22l3.11,3.25,5.35-6" />
            <circle cx="10.45" cy="10.45" r="9.5" />
        </svg>
    )
}

export default GreenTick
