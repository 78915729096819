import { Box, Typography } from '@material-ui/core'
import React from 'react'
import CustomTextField from '../../components/Customized/CustomTextField'
import GreenTick from '../../icon/GreenTick'
import CustomRangeSlider from '../Customized/CustomRangeSlider'

export const HandleTextOptions = ({ withSelectedFlag = true, question, onChange }) => (
    <>
        <Box style={{ display: 'flex' }}>
            <Typography style={{ flex: 1 }}>
                <CustomTextField
                    value={question.answer}
                    type="number"
                    onChange={({ target: { value } }) => {
                        try {
                            if (
                                value === '' ||
                                (parseInt(value) <= question.charLimit && parseInt(value) >= question.minCharLimit)
                            ) {
                                onChange(value)
                            }
                        } catch (err) {
                            // console.log(err)
                        }
                    }}
                    variant="filled"
                />
            </Typography>
            {withSelectedFlag && (
                <Typography
                    variant="body1"
                    style={{
                        minWidth: 60,
                        fontWeight: 'inherirt',
                        padding: '0px 10px',
                        flex: 'none',
                        color: 'green'
                    }}
                >
                    <GreenTick />
                </Typography>
            )}
        </Box>
        <CustomRangeSlider
            value={question.answer}
            min={question.minCharLimit}
            max={question.charLimit}
            onChange={(_, value) => {
                onChange(value.toString())
            }}
        />
    </>
)
