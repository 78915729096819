import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import loader from '../../assets/homepage_assets/C4L_Loader.gif'
import more_event from '../../assets/homepage_assets/webp_assets/more-events-66167b10632ff.webp'
import Header from '../../components/homepage_components/Header/Header'
import './events.css'
import { getEvents } from '../../redux-thunk/getEvents'
import { ROUTES } from '../../util/routes'

const EventsScreen = () => {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const { events } = useSelector((state) => state.events)
    const dispatch = useDispatch()

    useEffect(() => {
        setIsLoading(true)
        dispatch(getEvents()).then((res) => {
            setIsLoading(false)
        })
    }, [])

    return (
        <div className="events_container">
            <Header isPrimary={false} />
            {isLoading ? (
                <img className="events_loader" src={loader} alt="loader" />
            ) : (
                <div className="images_container">
                    {events?.events?.map((event, id) => {
                        return (
                            <div
                                key={id}
                                className="poster_container"
                                onClick={() => {
                                    history.push(`${ROUTES.EVENTS_SCREEN}/${event.eventName}`, {
                                        state: event
                                    })
                                }}
                            >
                                <img className="individual_image" src={event.poster} alt={event.eventName} />
                            </div>
                        )
                    })}
                    <div className="poster_container">
                        <img
                            className="individual_image"
                            src={more_event}
                            alt="More events comingsoon"
                            style={{ cursor: 'not-allowed' }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default EventsScreen
