import React from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../util/routes'
import TCS_C4L_LOGO from '../../../assets/homepage_assets/C4L Logo.svg'
import './header.css'
const Header = ({ isPrimary = true }) => {
    const history = useHistory()

    const navBar = [
        {
            Page: 'Events',
            Route: ROUTES.EVENTS_SCREEN,
            active: true
        }
    ]

    function handleClick(Route, active) {
        active && history.push(Route)
    }

    return (
        <div
            className="Header"
            style={{
                background: isPrimary
                    ? 'transparent'
                    : 'transparent linear-gradient(90deg, #54B948 0%, #4E84C4 100%) 0% 0% no-repeat padding-box'
            }}
        >
            <div className="header_left_container">
                <img
                    className="tcs_logo"
                    src={TCS_C4L_LOGO}
                    alt="TCS C4L LOGO"
                    onClick={() => history.push(ROUTES.LANDING_SCREEN)}
                />
            </div>
            <div className="header_right_container">
                {navBar.map(({ Page, Route, active }, index) => {
                    return (
                        <div className="right_container_div" key={index}>
                            {index !== 0 && <div className="nav_seperator"></div>}
                            <button className="nav_button" key={Page} onClick={() => handleClick(Route, active)}>
                                {Page}
                            </button>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Header
