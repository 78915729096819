import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import MiniLoader from '../../components/common/miniLoader/miniLoader'
import PlegeCard from '../../components/PledgeComponents/PlegeCard'
import { SnackbarContext } from '../../Context/SnackbarContext'
import getPledgeThunk from '../../redux-thunk/getPledgeThunk'
import imgPledge from './take-a-pledge.svg'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
function PledgeScreen() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const { handleClick } = useContext(SnackbarContext)
    const { emailId, uid } = useSelector((state) => state.session)
    const masterPledges = useSelector((state) => state.applicationInfo.pledgesList)
    const getPledgesList = useCallback(() => {
        setLoading(true)
        dispatch(getPledgeThunk())
            .catch((err) => {
                handleClick(err.message)
            })
            .finally(() => setLoading(false))
    }, [dispatch, handleClick])
    useEffect(() => {
        getPledgesList()
    }, [])
    
    return (
        <div style={{ margin: '10px 10px 10px 10px' }}>
            <img src={imgPledge} style={{ width: '100%' }} alt="profile" />
            {!loading ? (
                masterPledges.length !== 0 ? (
                    <>
                        {masterPledges.map((singlePledge) => {
                            return <PlegeCard key={singlePledge.pledge_id} pledge={singlePledge} />
                        })}
                    </>
                ) : (
                    ''
                )
            ) : (
                <MiniLoader />
            )}
        </div>
    )
}
export default PledgeScreen
