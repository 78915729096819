let initialState = {
    allFootprintSurveys: [],
    resultList: [],
    habbitsList: [],
    pledgesList: [],
    pledgesCategory: [],
    footprintRanges: {},
    defaultSurveyValues: {},
    hasPledges: false,
    hasHabbits: false,
    hasSurveys: false,
    inDigizen: null,
    pageLoader: false
}

const reducer$applicaitonInformation = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'application-info@@set-page-loader':
            return {
                ...state,
                pageLoader: payload
            }
        case 'application-info@@set-env':
            return {
                ...state,
                // inDigizen:true,
                inDigizen: state.inDigizen === null ? payload : state.inDigizen
            }
        case 'application-info@@set-surveys':
            return {
                ...state,
                hasSurveys: true,
                allFootprintSurveys: payload
            }
        case 'application-info@@set-footprintRanges':
            return {
                ...state,
                footprintRanges: payload
            }
        case 'application-info@@set-defaultSurveyValues':
            return {
                ...state,
                defaultSurveyValues: payload
            }
        case 'application-info@@set-footprint':
            return {
                ...state,
                resultList: payload
            }
        case 'application-info@@set-one-footprint':
            // console.log(payload.oneResult.footprintValue)
            let newallFootprintSurveys = state.allFootprintSurveys
            newallFootprintSurveys[payload.footprintNo] = {
                ...newallFootprintSurveys[payload.footprintNo],
                isAllowed: false,
                lastSubmissionValue: payload.oneResult.footprintValue,
                lastSubmissionTime: payload.oneResult.surveyFootprintTime
            }
            return {
                ...state,
                resultList: { ...state.resultList, [payload.type]: payload.oneResult },
                allFootprintSurveys: newallFootprintSurveys
            }
        case 'application-info@@set-pledges':
            return {
                ...state,
                hasPledges: true,
                pledgesList: payload
            }
        case 'application-info@@set-habbits':
            return {
                ...state,
                hasHabbits: true,
                habbitsList: payload
            }
        case 'application-info@@set-pledges-category':
            return {
                ...state,
                pledgesCategory: payload
            }
        case 'application-info@@reset':
            return initialState
        default:
            return state
    }
}

const setPageLoader = (flag) => {
    // console.log({ flag })
    return {
        type: 'application-info@@set-page-loader',
        payload: flag
    }
}

const saveFootprintSurveys = (surveyList) => ({
    type: 'application-info@@set-surveys',
    payload: surveyList
})

const saveFootprintRanges = (footprintRanges) => ({
    type: 'application-info@@set-footprintRanges',
    payload: footprintRanges
})

const savedefaultSurveyValues = (defaultSurveyValues) => ({
    type: 'application-info@@set-defaultSurveyValues',
    payload: defaultSurveyValues
})

const saveGlobalFootprintResult = (resultList) => ({
    type: 'application-info@@set-footprint',
    payload: resultList
})

const saveGlobalOneFootprintResult = (footprintNo, oneResult, type) => ({
    type: 'application-info@@set-one-footprint',
    payload: { footprintNo, oneResult, type }
})

const saveGlobalHabbits = (habbitList) => ({
    type: 'application-info@@set-habbits',
    payload: habbitList
})

const saveGlobalPledges = (pledges) => ({
    type: 'application-info@@set-pledges',
    payload: pledges
})

const saveGlobalPledgeCategory = (categaryList) => ({
    type: 'application-info@@set-pledges-category',
    payload: categaryList
})

const setEnv = (flag) => ({
    type: 'application-info@@set-env',
    payload: flag
})

export {
    setPageLoader,
    saveFootprintSurveys,
    saveFootprintRanges,
    saveGlobalFootprintResult,
    saveGlobalHabbits,
    saveGlobalPledges,
    setEnv,
    saveGlobalPledgeCategory,
    savedefaultSurveyValues,
    saveGlobalOneFootprintResult
}

export default reducer$applicaitonInformation
