import { Typography } from '@material-ui/core'
import React, { Fragment } from 'react'
import CompleteCustomAccordian from '../Customized'
import { FollowUpQuestion } from './FollowUpQuestion'

export const MainRadioOption = ({ option, toggleSelect, selectFUQRadio, setFUQText }) => {
    return (
        <Fragment>
            <CompleteCustomAccordian flag={option.isSelected} onChange={toggleSelect}>
                <Typography variant="body1" style={{ flex: 1 }}>
                    {option.name}
                </Typography>
            </CompleteCustomAccordian>
            {option.isSelected &&
                option.isFollowUp &&
                option.isFollowUp.length > 0 &&
                option.isFollowUp.map((fuq) => (
                    <FollowUpQuestion
                        key={fuq.id}
                        {...{
                            fuq,
                            selectFUQRadio: selectFUQRadio(fuq.id),
                            setFUQText: setFUQText(fuq.id)
                        }}
                    />
                ))}
        </Fragment>
    )
}
