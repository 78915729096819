import React, { useState } from 'react'
import { useLocation } from 'react-router'
import Header from '../../components/homepage_components/Header/Header'
import Text from '../../components/homepage_components/Text'
import './eventDetails.css'

const EventDetails = () => {
    const location = useLocation()
    const [isValidImage, setIsValidImage] = useState(true)
    const eventDetails = location.state.state || {}
    const [readmore, setReadmore] = useState(true)

    function handleImageError() {
        setIsValidImage(false)
    }

    // function renderHTML(content) {
    //     const sanitized_text = DOMPurify.sanitize(content)
    //     return <div className="html_content" dangerouslySetInnerHTML={{ __html: sanitized_text }} />
    // }

    return (
        <div className="eventDescriptionPage">
            <Header isPrimary={false} />
            <div className="description_container">
                <div className="title_container">
                    <Text
                        $color="#000"
                        $weight="600"
                        $size="41px"
                        $line_height="52px"
                        $alignment="left"
                        $font="Houschka Rounded Alt"
                    >
                        {eventDetails?.eventName}
                    </Text>
                    <Text
                        $color="#707070"
                        $weight="600"
                        $size="20px"
                        $line_height="30px"
                        $alignment="left"
                        $font="Poppins"
                    >
                        {eventDetails?.subHeading}
                    </Text>
                </div>
            </div>
            <div className="lower_container">
                <div className="details_container">
                    <Text
                        $color="#000"
                        $size="22px"
                        $line_height="33px"
                        $alignment="left"
                        $font="Poppins"
                        $weight="600"
                    >
                        Details
                    </Text>
                    {!readmore ? (
                        <div className="Html_div">
                            <div className="html_content" dangerouslySetInnerHTML={{ __html: eventDetails?.details }} />
                        </div>
                    ) : (
                        <div className="Html_div">
                            <div
                                className="html_content"
                                dangerouslySetInnerHTML={{ __html: eventDetails?.details?.slice(0, 650) }}
                            />
                            <span>
                                <button
                                    className="readmore_button"
                                    onClick={() => {
                                        setReadmore(false)
                                    }}
                                >
                                    ...Readmore
                                </button>
                            </span>
                        </div>
                    )}
                </div>
                <div className={isValidImage ? `poster` : `poster invalid_poster`}>
                    {isValidImage ? (
                        <img
                            className="poster_image_mini"
                            src={eventDetails?.poster}
                            alt="event poster"
                            onError={handleImageError}
                        />
                    ) : (
                        <Text
                            $color="#000000"
                            $font="Poppins"
                            $weight="600"
                            $size="17px"
                            $line_height="21px"
                            $alignment="center"
                        >
                            No Poster!
                        </Text>
                    )}
                </div>
            </div>
        </div>
    )
}

export default EventDetails
