import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import MiniLoader from '../../components/common/miniLoader/miniLoader'
import ButtonContainer from '../../components/Customized/ButtonContainer'
import InfoBox from '../../components/Customized/InfoBox'
import OutlinedButton from '../../components/Customized/OutlinedButton'
import RoundedButton from '../../components/Customized/RoundedButton'
import { setPageLoader } from '../../reducer/applicationInformation'
import pledgeAction from '../../redux-thunk/pledgeAction'
import { ROUTES } from '../../util/routes'
import firebase from 'firebase/app'
import 'firebase/auth'
import {Scrollbar} from 'smooth-scrollbar-react';
import moment from 'moment'
import 'firebase/firestore'
import { firebaseRemoteConfig } from '../..'
const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        padding: 0,
        margin: 0,
        '&>img': {
            width: 'calc( 100% + 20px)'
        }
    },
    pledgeTitle: {
        color: '#111111',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 25,
        lineHeight: '29px',
        textAlign: 'center',
        marginBottom: 23,
        marginTop: 15
    },
    body: {
        fontSize: 14,
        marginBottom: 28,
        lineHeight: '16px',
        textAlign: 'left',
        color: '#8F8C8C'
    },
    infoRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 12,
        color: '#999999',
        lineHeight: '14px',
        padding: '4px 0px'
    }
}))

function ExtensionSinglePledge() {
    const { pledgeId } = useParams()
    const masterPledges = useSelector((state) => state.applicationInfo.pledgesList)

    const showLoader = useSelector((state) => state.applicationInfo.pageLoader)
    const { emailId, uid } = useSelector((state) => state.session)
    const selectedPledge = useMemo(() => {
        return masterPledges.find((ExtensionSinglePledge) => ExtensionSinglePledge.pledge_id === pledgeId)
    }, [pledgeId, masterPledges])
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const [endMessage, setEndMessage] = useState(false)
    const takePledgeAction = useCallback(
        (id, action) => () => {
            dispatch(setPageLoader(true))
            if (action === 'end') {
                setEndMessage(true)
            }
            dispatch(
                pledgeAction({
                    pledgeId: id,
                    action: action
                })
            )
        },
        [dispatch]
    )
    const [thankyouMsg, setThankYouMsg] = useState('')
    useEffect(() => {
        if (selectedPledge) {
            dispatch(setPageLoader(false))
        }
        firebaseRemoteConfig.fetchAndActivate()
        setThankYouMsg(firebaseRemoteConfig.getValue('thank_you_update_pledge_text').asString())
    }, [selectedPledge, dispatch])
    return (
        <>
            {showLoader && (
                <>
                    {' '}
                    <MiniLoader />{' '}
                </>
            )}
            <Box className={classes.container}>
            <div className='App'>
      <Scrollbar
      //alwaysShowTracks
        plugins={{
          overscroll: {
            effect: 'bounce',
          },
        }}>
       
        <div className='list-data' style={{height: '480px'}}>
          <div><Container maxWidth="lg">
            <Typography variant="h2" className={classes.pledgeTitle}>
                        {selectedPledge.pledge_name}
                    </Typography>
                    <Box className={classes.container} style={{ margin: '0px -10px' }}>
                        <img src={selectedPledge.pledge_image} alt="placeholder" />
                        
                    </Box>
                    <Box className={classes.infoRow}>
                        <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                            {selectedPledge.pledged_by_number_of_user} others pledged
                        </Typography>
                        <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                            Duration: {selectedPledge.pledge_duration} Days
                        </Typography>
                    </Box>

                    <Typography variant="body1" className={classes.body}>
                        {selectedPledge.pledge_description}
                    </Typography>
                    {selectedPledge.isPledgeEnded === false ? (
                        <>
                            <InfoBox>
                                <Typography>
                                    {thankyouMsg}
                                </Typography>
                            </InfoBox>
                            <ButtonContainer>
                                <RoundedButton
                                    fullWidth
                                    onClick={() => {
                                        history.push({
                                            pathname: ROUTES.ExtensionPledgeLog,
                                            state: {
                                                id: selectedPledge.pledge_id
                                            }
                                        })
                                    }}
                                >
                                    <Typography>Update pledge</Typography>
                                </RoundedButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <OutlinedButton fullWidth onClick={takePledgeAction(selectedPledge.pledge_id, 'end')}>
                                    <Typography>End</Typography>
                                </OutlinedButton>
                            </ButtonContainer>
                        </>
                    ) : (
                        <>
                            {endMessage && (
                                <InfoBox>
                                    <Typography>
                                        Your pledge has ended. Click on 'Begin Now' to retake the pledge.
                                    </Typography>
                                </InfoBox>
                            )}
                            <ButtonContainer>
                                <RoundedButton fullWidth onClick={takePledgeAction(selectedPledge.pledge_id, 'begin')}>
                                    <Typography>Begin Now</Typography>
                                </RoundedButton>
                            </ButtonContainer>
                        </>
                    )}</Container></div>
        </div>
        
      </Scrollbar>
    </div>
               
            </Box>
        </>
    )
}

export default ExtensionSinglePledge
