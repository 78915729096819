let initial_state = {
    events: []
}

export const setEvents = (events) => ({
    type: 'SET_EVENTS',
    payload: { events }
})

const eventsReducer = (state = initial_state, { type, payload }) => {
    switch (type) {
        case 'SET_EVENTS': {
            return {
                ...state,
                events: payload
            }
        }
        default:
            return state
    }
}

export default eventsReducer
