import { Slider, withStyles } from '@material-ui/core'

const CustomSlider = withStyles(() => ({
    root: {
        color: '#4E84C4',
        marginBottom: 0
    },
    thumb: {
        height: 14,
        width: 14,
        '&:after': {
            margin: 11,
            content: '',
            width: 22,
            height: 22,
            // border: '2px solid #F23268c9',
            border: '2px solid #4E84C4c9',
            borderRadius: '50%'
        },
        '&:focus, &:hover, &$active': {
            boxShadow: '0px 0px 0px 10px #4E84C435 '
        }
    },
    active: {},
    track: {
        height: 4,
        borderRadius: 4
    },
    rail: {
        height: 4,
        borderRadius: 4
    }
}))(Slider)

export default CustomSlider
