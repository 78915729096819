const WhiteCross = ({ size = 30, fill = 'none' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.54 28.54"
            width={size}
            height={size}
            color={fill}
            strokeWidth="1.5"
            stroke="currentColor"
            fill={fill}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g>
                <g>
                    <path d="M20.93,9.24l-5.19,5.2,5.19,5.19a1,1,0,0,1-.73,1.77h0a1,1,0,0,1-.72-.3l-5.2-5.2-5.2,5.2a1.06,1.06,0,0,1-.73.3,1,1,0,0,1-1-1,1.07,1.07,0,0,1,.3-.74l5.2-5.19L7.6,9.24A1,1,0,0,1,9.07,7.77l5.2,5.2,5.2-5.2a1,1,0,1,1,1.46,1.47Z" />
                    <path
                        className="cls-1"
                        d="M14.27,0A14.27,14.27,0,1,0,28.54,14.27,14.27,14.27,0,0,0,14.27,0Zm0,26.47a12.19,12.19,0,1,1,12.2-12.2A12.2,12.2,0,0,1,14.27,26.47Z"
                    />
                </g>
            </g>
        </svg>
    )
}
export default WhiteCross
