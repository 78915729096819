import React from 'react'
const CardWaterIcon = ({ status = 'inactive', size = 30 }) => {
    // console.log(status)
    if (status === 'active') {
        return (
            <svg
                viewBox="0 0 48.43 48.43"
                width={size}
                height={size}
                stroke="white"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <circle fill="#4E84C4" strokeWidth="2" cx="24.21" cy="24.21" r="22.5" />
                        <path
                            strokeWidth="0.5"
                            stroke="#4E84C4"
                            d="M30.67,22.14C30,20.6,29.08,18.85,28,16.8,26,13.37,24.05,10.44,24,10.39s-2,3-4,6.41a55.8,55.8,0,0,0-2.72,5.34,12.06,12.06,0,0,0-1.23,4.35,7.73,7.73,0,0,0,.61,3.08A7.91,7.91,0,0,0,24,34.39a8,8,0,0,0,3.08-.62,7.88,7.88,0,0,0,4.82-7.28A12.06,12.06,0,0,0,30.67,22.14ZM25,32.13l-.1.06-.1.05c-.05,0-.1,0-.15,0l-.15.05c-.05,0-.1,0-.16,0a.46.46,0,0,0-.2,0H22.72c-.1,0-.26,0-.36,0l-.2,0H22a5.77,5.77,0,0,1-2.56-1.54,6.58,6.58,0,0,1-1.54-2.56V28l-.05-.2s-.05-.1-.05-.21a.46.46,0,0,0-.05-.2,3.49,3.49,0,0,1-.05-.72c.3-.1.3-.15.3-.21V26a.46.46,0,0,1,.05-.2.19.19,0,0,1,0-.08.2.2,0,0,1,0-.08c0-.05.05-.1.05-.15s.06-.1.06-.15l.05-.1.05-.1h.1v1.33a9.76,9.76,0,0,0,.36,1.28,5.28,5.28,0,0,0,.61,1.18,5.72,5.72,0,0,0,.87,1,8.61,8.61,0,0,0,1,.87l.31.15.15.1.1.1.16.1.15,0s.1.06.16.06l.15.05a6.94,6.94,0,0,0,1.28.36H25Z"
                        />
                    </g>
                </g>
            </svg>
        )
    }
    if (status === 'completed') {
        return (
            <svg
                viewBox="0 0 48.43 48.43"
                width={size}
                height={size}
                stroke="white"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <circle fill="#12AF7B" strokeWidth="2" cx="24.21" cy="24.21" r="22.5" />
                        <path
                            strokeWidth="0.5"
                            stroke="#12AF7B"
                            d="M30.67,22.14C30,20.6,29.08,18.85,28,16.8,26,13.37,24.05,10.44,24,10.39s-2,3-4,6.41a55.8,55.8,0,0,0-2.72,5.34,12.06,12.06,0,0,0-1.23,4.35,7.73,7.73,0,0,0,.61,3.08A7.91,7.91,0,0,0,24,34.39a8,8,0,0,0,3.08-.62,7.88,7.88,0,0,0,4.82-7.28A12.06,12.06,0,0,0,30.67,22.14ZM25,32.13l-.1.06-.1.05c-.05,0-.1,0-.15,0l-.15.05c-.05,0-.1,0-.16,0a.46.46,0,0,0-.2,0H22.72c-.1,0-.26,0-.36,0l-.2,0H22a5.77,5.77,0,0,1-2.56-1.54,6.58,6.58,0,0,1-1.54-2.56V28l-.05-.2s-.05-.1-.05-.21a.46.46,0,0,0-.05-.2,3.49,3.49,0,0,1-.05-.72c.3-.1.3-.15.3-.21V26a.46.46,0,0,1,.05-.2.19.19,0,0,1,0-.08.2.2,0,0,1,0-.08c0-.05.05-.1.05-.15s.06-.1.06-.15l.05-.1.05-.1h.1v1.33a9.76,9.76,0,0,0,.36,1.28,5.28,5.28,0,0,0,.61,1.18,5.72,5.72,0,0,0,.87,1,8.61,8.61,0,0,0,1,.87l.31.15.15.1.1.1.16.1.15,0s.1.06.16.06l.15.05a6.94,6.94,0,0,0,1.28.36H25Z"
                        />
                    </g>
                </g>
            </svg>
        )
    } else {
        return (
            <svg
                viewBox="0 0 48.43 48.43"
                width={size}
                height={size}
                stroke="#999999"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <circle fill="white" strokeWidth="2" cx="24.21" cy="24.21" r="22.5" />
                        <path
                            strokeWidth="1"
                            stroke="#999999"
                            d="M30.67,22.14C30,20.6,29.08,18.85,28,16.8,26,13.37,24.05,10.44,24,10.39s-2,3-4,6.41a55.8,55.8,0,0,0-2.72,5.34,12.06,12.06,0,0,0-1.23,4.35,7.73,7.73,0,0,0,.61,3.08A7.91,7.91,0,0,0,24,34.39a8,8,0,0,0,3.08-.62,7.88,7.88,0,0,0,4.82-7.28A12.06,12.06,0,0,0,30.67,22.14ZM25,32.13l-.1.06-.1.05c-.05,0-.1,0-.15,0l-.15.05c-.05,0-.1,0-.16,0a.46.46,0,0,0-.2,0H22.72c-.1,0-.26,0-.36,0l-.2,0H22a5.77,5.77,0,0,1-2.56-1.54,6.58,6.58,0,0,1-1.54-2.56V28l-.05-.2s-.05-.1-.05-.21a.46.46,0,0,0-.05-.2,3.49,3.49,0,0,1-.05-.72c.3-.1.3-.15.3-.21V26a.46.46,0,0,1,.05-.2.19.19,0,0,1,0-.08.2.2,0,0,1,0-.08c0-.05.05-.1.05-.15s.06-.1.06-.15l.05-.1.05-.1h.1v1.33a9.76,9.76,0,0,0,.36,1.28,5.28,5.28,0,0,0,.61,1.18,5.72,5.72,0,0,0,.87,1,8.61,8.61,0,0,0,1,.87l.31.15.15.1.1.1.16.1.15,0s.1.06.16.06l.15.05a6.94,6.94,0,0,0,1.28.36H25Z"
                        />
                    </g>
                </g>
            </svg>
        )
    }
}
export default CardWaterIcon
