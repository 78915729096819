import React from 'react'
import image1 from '../../assets/png/ic_sm10@3x.png'
import image2 from '../../assets/png/ic_sm9@3x.png'
import image3 from '../../assets/png/ic_sm8@3x.png'
import image4 from '../../assets/png/ic_sm7@3x.png'
import image5 from '../../assets/png/ic_sm6@3x.png'
import image6 from '../../assets/png/ic_sm5@3x.png'
import image7 from '../../assets/png/ic_sm4@3x.png'
import image8 from '../../assets/png/ic_sm3@3x.png'
import image9 from '../../assets/png/ic_sm2@3x.png'
import image10 from '../../assets/png/ic_sm1@3x.png'

function ResultLevel({ upperLimit, lowerLimit, value }) {
    const indexofValue = Math.ceil(((value - lowerLimit) / (upperLimit - lowerLimit)) * 10)
    const arrayofImage = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10]

    return (
        <div>
            <img
                src={indexofValue < 10 ? indexofValue===0? arrayofImage[0]: arrayofImage[indexofValue - 1] : arrayofImage[9]}
                alt="levels"
                style={{ height: '24px', padding: '2px' }}
            />
        </div>
    )
}

export default ResultLevel
