import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/remote-config'
import React from 'react'
import ReactDOM from 'react-dom'
// import { firebaseConfig } from './firebase.dev.js' // dev config
// import {firebaseConfig} from './firebase.staging.json' // staging config
// import { firebaseConfig } from './firebase.prod.js' // prod config
// import {firebaseConfig} from './firebase.flutter.js'
import {firebaseConfig} from './firebase.salsa.js'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
require('dotenv').config()
const firebaseInitializedObject = firebase.initializeApp(firebaseConfig)
const firebaseAuth = firebaseInitializedObject.auth()
const firebaseRemoteConfig = firebase.remoteConfig(firebaseInitializedObject)
firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 3600000
firebaseRemoteConfig.defaultConfig = {
    aes_key: ''
}
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
)

export { firebaseInitializedObject, firebaseAuth, firebaseRemoteConfig, firebaseConfig }

reportWebVitals()
