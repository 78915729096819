import React, { useEffect } from 'react'
import './privacyTerms.css'
import Header from '../../components/homepage_components/Header/Header'

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="privacy_terms">
            <Header className="header_div" />
            <div className="privacy_terms_container">
                <h1>TCS CIRCLE4LIFE PRIVACY POLICY AND COOKIE POLICY</h1>
                <p>
                    Tata Consultancy Services and its affiliates are committed to protecting the privacy and security of
                    your personal data. This privacy policy (“Privacy Policy”) relates to your use of the CIRCLE4LIFE
                    Application (APP) and explains how TCS and its affiliates collect, use, share, store, disclose and
                    transfer your personal data when you use the APP and enumerates your rights in connection with the
                    APP and related services.
                </p>
                <p>
                    This Privacy Policy applies to all the personal data that is collected and used by us when you
                    create a CIRCLE4LIFE ID account.
                </p>
                <p>
                    The TCS entity responsible for the processing of your personal data will depend on how you interact
                    with the APP and where you are located. The relevant TCS entity are referred to as “TCS”, “our”,
                    “we” or “us” in this Privacy Policy. Please review our List of local entities for the name of TCS
                    entity responsible and the appropriate contact information as stated below.
                </p>
                <p>
                    IF YOU CHOOSE TO USE THE CIRCLE4LIFE APPLICATION, THEN YOU AGREE TO PROVIDE YOUR CONSENT TO THE
                    COLLECTION, USE, STORING, DISCLOSURE AND TRANSFER OF PERSONAL DATA BY TCS IN ACCORDANCE WITH THIS
                    PRIVACY POLICY.
                </p>
                <h3>DATA PROTECTION PRINCIPLES </h3>
                <p>
                    We comply with all applicable data protection laws. This means that the personal data we hold about
                    you is:
                </p>
                <ol>
                    <li>Used lawfully, fairly and in a transparent way </li>
                    <li>
                        Collected only for valid purposes that we have clearly explained to you and not used in any way
                        that is incompatible with those purposes
                    </li>
                    <li>Relevant to the purposes we have told you about and limited only to those purposes</li>
                    <li>Accurate and kept up to date with best of our knowledge and informed by/to you</li>
                    <li>Kept only as long as necessary for the purposes</li>
                    <li>Kept securely</li>
                </ol>
                <h3>PERSONAL DATA WE COLLECT:</h3>
                <p>
                    This APP collects personal data about you and your family members, including information that
                    directly or indirectly identifies you, if you choose to share the information with the APP. We will
                    collect, store, and use the following categories of personal data about you:
                </p>
                <ul>
                    <li>Contact details including name and email</li>
                    <li>Device ID</li>
                    <li>Business Unit</li>
                    <li>Country </li>
                </ul>
                <span>Further details will be accessed, displayed and/or collected</span>
                <ul>
                    <li>Carbon (Transport, Utilities, Fuel usage, Waste, Diet Preference)</li>
                    <li>Water (consumption of water for indoor home activities)</li>
                    <li>Consumption (conscious choices towards buying food, clothes and gadgets)</li>
                    <li>Plastic (extent of usage of single-use plastics in various interactions)</li>
                    <li>Footprint Values Dashboard (Calculated by the application)</li>
                    <li>Pledges (voluntary targets set towards the improvements, on the basis of your footprint)</li>
                    <li>Offset</li>
                    <li>Grow Your tree (Plant a tree and offset)</li>
                    <li>External Offset (Purchase an external offset and provide the details)</li>
                    <li>Leader Board</li>
                    <li>Business Unit Leader Board • Profile screen</li>
                </ul>
                <h3>HOW IS YOUR PERSONAL DATA COLLECTED</h3>
                <p>
                    We collect personal data as specified hereinabove about you and your family members in a number of
                    ways, including but not limited to the following:
                </p>
                <ol>
                    <li>When you register and create an account in the app.</li>
                    <li>When you input any electricity and water consumption data or the app infers such data.</li>
                    <li>When you give us permission to collect location data.</li>
                    <li>When you give consent for challenges or leaderboard activities.</li>
                    <li>When you access third-party applications, such as Google Pedometer and Apple Health.</li>
                    <li>When we comply with legal requirements or obligations.</li>
                </ol>
                <h3>HOW WE WILL USE PERSONAL DATA</h3>
                <p>
                    We will only use your personal data when the law allows us to. Most commonly, we will use your
                    personal data in the following circumstances:
                </p>
                <ol>
                    <li>Where we need to perform the contract we have entered into with you.</li>
                    <li>Where we need to comply with a legal obligation.</li>
                    <li>
                        Where it is necessary for our legitimate interests (or those of a third party) and your
                        interests and fundamental rights do not override those interests.
                    </li>
                    <li>For general research and analysis purpose</li>
                </ol>
                <p>We may also use your personal data in the following situations, which are likely to be rare:</p>
                <ol>
                    <li>Where we need to protect your interests (or someone else’s interests).</li>
                    <li>Where it is needed in the public interest or for official purposes</li>
                </ol>
                <h5>Situations in which we will use your personal data</h5>
                <ol>
                    <li>Carbon (Transport, Utilities, Fuel usage, Waste, Diet Preference)</li>
                    <li>Water (consumption of water for indoor home activities)</li>
                    <li>Consumption (conscious choices towards buying food, clothes and gadgets)</li>
                    <li>Plastic (extent of usage of single-use plastics in various interactions)</li>
                    <li>Footprint Values Dashboard (Calculated by the application)</li>
                    <li>Pledges (voluntary targets set towards the improvements, on the basis of your footprint)</li>
                    <li>Offset</li>
                    <li>Grow Your tree (Plant a tree and offset)</li>
                    <li>External Offset (Purchase an external offset and provide the details)</li>
                </ol>
                <h5>If you fail to provide personal data</h5>
                <p>
                    If you fail to provide certain information when requested, we shall not be able to serve the
                    purpose(s) for which we process your personal data, or we may be prevented from complying with our
                    legal obligations.
                </p>
                <h5>Privacy Controls</h5>
                <p>
                    When using our APP, we also provide in-time notice or obtain consent for certain practices. For
                    example, we will obtain consent to use your location or if your CIRCLE4LIFE ID can be published on
                    the leaderboard or to send push notifications. In many cases, your mobile device will provide
                    additional tools to allow you to control when your device collects or shares particular categories
                    of personal data. For example, your mobile device may offer tools to manage cookie usage. We
                    encourage you to familiarize with and use the tolls available on your device.
                </p>
                <h5>Change of purpose</h5>
                <p>
                    We will only use your personal data for the purposes for which we collected it, unless we reasonably
                    consider that we need to use it for another reason and that reason is compatible with the original
                    purpose. If we need to use your personal data for an unrelated purpose, we will notify you and we
                    will explain the lawful basis which allows us to do so. Please note that we may process your
                    personal data without your knowledge or consent, in compliance with the above rules, where this is
                    required or permitted by law.
                </p>
                <h3>HOW WE USE PARTICULARLY SENSITIVE PERSONAL DATA</h3>
                <p>
                    ”Special categories” of particularly sensitive personal data require higher levels of protection. We
                    need to have further justification for collecting, storing and using this type of personal data. We
                    may process special categories of personal data in the following circumstances:
                </p>
                <ul>
                    <li>In limited circumstances, with your explicit written consent.</li>
                    <li>
                        Where we need to carry out our legal obligations. We have in place an appropriate policy
                        document and safeguards which we are required by law to maintain when processing such data.
                    </li>
                    <li>Where it is needed in the public interest.</li>
                    <p>
                        {' '}
                        Less commonly, we may process this type of information where it is needed in relation to legal
                        claims or where it is needed to protect your interests (or someone else’s interests) and you are
                        not capable of giving your consent, or where you have already made the information public.
                    </p>
                </ul>
                <h3> DATA SHARING AND DATA SECURITY</h3>
                <p>
                    We may have to share your data with third parties, including third-party service providers and other
                    entities in the TCS group. We require third parties to respect the security of your data and to
                    treat it in accordance with the law. We may transfer your personal data outside your country. If we
                    do so, you can expect a similar degree of protection in respect of your personal data. We may
                    disclose your personal data with regulatory and/or government authorities when asked and in order to
                    comply with the applicable law(s).
                </p>
                <h5>International Transfers of your Personal Data:</h5>
                <p>
                    The personal data we collect (or process) in the context of our APP will be stored in Singapore and
                    other countries. Some geography/countries, where we transfer your personal data might not have
                    appropriate (or adequate) privacy regulation and requirements, but TCS, as a global company, ensures
                    that our global privacy policy is complied across globe.
                </p>
                <p>
                    {' '}
                    In order to ensure that your personal data does receive at-par level of protection, we have put in
                    place the relevant intra group contracts and agreements which ensure that your personal data is
                    treated by TCS Affiliates or third parties in a way that is consistent with and which respects the -
                    country specific laws on data protection. We undertake all those measures required to comply with
                    cross-border personal data transfers, before transferring any of your personal data overseas.
                </p>
                <h5>Security of your data</h5>
                <p>
                    We have put in place appropriate technical, organizational and security measures to prevent your
                    personal data from being accidentally lost, used or accessed in an unauthorized way, altered or
                    disclosed. In addition, we limit access to your personal data to those employees, agents,
                    contractors and other third parties who have a business need to know. They will only process your
                    personal data on our instructions and they are subject to a duty of confidentiality. We have put in
                    place procedures to deal with any suspected data security breach and will notify you and any
                    applicable regulator of a suspected breach where we are legally required to do so.
                </p>
                <h3> DATA RETENTION</h3>
                <h5>How long will you use my information for?</h5>
                <p>
                    We will only retain your personal data for as long as necessary to fulfil the purposes we collected
                    it for, including for the purposes of satisfying any legal, accounting, or reporting requirements or
                    until your account is deleted. To determine the appropriate retention period for personal data, we
                    consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from
                    unauthorized use or disclosure of your personal data, the purposes for which we process your
                    personal data and whether we can achieve those purposes through other means, and the applicable
                    legal requirements.
                </p>
                <p>
                    {' '}
                    In some circumstances we may anonymize your personal data so that it can no longer be associated
                    with you, in which case we may use such information without further notice to you. Once you are no
                    longer [an employee, worker or contractor of the company] we will retain and securely destroy your
                    personal data in accordance with our data retention policy and applicable laws and regulations.
                </p>
                <h3>DATA SUBJECT RIGHTS</h3>
                <h5>Your duty to inform us of changes</h5>
                <p>
                    It is important that the personal data we hold about you is accurate and current. Please keep us
                    informed if your personal data changes during your relationship with us.
                </p>
                <h5>Your rights in connection with personal data</h5>
                <p>Under certain circumstances, by law you have the right to:</p>
                <li>
                    <b>Request access</b> to your personal data (commonly known as a “data subject access request”).
                    This enables you to receive a copy of the personal data we hold about you and to check that we are
                    lawfully processing it.
                </li>
                <li>
                    <b>Request correction</b> of the personal data that we hold about you. This enables you to have any
                    incomplete or inaccurate information we hold about you corrected.
                </li>
                <li>
                    <b>Request erasure</b> of your personal data. This enables you to ask us to delete or remove
                    personal data where there is no good reason for us continuing to process it. You also have the right
                    to ask us to delete or remove your personal data where you have exercised your right to object to
                    processing (see below).
                </li>
                <li>
                    <b>Object to processing</b> of your personal data where we are relying on a legitimate interest (or
                    those of a third party) and there is something about your particular situation which makes you want
                    to object to processing on this ground. You also have the right to object where we are processing
                    your personal data for direct marketing purposes.
                </li>
                <li>
                    <b>Request the restriction of processing</b> of your personal data. This enables you to ask us to
                    suspend the processing of personal data about you, for example if you want us to establish its
                    accuracy or the reason for processing it.
                </li>
                <li>
                    <b>Request the transfer</b> of your personal data to another party.
                </li>
                <p>
                    If you want to exercise a data subject right please contact us at the details provided hereinbelow.
                </p>
                <h5>What we may need from you</h5>
                <p>
                    We may need to request specific information from you to help us confirm your identity and ensure
                    your right to access the information (or to exercise any of your other rights). This is another
                    appropriate security measure to ensure that personal data is not disclosed to any person who has no
                    right to receive it.
                </p>
                <h3>RIGHT TO WITHDRAW CONSENT</h3>
                <p>
                    You have the right to withdraw your consent for that specific processing at any time. To withdraw
                    your consent, please contact us at the details provided hereinbelow. Once we have received
                    notification that you have withdrawn your consent, within reasonable period, we will no longer
                    process your information for the purpose or purposes you originally agreed to, unless we have
                    another legitimate basis for doing so in law.
                </p>
                <h3>THIRD PARTY APPS</h3>
                <p>
                    Our APP allows you to interact with wide variety of other digital products, apps and services. If
                    you choose to connect your CIRCLE4LIFET account with such third party applications, your privacy
                    rights on third party platforms will be governed by their respective policies. The APP may contain
                    links to other sites. If you click on a third-party link, you will be directed to that site. Note
                    that these external sites are not operated by us. Therefore, we strongly advise you to review the
                    privacy policy of these websites. We have no control over and assume no responsibility for the
                    content, privacy policies, or practices of any third-party sites or services.
                </p>
                <h3>DATA PROTECTION OFFICER</h3>
                <p>
                    We have dedicated data protection offices and have appointed data protection officers (DPO) to
                    oversee compliance of TCS with applicable data protection laws and with this PRIVACY POLICY. If you
                    have any questions about this PRIVACY POLICY or how we handle your personal data, please contact the
                    respective DPO at details mentioned in the list provided.
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>Global</td>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>
                                Global Privacy Office at tcs.cpo@tcs.com
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>UK and Ireland</td>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}> dpo.uki@tcs.com</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>Continental Europe</td>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>dpo.eu@tcs.com</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>United States</td>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>us.privacy@tcs.com</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>APAC</td>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>dpo.apac@tcs.com</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>LATAM</td>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>dpo.latam@tcs.com</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>Canada</td>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>canada.privacy@tcs.com</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>MEA</td>
                            <td style={{ border: '1px solid #ddd', padding: '10px 5px' }}>mea.privacy@tcs.com</td>
                        </tr>
                    </tbody>
                </table>
                <h3>PRIVACY POLICY TOWARDS CHILDREN</h3>
                <p>
                    Our APP and its services are not directed to individuals under 18. We do not knowingly collect
                    personal information from children under 18. If we become aware that a child under 18, or equivalent
                    minimum age depending on jurisdiction, has provided is for personal data, we take steps to remove
                    such information. If you become aware that your child has provided us with personal data without
                    your consent, please contact us at the contact information provided herein.
                </p>
                <h3>Cookies</h3>
                <p>
                    Cookies are files with a small amount of data that are commonly used as anonymous unique
                    identifiers. These are sent to your browser from the websites that you visit and are stored on your
                    device's internal memory.
                </p>
                <p>
                    The APP do not use these “cookies” explicitly. However, the APP may use third party code and
                    libraries or provide links to third party websites, that use “cookies” to collect information and
                    improve their services. You have the option to either accept or refuse these cookies and know when a
                    cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use
                    some portions of the APP.
                </p>
                <h3>CHANGES TO THIS PRIVACY POLICY</h3>
                <p>
                    We may update our Privacy Policy from time to time. The most current version of the Privacy Policy
                    will govern our use of your personal data and will always be on our APP. If we make any material
                    changes, we will notify you by posting a notice on our APP about the update to the Privacy Policy,
                    and the updated Privacy Policy will indicate the date of the latest revision. You agree to accept
                    any changes or revisions to this Privacy Policy by continuing to use our APP. We encourage you to
                    periodically review this page to stay aware of any changes to this Privacy Policy.
                </p>
            </div>
        </div>
    )
}

export default Privacy
