let initialState = {
    token: '',
    emailId: '',
    uid: ''
}

const session = (state = initialState, action) => {
    switch (action.type) {
        case 'user-session@@set-token':
            return {
                ...state,
                token: action.payload
            }
        case 'SET_TOKEN_USER_DATA':
            return {
                ...state,
                token: action.token,
                isRegistrationSurvey: action.isRegistrationSurvey,
                emailId: action.emailId,
                habbits: action.habbits,
                name: action.name,
                empId: action.empId
            }
        case 'user-info@@emailId':
            return {
                ...state,
                emailId: action.payload
            }
        case 'user-info@@uid':
            return {
                ...state,
                uid: action.payload
            }
        case 'SET_CARBON':
            return {
                ...state,
                carbon: true
            }
        case 'SET_WATER':
            return {
                ...state,
                water: true
            }
        case 'SET_CONSUMPTION':
            return {
                ...state,
                consumption: true
            }
        case 'SET_PLASTIC':
            return {
                ...state,
                plastic: true
            }
        case 'LOGOUT':
            return initialState
        default:
            return state
    }
}

const setSessionToken = (token) => ({
    type: 'user-session@@set-token',
    payload: token
})
const setemailId = (email) => ({
    type: 'user-info@@emailId',
    payload: email
})
const setuid = (email) => ({
    type: 'user-info@@uid',
    payload: email
})
const logout = () => ({
    type: 'LOGOUT'
})

export default session
export { setSessionToken, setemailId, setuid, logout }
