import { Paper, withStyles } from '@material-ui/core'

const CustomPaper = withStyles(() => ({
    root: {
        boxShadow: '4px 4px 8px #DDDDDD99',
        background: 'white',
        border: '1px solid white',
        width: '100%',
        padding: 12,
        margin: '4px auto'
    }
}))(Paper)

export default CustomPaper
