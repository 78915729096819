export function isCheckTypeQuestion(queston) {
    return (
        queston.optionType && typeof queston.optionType === 'string' && queston.optionType.toLowerCase() === 'checkbox'
    )
}
export function isRadioTypeQuestion(queston) {
    return queston.optionType && typeof queston.optionType === 'string' && queston.optionType.toLowerCase() === 'radio'
}
export function isTextTypeQuestion(queston) {
    return queston.optionType && typeof queston.optionType === 'string' && queston.optionType.toLowerCase() === 'text'
}
export function isCheckboxQuestionAnswered(question) {
    let isValid = false
    question.questionOptions.forEach((singleOption) => {
        if (singleOption.isSelected === true) {
            isValid = true
        }
    })
    return isValid
}
export function isRadioQuestionAnswered(question) {
    let isValid = false
    question.questionOptions.forEach((singleOption) => {
        if (singleOption.isSelected === true) {
            isValid = true
        }
    })
    return isValid
}
export function isTextQuestionAnswered(question) {
    return question.answer && typeof question.answer === 'string' && question.answer.trim() !== ''
}
export function isArray(instance) {
    return instance && instance instanceof Array
}

export function hasFollowUpQuestions(option) {
    return option.isFollowUp !== false && option.isFollowUp instanceof Array
}
