import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import submitSurveyThunk from '../../redux-thunk/submitSurveyThunk'
import MiniSurveyCardList from '../ActionItems/MiniSurveyCardList'
import SurveyCardList from '../ActionItems/SurveyCardList'
import { ShowSurveyQuestions } from './ShowSurveyQuestions'

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        backgroundColor: '#FBFBFB',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4)
        }
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        padding: 10
    },
    forDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    forMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}))

const TakeSurvey = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const classes = useStyles()
    const [surveyToShow, setSurveyToShow] = useState(null)

    const { allFootprintSurveys, hasSurveys } = useSelector((state) => state.applicationInfo)
    useEffect(() => {
        setSurveyToShow(allFootprintSurveys.find((survey) => survey.sequenceNo === location.params))
    }, [setSurveyToShow, allFootprintSurveys, location])
    if (!hasSurveys || !surveyToShow) {
        return null
    }
    return (
        <Box className={classes.container}>
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={false} md={4}>
                        <div className={classes.forDesktop}>
                            <SurveyCardList activeId={surveyToShow.sequenceNo} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.center}>
                        {/* Component to handle any all the Foot Print Survey */}
                        <div className={classes.forMobile}>
                            <MiniSurveyCardList activeId={surveyToShow.sequenceNo} />
                        </div>
                        <ShowSurveyQuestions
                            surveyName={surveyToShow.surveyName}
                            surveyKey={surveyToShow.sequenceNo}
                            surveyQuestions={surveyToShow.surveyQuestions}
                            surveyClick={'from C4lapp'}
                            submitSurvey={(data) => {
                                return dispatch(
                                    submitSurveyThunk(surveyToShow.sequenceNo, surveyToShow.surveyName, data)
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default TakeSurvey
