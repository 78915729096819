import { Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setExtensionEmail } from '../reducer/extension'
import { ROUTES } from '../util/routes'
import './extensionEntry.css'

function ExtensionLogin() {
    const dispatch = useDispatch()
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')

    const LoginUser = () => {
        //email validation regex
        if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@tcs.com$/.test(email)) {
            setError('Please enter a valid TCS email ID')
            return
        }
        dispatch(setExtensionEmail(email))
        setError('')
        history.push(ROUTES.extensionList)
    }

    const withoutLoginUser = () => {
        dispatch(setExtensionEmail('dummyemail@gmail.com'))
        setError('')
        history.push(ROUTES.extensionList)
    }

    return (
        <div
            style={{
                display: 'flex',
                height: '80vh',
                padding: '16px',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <div className="text" style={{ margin: '8px' }}>
                Enter your TCS email ID below to see your carbon footprint score
            </div>
            <input
                className="loginInput"
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
            />
            {error && (
                <Typography variant="body1" style={{ color: 'red', margin: '12px 8px' }}>
                    {error}
                </Typography>
            )}
            <button className="buttonOrange" onClick={() => LoginUser()}>
                Submit
            </button>
            <Typography variant="body1" style={{ margin: '24px 8px' }}>
                OR
            </Typography>
            <button className="buttonOrange" onClick={() => withoutLoginUser()}>
                I am not a TCS employee
            </button>
        </div>
    )
}

export default ExtensionLogin
