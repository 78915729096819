import { Box, Container, makeStyles, Typography, Grid } from '@material-ui/core'
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { SnackbarContext } from '../Context/SnackbarContext'
import CarbonIcon from '../icon/svg-icons/Carbon footprint-pink.svg'
import FoodIcon from '../icon/svg-icons/whiteFoodIcon.svg'
import PlasticIcon from '../icon/svg-icons/whitePlasticIcon.svg'
import WaterIcon from '../icon/svg-icons/whiteWaterIcon.svg'
import { setCarbonResponseData, setExtensionCountry } from '../reducer/extension'
import getFootprintSurveyExtension from '../redux-thunk/getFootprintSurveyExtension'
import { ROUTES } from '../util/routes'
import { CarbonSurveyCard } from './carbonCard'
import { CountryDropDown } from './countryDropDown'
import { GrayCard } from './greyCards'
import { NoOfHouseholdCard } from './noOfHouseholdCard'
import { firebaseRemoteConfig } from '..'
import { ShowSurveyQuestions } from '../components/takeSurvey/ShowSurveyQuestions'

import getFootprintSurveyListThunk from './../redux-thunk/getFootprintSurveyListThunk'
import submitSurveyThunk from '../redux-thunk/submitSurveyThunk'
import verifyUserThunk from '../redux-thunk/verifyUserThunk'
import { setSessionToken } from '../reducer/session'
import {Scrollbar} from 'smooth-scrollbar-react';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        backgroundColor: '#FBFBFB',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4)
        }
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        padding: 10
    },
    forDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    forMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}))

function ExtensionSurveyList() {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { ExtensionsurveyName } = useParams()
    // console.log(ExtensionsurveyName, '----------')
    let extensionSurvey = null;

    if (ExtensionsurveyName == ':carbon') {
        extensionSurvey = 'Carbon Footprint';
    } else if (ExtensionsurveyName == ':plastic') {
        extensionSurvey = 'Plastic Footprint';
    }
    else if (ExtensionsurveyName == ':water') {
        extensionSurvey = 'Water Footprint';
    }
    else {
        extensionSurvey = 'Consumption Footprint';

    }
    //console.log(extensionSurvey, "extension survey category")

    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const token = useSelector((state) => state.session.token)
    const [sustainability_disclaimer, setSustainability_disclaimer] = useState('')
    const [error, setError] = useState(null)
    const { country, noOfHouseHolds } = useSelector((state) => state.extension)
    const [surveyCarbonQuestions, setSurveyCarbonQuestions] = useState(null)

    // ----------------Testing ----------------------------

    const { carbonSurveyQuestions } = useSelector((state) => state.extension)
    const { allFootprintSurveys } = useSelector((state) => state.applicationInfo)
    const [surveyToShow, setSurveyToShow] = useState(null)

    const surveyGrayCards = [
        {
            name: 'Plastic Footprint',
            image: PlasticIcon
        },
        {
            name: 'Consumption Footprint',
            image: FoodIcon
        },
        {
            name: 'Water Footprint',
            image: WaterIcon
        }
    ]
    const { countries } = useSelector((state) => state.extension)

    const { handleClick } = useContext(SnackbarContext)

    // useEffect(() => {
    //     if (allFootprintSurveys.length > 0) {
    //         setSurveyToShow(allFootprintSurveys.find((each) => each.surveyName === 'Carbon Footprint').surveyQuestions)
    //         setSurveyObject(allFootprintSurveys.find((each) => each.surveyName === 'Carbon Footprint'))
    //     }
    // }, [setSurveyToShow])
    useEffect(() => {
        setSurveyToShow(allFootprintSurveys.find((survey) => survey.surveyName === extensionSurvey).surveyQuestions)
    }, [setSurveyToShow, allFootprintSurveys])

    if (loading) {
        return (
            <Box className={classes.wrapper}>
                <Container maxWidth="md">
                    <Typography variant="h5" className={classes.title}>
                        Loading Surveys .....
                    </Typography>
                </Container>
            </Box>
        )
    }
    if (!surveyToShow) {
        return null
    }

    return (
        <Box className={classes.wrapper}>
            <Box className={classes.container}>
                {/* {console.log(surveyToShow)} */}
                    <div className='App'>
      <Scrollbar
      //alwaysShowTracks
        plugins={{
          overscroll: {
            effect: 'bounce',
          },
        }}>
          
       
        <div className='list-data' style={{height: '480px'}}>
            <div>
            <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} className={classes.center}>
                <ShowSurveyQuestions
                                surveyName={extensionSurvey}
                                surveyKey={1}
                                surveyQuestions={surveyToShow}
                                surveyClick={'from plugin'}
                                submitSurvey={(data) => {
                                    return dispatch(
                                        submitSurveyThunk("1", extensionSurvey, data)
                                    )
                                }

                                }
                            />
                            </Grid>
                            </Grid>
                            </Container>
                            </div>

        </div>
        
      </Scrollbar>
    </div>

                           
                  
            </Box>
        </Box>
    )
}
export default ExtensionSurveyList