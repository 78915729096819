import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import submitCarbonSurveyThunk from '../redux-thunk/submitCarbonSurveyThunk'
import submitCarbonSurveyWithOTPThunk from '../redux-thunk/submitCarbonSurveyWIthOTPthunk'
import { ROUTES } from '../util/routes'
import './extensionEntry.css'

function ExtensionVerify() {
    const dispatch = useDispatch()
    const history = useHistory()
    const [OTP, setOTP] = useState('')
    const [error, setError] = useState('')

    const VerifyUser = () => {
        if (!new RegExp(/\d/).test(OTP)) {
            setError('Please enter a valid OTP address')
            return
        }
        setError('')
        dispatch(submitCarbonSurveyWithOTPThunk(OTP))
            .then(() => {
                history.push(ROUTES.extensionResult)
            })
            .catch((err) => setError('Please try Again!!!'))
    }

    return (
        <div className="extensionEntry">
            <div className="card">
                <div className="text">Enter OTP Received In Your Email</div>
                <input
                    className="loginInput"
                    type="number"
                    value={OTP}
                    placeholder="Enter the OTP"
                    onChange={(e) => setOTP(e.target.value)}
                />
                {error && (
                    <p
                        style={{
                            margin: 0,
                            fontSize: 10,
                            color: 'red',
                            fontWeight: 700
                        }}
                    >
                        {error}
                    </p>
                )}
                <div style={{ margin: '12px', fontSize: '12px' }}>
                    Don’t receive OTP?{' '}
                    <span style={{ color: '#f27535' }} onClick={() => dispatch(submitCarbonSurveyThunk())}>
                        RESEND OTP
                    </span>
                </div>
                <button className="buttonOrange" onClick={() => VerifyUser()}>
                    Verify
                </button>
            </div>
        </div>
    )
}

export default ExtensionVerify
