import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setSessionToken } from "../reducer/session";
// import loadSessionThunk from "../redux-thunk/loadSessionThunk";
import { ROUTES } from '../util/routes'
import MiniLoader from '../components/common/miniLoader/miniLoader'
import '../components/common/styles/styles.css'
import getFootprintSurveyListThunk from '../redux-thunk/getFootprintSurveyListThunk'

class LoadSession extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isError: false
        }
    }
    componentDidMount() {
        let sessionID = this.props.match.params.sessionID
        let screen = this.props.match.params.screen
        this.getUserDetails(sessionID, screen)
    }

    getUserDetails = (sessionID, screen) => {
        this.setState({
            isLoading: true
        })
        this.props
            .dispatch(getFootprintSurveyListThunk(sessionID))

            .then(() => {
            // console.log(sessionID, 'enc token')
                if (screen === 'survey') {
                    this.props.history.push({
                        pathname: ROUTES.survey
                    })
                }
                if (screen === 'pledges') {
                    this.props.history.push({
                        pathname: ROUTES.pledgeList
                    })
                }
                if (screen === 'extensionPledges') {
                    this.props.history.push({
                        pathname: ROUTES.extensionPledges
                    })
                }
                if (screen === 'survey=carbon') {
                    this.props.history.push({
                        pathname: `${ROUTES.extensionList}/:carbon`
                    })
                }
                if (screen === 'survey=water') {
                    this.props.history.push({
                        pathname: `${ROUTES.extensionList}/:water`
                    })
                }
                if (screen === 'survey=plastic') {
                    this.props.history.push({
                        pathname: `${ROUTES.extensionList}/:plastic`
                    })
                }
                if (screen === 'survey=consumption') {
                    this.props.history.push({
                        pathname: `${ROUTES.extensionList}/:consumption`
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                    isError: true
                })
            })
    }

    render() {
        return <div className="overallBody2">{this.state.isLoading ? <MiniLoader /> : <h1>Unauthorized User</h1>}</div>
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.session.token,
        isRegistrationSurvey: state.session.isRegistrationSurvey
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (payload) => dispatch(payload)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoadSession))
