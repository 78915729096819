import { Radio, withStyles } from '@material-ui/core'

const CustomRadio = withStyles(() => ({
    root: {
        transform: 'scale(0.8)',
        paddingRight: 5
    },
    checked: {
        color: '#4E84C4 !important'
    }
}))(Radio)

export default CustomRadio
