import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setExtensionNoOfHouseHolds } from '../reducer/extension'

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '4px 4px 12px #DDDDDD99',
        backgroundColor: '#ffffff',
        display: 'flex',
        margin: theme.spacing(2, 0),
        borderRadius: 15,
        padding: 15,
        maxWidth: 500,
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            marginRight: 4,
            marginLeft: 4
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 15,
            maxWidth: 'unset',
            width: 250,
            minWidth: 250,
            minHeight: 250,
            height: 250
        }
    },
    cardTopContent: {
        display: 'flex',
        margin: theme.spacing(1, 0),
        borderRadius: 15,
        maxWidth: 500,
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            marginRight: 4,
            marginLeft: 4
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 'unset',
            width: 250,
            minWidth: 250
        }
    },

    contentContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center'
        }
    },
    imageContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            flex: 1,
            display: 'block'
        }
    },
    title: {
        font: 'normal normal normal 16px/30px Roboto',
        letterSpacing: 0,
        fontWeight: 'bold',
        color: '#333333',
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center'
        }
    },
    subTitle: {
        font: 'normal normal normal 14px/19px Roboto',
        letterSpacing: 0,
        color: '#999999',
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center'
        }
    },
    icon: {
        height: '3rem',
        [theme.breakpoints.up('lg')]: {
            height: '5rem'
        }
    }
}))

export const NoOfHouseholdCard = ({ noOfHouseholds }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    return (
        <Box component="button" className={classes.card}>
            <div className={classes.cardTopContent}>
                <div className={classes.contentContainer}>
                    <Typography variant="h5" className={classes.title}>
                        No of people in household <span style={{ color: 'red' }}>*</span>
                    </Typography>
                </div>
                <div style={{ flex: '1' }}>
                    <input
                        onChange={(event) => {
                            dispatch(setExtensionNoOfHouseHolds(event.target.value))
                        }}
                        value={noOfHouseholds}
                        placeholder="Number of people"
                        type="number"
                        style={{
                            width: '100%',
                            padding: '4px',
                            background: '#F1F2F6',
                            outline: 'none',
                            borderRadius: '4px',
                            border: 'none'
                        }}
                        min="1"
                        max="99"
                        maxLength="2"
                    />
                </div>
            </div>
        </Box>
    )
}
