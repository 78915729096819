import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '4px 4px 12px #DDDDDD99',
        backgroundColor: '#ffffff',
        display: 'flex',
        marginBottom: theme.spacing(2),
        borderRadius: 15,
        padding: 15,
        maxWidth: 500,
        width: '100%',
        flexDirection: 'column',
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            marginRight: 4,
            marginLeft: 4
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 15,
            maxWidth: 'unset',
            width: 250,
            minWidth: 250,
            minHeight: 250,
            height: 250
        }
    },
    cardTopContent: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        borderRadius: 15,
        maxWidth: 500,
        width: '100%',
        flexDirection: 'row',
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            marginRight: 4,
            marginLeft: 4
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 'unset',
            width: 250,
            minWidth: 250
        }
    },

    contentContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center'
        }
    },
    imageContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            flex: 1,
            display: 'block'
        }
    },
    title: {
        font: 'normal normal normal 16px/30px Roboto',
        letterSpacing: 0,
        fontWeight: 'bold',
        color: '#333333',
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center'
        }
    },
    subTitle: {
        font: 'normal normal normal 14px/19px Roboto',
        letterSpacing: 0,
        color: '#999999',
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center'
        }
    },
    icon: {
        height: '3rem',
        [theme.breakpoints.up('lg')]: {
            height: '5rem'
        }
    }
}))

export const CarbonSurveyCard = ({ data, onCardClick }) => {
    const classes = useStyles()
    return (
        <Box onClick={onCardClick} component="button" className={classes.card}>
            <div className={classes.cardTopContent}>
                <div className={classes.contentContainer}>
                    <Typography variant="h5" className={classes.title}>
                        {data && data.name}
                    </Typography>
                    <Typography variant="body1" className={classes.subTitle}>
                        {data && data.requiredTime}
                    </Typography>
                </div>
                <div className={classes.imageContainer}>
                    {data && <img src={data.image} alt={`${data.name} icon`} className={classes.icon} />}
                </div>
            </div>
        </Box>
    )
}
