import { makeStyles } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import MiniSurveyCard from './MiniSurveyCard'
const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        padding: '15px 5px 5px 5px'
    }
}))
function MiniSurveyCardList({ activeId, showCompleted = true, isClickable = false }) {
    const classes = useStyles()
    const surveyList = useSelector((state) => state.applicationInfo.allFootprintSurveys)
    const keys = useRef(['', 'Carbon', 'Plastic', 'Water', 'Consumption'])
    const [rendererList, setRendererList] = useState([])
    useEffect(() => {
        setRendererList(
            surveyList.map((singleSurvey) => ({
                id: singleSurvey.id,
                status:
                    !singleSurvey.isAllowed &&
                    singleSurvey.lastSubmissionValue >= 0.0 &&
                    activeId !== singleSurvey.sequenceNo &&
                    showCompleted
                        ? 'completed'
                        : activeId === singleSurvey.sequenceNo
                        ? 'active'
                        : 'inactive',
                name: keys.current[singleSurvey.sequenceNo]
            }))
        )
    }, [surveyList, activeId, showCompleted])
    const image = [{ id: 'CarbonIcon' }, { id: 'PlasticIcon' }, { id: 'WaterIcon' }, { id: 'FoodIcon' }]
    return (
        <div className={classes.container}>
            {rendererList.map(
                (item, key) =>
                // Hiding plastic survey 
                    item.name !== 'Plastic' && (
                        <MiniSurveyCard
                            isClickable={isClickable}
                            key={item.id}
                            id={key}
                            name={item.name}
                            status={item.status}
                            image={image[key].id}
                        />
                    )
            )}
        </div>
    )
}

export default MiniSurveyCardList
