import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
    text: (props) => ({
        fontFamily: props.$font ? props.$font : 'Poppins',
        fontSize: props.$size ? props.$size : '18px',
        fontWeight: props.$weight ? props.$weight : '400',
        lineHeight: props.$line_height ? props.$line_height : '25px',
        color: props.$color ? props.$color : '#000000',
        textAlign: props.$alignment ? props.$alignment : 'left',
        margin: '0px',
        padding: '0px',

        '& > span': {
            fontFamily: props.$font ? props.$font : 'Poppins',
            fontSize: props.$size ? props.$size : '18px',
            fontWeight: props.$weight ? props.$weight : '400',
            lineHeight: props.$line_height ? props.$line_height : '25px',
            color: props.$color ? props.$color : '#000000',
            textAlign: props.$alignment ? props.$alignment : 'left',
            margin: '0px',
            padding: '0px'
        }
    })
}))

const Text = ({ children, ...props }) => {
    const classes = useStyles(props)
    return <p className={classes.text}>{children}</p>
}

export default Text
