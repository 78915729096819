import axios from 'axios'
import { API_ROUTES } from '../util/routes'

function updatePledgeLog({ pledgeId, selectedDates = [], unselectedDates = [] }) {
    return async function (dispatch, getState, { endpoint }) {
        if (!pledgeId) {
            throw new Error('Invalid values')
        }
        const token = getState().session.token
        const { data } = await axios.post(
            endpoint + API_ROUTES.UPDATE_PLEDGE,
            {
                pledge_id: pledgeId,
                selectedDates: selectedDates,
                unselectedDates: unselectedDates
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (data.status !== 'success') {
            throw new Error('Invalid User')
        }
        return data.message
    }
}
export default updatePledgeLog
