import axios from 'axios'
import { setPageLoader } from '../reducer/applicationInformation'
import { API_ROUTES } from '../util/routes'
import fetchPledgeData from './getPledgeThunk'

function pledgeAction({ pledgeId, action }) {
    return async function (dispatch, getState, { endpoint }) {
        if (!pledgeId || !action) {
            throw new Error('Invalid values')
        }

        const token = getState().session.token
        const { data } = await axios.post(
            endpoint + API_ROUTES.BEGIN_END_PLEDGE,
            {
                pledge_id: pledgeId,
                pledge_flag: action
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )

        if (data.status !== 'success') {
            throw new Error('Invalid User')
        }

        dispatch(fetchPledgeData())
        return data.message
    }
}
export default pledgeAction
