import { FormControl, FormControlLabel, FormLabel, makeStyles, RadioGroup } from '@material-ui/core'
import React from 'react'
import CustomPaper from '../Customized/CustomPaper'
import CustomRadio from '../Customized/CustomRadio'

/***
 * Component to handle the Radio type Options
 * Expected input are
 * 1) The array of the Radio options
 * 2) Change Function to replace a particular option with updated Option Object
 */

const useStyles = makeStyles(() => ({
    title: {
        font: 'normal normal normal 16px/30px Roboto',
        letterSpacing: 0,
        color: '#333333',
        fontWeight: '700',
        margin: '0px'
    },
    titleFocus: {
        color: '#333333!important'
    },
    optionText: {
        font: 'normal normal normal 14px/30px Roboto',
        letterSpacing: 0,
        height: 26,
        color: '#333333'
    }
}))

export const HandleRadioFUQ = ({ questionText, questionOptions, onChange, withWrapper = false }) => {
    const classes = useStyles()
    const selectedValue = questionOptions.find((opt) => opt.isSelected)?.id
    const Component = withWrapper ? CustomPaper : 'div'
    return (
        <Component {...(withWrapper ? { elevation: 10 } : {})}>
            <FormControl component="fieldset">
                <FormLabel classes={{ focused: classes.titleFocus }} className={classes.title} component="legend">
                    {questionText}
                </FormLabel>
                <RadioGroup value={selectedValue} onChange={({ target: { value } }) => onChange(value)}>
                    {questionOptions.map((singleOption) => (
                        <FormControlLabel
                            classes={{
                                label: classes.optionText
                            }}
                            key={singleOption.id}
                            value={singleOption.id}
                            control={<CustomRadio />}
                            label={singleOption.name}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Component>
    )
}
