import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import EmployeeCommute from './EmployeeCommute'
import SustainabilityCourse from './SustainabilityCourse'
function HandleBannerRoute() {
    const params = useParams()
    const [screenToShow, setScreenToShow] = useState('')
    useEffect(() => {
        setScreenToShow(params.bannerScreen)
    }, [setScreenToShow])
    function showScreen() {
        console.log(screenToShow)
        switch (screenToShow) {
            case 'EmployeeCommute':
                return <EmployeeCommute />
            case 'SustainabilityCourse':
                return <SustainabilityCourse />
            default:
                return 'No Screen'
        }
    }
    return <div>{showScreen()}</div>
}

export default HandleBannerRoute
