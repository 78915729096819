import React, { Component } from 'react'
import './miniLoader.css'
// import Loader from './logo_animation.gif'
// import Loader from './C4-transaparent.gif'
import Loader from "./C4L_Loader.gif"
class MiniLoader extends Component {
    render() {
        return (
            <div className="loader">
                <img src={Loader} alt="miniLoader" className ="miniloader_mini" />     
            </div>
        )
    }
}

export default MiniLoader
