import { combineReducers } from 'redux'
import surveyList from './surveyList'
import layout from './layout'
import session from './session'
import reducer$applicaitonInformation from './applicationInformation'
import reducer$userInfo from './userInfo'
import extension from './extension'
import eventsReducer from './eventsReducer'

export default combineReducers({
    applicationInfo: reducer$applicaitonInformation,
    userInfo: reducer$userInfo,
    surveyList,
    layout,
    session,
    extension,
    events: eventsReducer
})
