import { AccordionDetails, withStyles } from '@material-ui/core'

const CustomAccordionDetails = withStyles(() => ({
    root: {
        boxShadow: 'none',
        margin: 0,
        padding: 0,
        background: 'transparent',
        display: 'flex',
        flexDirection: 'column'
    }
}))(AccordionDetails)

export default CustomAccordionDetails
