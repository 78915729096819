import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import MiniCardCarbonIcon from '../../icon/MiniCardCarbonIcon'
import MiniCardConsumptionIcon from '../../icon/MiniCardConsumptionIcon'
import MiniCardPlasticIcon from '../../icon/MiniCardPlasticIcon'
import MiniCardWaterIcon from '../../icon/MiniCardWaterIcon'
const useStyles = makeStyles(() => ({
    cardContainer: {
        paddingRight: 8,
        paddingLeft: 8
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 80,
        textAlign: 'center'
    },
    font: {
        font: 'normal normal normal 12px/30px Roboto',
        fontWeight: '400'
    },
    whiteBackground: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '2px 2px 8px #dddddd99',
        padding: 10,
        margin: 4,
        borderRadius: 10
    },
    makeContained: {
        flex: 1
    }
}))
const MiniSurveyCard = ({ name, status, image, id, isClickable }) => {
    const classes = useStyles()
    const history = useHistory()
    const { resultList } = useSelector((state) => state.applicationInfo)
    return (
        <div className={classes.makeContained}>
            <label className={classes.label}>
                <div
                    className={classes.whiteBackground}
                    style={{
                        background: status === 'active' ? '#4E84C4' : status === 'completed' ? '#01AF7B' : 'white',
                        color: ['completed', 'active'].includes(status) ? 'white' : '#999999'
                    }}
                    onClick={() => {
                        if (isClickable && resultList[name.toLowerCase()].footprintValue !== null) {
                            history.replace({
                                state: {
                                    type: name.toLowerCase(),
                                    id: `${id + 1}`
                                }
                            })
                        }
                    }}
                >
                    <span>
                        {image === 'CarbonIcon' && <MiniCardCarbonIcon size={24} status={status} />}
                        {image === 'FoodIcon' && <MiniCardConsumptionIcon size={24} status={status} />}
                        {image === 'WaterIcon' && <MiniCardWaterIcon size={24} status={status} />}
                        {image === 'PlasticIcon' && <MiniCardPlasticIcon size={24} status={status} />}
                    </span>
                </div>
                <div
                    className={classes.font}
                    style={{
                        color: status === 'active' ? '#4E84C4' : status === 'completed' ? '#01AF7B' : '#999999'
                    }}
                >
                    {name}
                </div>
            </label>
        </div>
    )
}

export default MiniSurveyCard
