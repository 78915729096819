import React from 'react'
import GreenTick from '../../icon/GreenTick'
import CustomAccordion from './CustomAccordion'
import CustomAccordionDetails from './CustomAccordionDetails'
import CustomAccordionSummary from './CustomAccordionSummary'

const CompleteCustomAccordian = ({ withSelectedFlag = true, flag, onChange, children, extra }) => (
    <>
        <CustomAccordion expanded={flag} onChange={onChange}>
            <CustomAccordionSummary>
                {children}
                {withSelectedFlag && flag && <GreenTick size={25} />}
            </CustomAccordionSummary>
            <CustomAccordionDetails>{extra}</CustomAccordionDetails>
        </CustomAccordion>
    </>
)

export default CompleteCustomAccordian
