import { Typography } from '@material-ui/core'
import React from 'react'
import styles from './pledgeProgressBar.module.css'

// Component to handle the progress bar changes
export const PledgeProgressBar = ({ value, total ,percentageValue}) => (
    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '16px' }}>
        <div style={{ flex: 1 }} className={styles.progress}>
            {/* <div style={{ width: `${(value / total) * 100}%` }}></div> */}
            <div style={{ width: `${percentageValue}%` }}></div>
            <div />
        </div>
        <Typography style={{ paddingLeft: '16px' }}>{percentageValue.toFixed(1)}%</Typography>
    </div>
)

export default PledgeProgressBar
