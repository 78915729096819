import { v4 } from 'uuid'

function addUniqueId(payload) {
    if (payload instanceof Array) {
        return payload.map(addUniqueId)
    } else if (payload instanceof Object) {
        const update = {
            id: v4()
        }
        Object.keys(payload).forEach((key) => (update[key] = addUniqueId(payload[key])))
        return update
    } else {
        return payload
    }
}

export { addUniqueId }
