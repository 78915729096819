import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import CustomPaper from '../../components/Customized/CustomPaper'
import CompleteCustomAccordian from '../Customized'
import { HandleTextOptions } from './HandleTextOptions'
import { MainCheckboxOption } from './MainCheckboxOption'
import { MainRadioOption } from './MainRadioOption'
/***
 * Handle the question that is supposed to be displayed on the screen
 *
 * Expects props are
 * 1) Question Object
 * 2) Question number that is supossed to be displayed
 * 3) Updated function that will replace this Question Object with new one
 */

const useStyles = makeStyles({
    question: {
        margin: '18px 0px',
        color: 'black',
        fontWeight: '500'
    }
})
export const HandleQuestion = ({
    withWrapper = false,
    question,
    handleArrayQuestions,
    questionNumber = '',
    setCheckboxOption,
    setRadioOption,
    setTextOption,
    selectFUQRadio,
    setFUQText
}) => {
    const classes = useStyles()
    if (question instanceof Array) {
        return question.map((singleQuestion, idx) => (
            <HandleQuestion
                question={singleQuestion}
                key={singleQuestion.id}
                questionNumber={idx === 0 ? questionNumber : ''}
                setRadioOption={handleArrayQuestions(singleQuestion.id)}
                setTextOption={handleArrayQuestions(singleQuestion.id)()}
                selectFUQRadio={() => {}}
                setFUQText={() => {}}
            />
        ))
    }

    const Component = withWrapper ? CustomPaper : 'div'

    return (
        <Component {...(withWrapper ? { elevation: 10 } : {})}>
            <Typography className={classes.question} variant="body1">
                {questionNumber && (
                    <>
                        {questionNumber}
                        <span>. </span>{' '}
                    </>
                )}
                {question.questionName}
            </Typography>
            {/* Rendering Option based on the Option Type */}
            {question.optionType.toLowerCase() === 'checkbox' &&
                question.questionOptions &&
                question.questionOptions.map((singleCheckboxOption) => (
                    <MainCheckboxOption
                        key={singleCheckboxOption.id}
                        option={singleCheckboxOption}
                        selectFUQRadio={selectFUQRadio(singleCheckboxOption.id)}
                        setFUQText={setFUQText(singleCheckboxOption.id)}
                        toggleSelect={setCheckboxOption(singleCheckboxOption.id)}
                    />
                ))}
            {question.optionType.toLowerCase() === 'radio' &&
                question.questionOptions &&
                question.questionOptions.map((singleQuestionOption) => (
                    <MainRadioOption
                        key={singleQuestionOption.id}
                        option={singleQuestionOption}
                        selectFUQRadio={selectFUQRadio(singleQuestionOption.id)}
                        setFUQText={setFUQText(singleQuestionOption.id)}
                        toggleSelect={setRadioOption(singleQuestionOption.id)}
                    />
                ))}
            {question.optionType.toLowerCase() === 'text' && (
                <CompleteCustomAccordian flag={true} withSelectedFlag={false} onChange={() => {}}>
                    <Box style={{ flex: 'column', width: '100%' }}>
                        <HandleTextOptions withSelectedFlag={false} onChange={setTextOption} question={question} />
                    </Box>
                </CompleteCustomAccordian>
            )}
        </Component>
    )
}
