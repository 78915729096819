import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'
import Layout from '../components/layout/layout'
import DetailedResult from '../components/result/detailedResult'
import TakeSurvey from '../components/takeSurvey/takeSurvey'
import { setEnv } from '../reducer/applicationInformation'
import Login from '../screens/login/login'
import { ROUTES } from '../util/routes'
import { Redirect } from 'react-router'
import ExtensionEntry from '../extension'
import ExtensionSurveyList from '../extension/extensionSurveyList'
import LoadSession from './LoadSession'
import LogPledge from './LogPledge'
import PledgeScreen from './PledgeScreen'
import SinglePledge from './PledgeScreen/SinglePledge'
import SurveyListScreen from './surveylist'
import TakeCarbonSurvey from '../components/takeSurvey/takeCarbonSurvey'
import ExtensionLogin from '../extension/login'
import ExtensionResult from '../extension/extensionResult'
import LandingPage from '../landingPage/sp'
import ExtensionVerify from '../extension/verify'
import Banner from './banner'
// import TermsAndConditions from '../landingPage/termsAndConditions'
// import PrivacyPolicy from '../landingPage/privacyPolicy'
// import ErrorPage from './ErrorPage'
import MiniLoader from '../components/common/miniLoader/miniLoader'
import ExtensionPledgeScreen from './PledgeScreen/entensionPledges'
import ExtensionSinglePledge from './PledgeScreen/extensionSinglePledge'
import ExtensionLogPledge from './LogPledge/extensionPledgeLog'
import HandleBannerRoute from './banner/HandleBannerRoute'
import LandingScreen from '../pages/LandingScreen'
import Privacy from '../pages/PrivacyTerms/Privacy'
import Terms from '../pages/PrivacyTerms/Terms'
import EventsScreen from '../pages/EventsScreen/EventsScreen'
import EventDetails from '../pages/EventsScreen/EventDetails'
// import MiniLoader from '../components/common/miniLoader/miniLoader'

function EntryPoint() {
    const dispatch = useDispatch()
    const location = useLocation()
    const { search } = useLocation()
    useEffect(() => {
        const queryString = search.split('?')[1]
        console.log(queryString)
        if (queryString) {
            const viewString = queryString.split('&').find((e) => e.startsWith('view='))
            console.log(viewString)
            if (viewString && viewString === 'view=digizen') {
                dispatch(setEnv(true))
                return
            }
        }
        dispatch(setEnv(false))
    }, [search, dispatch])
    // function getStartTime() {
    //     var d = new Date()
    //     var startTime = d.getTime()
    //     console.log(location.pathname, startTime)
    // }
    return (
        <Switch>
            <Layout>
                {/* For Auth Process*/}
                {/* {getStartTime()} */}
                <Route path={ROUTES.login} component={Login} />
                <Route path={ROUTES.banner} component={Banner} exact />
                <Route path={`${ROUTES.banner}/:bannerScreen`} component={HandleBannerRoute} />
                {/* <Route path={ROUTES.privacy} component={Privacy} />
                <Route path={ROUTES.termsAndCondtions} component={Terms} /> */}
                <Route path={ROUTES.extensionResult} component={ExtensionResult} />
                <Route path={ROUTES.loginCarbonSurvey} component={ExtensionLogin} />
                <Route path={ROUTES.verifyCarbonSurvey} component={ExtensionVerify} />
                {/*  */}
                <Route path={ROUTES.extension} component={ExtensionEntry} exact />
                {/* <Route path={ROUTES.extensionList} component={ExtensionSurveyList} /> */}
                {/* <Route path={`${ROUTES.loadSession}/:sessionID/:screen`} component={LoadSession} /> */}
                {/* //extension survey code */}
                <Route path={`${ROUTES.extensionList}/:ExtensionsurveyName`} component={ExtensionSurveyList} />
                {/* // <Route path={`${ROUTES.extensionList}/:surveyName`} component={ExtensionSurveyList} /> */}
                <Route path={`${ROUTES.loadSession}/:sessionID/:screen`} component={LoadSession} />
                <Route path={ROUTES.pledgeList} component={PledgeScreen} />
                <Route path={`${ROUTES.pledgeScreen}/:pledgeId`} component={SinglePledge} />
                <Route path={ROUTES.pledgeLog} component={LogPledge} />
                {/* extension pledges */}
                <Route path={ROUTES.extensionPledges} component={ExtensionPledgeScreen} />
                <Route path={`${ROUTES.extensionPledgeScreen}/:pledgeId`} component={ExtensionSinglePledge} />
                <Route path={ROUTES.ExtensionPledgeLog} component={ExtensionLogPledge} />
                <Route path={ROUTES.survey} component={SurveyListScreen} />
                <Route path={ROUTES.takeSurvey} component={TakeSurvey} />
                <Route path={ROUTES.takeCarbonSurvey} component={TakeCarbonSurvey} />
                <Route path={ROUTES.eachResult} component={DetailedResult} />
                {/* <Route path="/" component={LandingPage} exact /> */}
                <Route path="/mini" component={MiniLoader} />
                {/* <Route path='/404' component ={ErrorPage} />
                <Redirect from="*"  to ="/404" /> */}
                {/* ======================== new marketing page Routes========================= */}
                <Route path="/" component={LandingScreen} exact />
                <Route path={ROUTES.PRIVACY} component={Privacy} exact />
                <Route path={ROUTES.TERMS} component={Terms} exact />
                <Route path={ROUTES.EVENTS_SCREEN} component={EventsScreen} exact />
                <Route path={`${ROUTES.EVENTS_SCREEN}/:eventName`} component={EventDetails} exact />
            </Layout>
        </Switch>
    )
}

export { EntryPoint }
