import axios from 'axios'
import { firebaseRemoteConfig } from '..'
import { setExtensionMessage, setExtensionSurveyFootprint } from '../reducer/extension'
import { API_ROUTES } from '../util/routes'
import CryptoJS from 'crypto-js'
// import crypto from 'crypto'
function submitCarbonSurveyWithOTPThunk(OTP) {
    let encryptedOTP = CryptoJS.SHA256(OTP).toString()
    // console.log(encryptedOTP);
    return async function (dispatch, getState, { endpoint }) {
        await firebaseRemoteConfig.fetchAndActivate()
        const public_token = firebaseRemoteConfig.getValue('public_hash_key').asString()
        const surveyQuestions = getState().extension.carbonSurveyQuestionsResponse
        const { country, noOfHouseHolds, email } = getState().extension
        const { data } = await axios.post(
            endpoint + API_ROUTES.SUBMIT_VERIFIED_EXTENSION_SURVEY_QUESTIONS,
            {
                surveyName: 'Carbon Footprint',
                surveyQuestions,
                encryptedOTP,
                country,
                noOfHouseHolds,
                email
            },
            {
                headers: {
                    Authorization: `Bearer ${public_token}`
                }
            }
        )
        // console.log('data>>>>', data)
        if (data.status !== 'success') {
            throw new Error('Invalid Error ' + data.message)
        }
        const { message, title } = data
        dispatch(setExtensionSurveyFootprint(data.survey_footprint))
        dispatch(setExtensionMessage({ message, title }))
    }
}

export default submitCarbonSurveyWithOTPThunk
