import React from 'react'
const MiniCardPlasticIcon = ({ status = 'inactive', size = 24 }) => {
    if (status === 'active') {
        return (
            <svg
                viewBox="0 0 13.51 24"
                width={size}
                height={size}
                strokeWidth="0.5"
                stroke="#4E84C4"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <path d="M12.25,0h-.78a1.27,1.27,0,0,0-1.26,1.26V6.47A1.25,1.25,0,0,1,9,7.73H4.55A1.25,1.25,0,0,1,3.3,6.47V1.26A1.27,1.27,0,0,0,2,0H1.26A1.26,1.26,0,0,0,0,1.26V22.74A1.25,1.25,0,0,0,1.26,24h11a1.25,1.25,0,0,0,1.26-1.26V1.26A1.27,1.27,0,0,0,12.25,0ZM5.71,18.92H2.93a.56.56,0,0,1-.56-.56.6.6,0,0,1,.1-.32l1.14-2a.56.56,0,1,1,1,.54s0,0,0,0l-.68,1.18H5.71a.57.57,0,0,1,.54.59A.56.56,0,0,1,5.71,18.92Zm.07-4.38a.56.56,0,1,1-1-.54h0l1.46-2.53A.56.56,0,0,1,7,11.25a.53.53,0,0,1,.21.21L8.7,14a.57.57,0,0,1-.2.77.56.56,0,0,1-.76-.2,0,0,0,0,0,0,0l-1-1.7Zm5.07,4.31a.6.6,0,0,1-.27.07H7.79a.56.56,0,0,1,0-1.12H9.62l-.69-1.18a.56.56,0,0,1,1-.57s0,0,0,0l1.16,2A.56.56,0,0,1,10.85,18.85Z" />
                    </g>
                </g>
            </svg>
        )
    }
    if (status === 'completed') {
        return (
            <svg
                viewBox="0 0 13.51 24"
                width={size}
                height={size}
                strokeWidth="0.5"
                stroke="#12AF7B"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <path d="M12.25,0h-.78a1.27,1.27,0,0,0-1.26,1.26V6.47A1.25,1.25,0,0,1,9,7.73H4.55A1.25,1.25,0,0,1,3.3,6.47V1.26A1.27,1.27,0,0,0,2,0H1.26A1.26,1.26,0,0,0,0,1.26V22.74A1.25,1.25,0,0,0,1.26,24h11a1.25,1.25,0,0,0,1.26-1.26V1.26A1.27,1.27,0,0,0,12.25,0ZM5.71,18.92H2.93a.56.56,0,0,1-.56-.56.6.6,0,0,1,.1-.32l1.14-2a.56.56,0,1,1,1,.54s0,0,0,0l-.68,1.18H5.71a.57.57,0,0,1,.54.59A.56.56,0,0,1,5.71,18.92Zm.07-4.38a.56.56,0,1,1-1-.54h0l1.46-2.53A.56.56,0,0,1,7,11.25a.53.53,0,0,1,.21.21L8.7,14a.57.57,0,0,1-.2.77.56.56,0,0,1-.76-.2,0,0,0,0,0,0,0l-1-1.7Zm5.07,4.31a.6.6,0,0,1-.27.07H7.79a.56.56,0,0,1,0-1.12H9.62l-.69-1.18a.56.56,0,0,1,1-.57s0,0,0,0l1.16,2A.56.56,0,0,1,10.85,18.85Z" />
                    </g>
                </g>
            </svg>
        )
    } else {
        return (
            <svg
                viewBox="0 0 13.51 24"
                width={size}
                height={size}
                strokeWidth="0.5"
                stroke="#999999"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <path d="M12.25,0h-.78a1.27,1.27,0,0,0-1.26,1.26V6.47A1.25,1.25,0,0,1,9,7.73H4.55A1.25,1.25,0,0,1,3.3,6.47V1.26A1.27,1.27,0,0,0,2,0H1.26A1.26,1.26,0,0,0,0,1.26V22.74A1.25,1.25,0,0,0,1.26,24h11a1.25,1.25,0,0,0,1.26-1.26V1.26A1.27,1.27,0,0,0,12.25,0ZM5.71,18.92H2.93a.56.56,0,0,1-.56-.56.6.6,0,0,1,.1-.32l1.14-2a.56.56,0,1,1,1,.54s0,0,0,0l-.68,1.18H5.71a.57.57,0,0,1,.54.59A.56.56,0,0,1,5.71,18.92Zm.07-4.38a.56.56,0,1,1-1-.54h0l1.46-2.53A.56.56,0,0,1,7,11.25a.53.53,0,0,1,.21.21L8.7,14a.57.57,0,0,1-.2.77.56.56,0,0,1-.76-.2,0,0,0,0,0,0,0l-1-1.7Zm5.07,4.31a.6.6,0,0,1-.27.07H7.79a.56.56,0,0,1,0-1.12H9.62l-.69-1.18a.56.56,0,0,1,1-.57s0,0,0,0l1.16,2A.56.56,0,0,1,10.85,18.85Z" />
                    </g>
                </g>
            </svg>
        )
    }
}
export default MiniCardPlasticIcon
