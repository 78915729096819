import React from 'react'
const MiniCardCarbonIcon = ({ status = 'inactive', size = 24 }) => {
    // console.log({ status })
    if (status === 'active') {
        return (
            <svg
                viewBox="0 0 23.83 13.69"
                width={size}
                height={size}
                strokeWidth="0.5"
                stroke="#4E84C4"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <path d="M22.23,6.28h0a4.79,4.79,0,0,0-2.88-1,5.18,5.18,0,0,0-1.11.13,2.72,2.72,0,0,0-2.49-1.72A5.79,5.79,0,0,0,8.59.24a5.87,5.87,0,0,0-2.31,1.3s0,0,0,0A5.23,5.23,0,0,0,4.58,5.3,4.36,4.36,0,0,0,0,9.41a4.35,4.35,0,0,0,4.41,4.28H19.35a4.35,4.35,0,0,0,4.48-4.22A4.11,4.11,0,0,0,22.23,6.28ZM9.48,10.13h.17a1.6,1.6,0,0,0,.8-.19.37.37,0,0,1,.4.06.31.31,0,0,1,.09.21.32.32,0,0,1-.18.29,2.39,2.39,0,0,1-1.15.29A1.7,1.7,0,0,1,7.8,9.23V9.07A1.67,1.67,0,0,1,9.44,7.34h.17a2.44,2.44,0,0,1,1.16.3.3.3,0,0,1,.14.42.35.35,0,0,1-.45.15h0A1.66,1.66,0,0,0,9.65,8a1,1,0,0,0-1.11.91.38.38,0,0,0,0,.15A1,1,0,0,0,9.48,10.13Zm5.39-1.06a1.68,1.68,0,0,1-1.65,1.72h-.16a1.71,1.71,0,0,1-1.81-1.57V9.07A1.74,1.74,0,0,1,13,7.31a1.76,1.76,0,0,1,1.88,1.62A.33.33,0,0,1,14.87,9.07Zm1.25,1.68h-.78a.21.21,0,0,1-.11,0,.18.18,0,0,1-.05-.11.29.29,0,0,1,.07-.2c.17-.23.52-.35.66-.5a.16.16,0,0,0,0-.21c-.1-.08-.37,0-.49,0a.18.18,0,0,1-.16,0,.12.12,0,0,1,0-.18l0,0A.87.87,0,0,1,16,9.42a.39.39,0,0,1,.21.53.16.16,0,0,1,0,.07,2.69,2.69,0,0,1-.6.45l.54,0a.13.13,0,0,1,.16.11A.13.13,0,0,1,16.12,10.75ZM13.19,8h-.13a1,1,0,0,0-1.1.94.57.57,0,0,0,0,.13,1,1,0,0,0,1,1.09,1.06,1.06,0,0,0,1.17-.92V9.07A1,1,0,0,0,13.19,8Z" />
                    </g>
                </g>
            </svg>
        )
    }
    if (status === 'completed') {
        return (
            <svg
                viewBox="0 0 23.83 13.69"
                width={size}
                height={size}
                strokeWidth="0.5"
                stroke="#12AF7B"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <path d="M22.23,6.28h0a4.79,4.79,0,0,0-2.88-1,5.18,5.18,0,0,0-1.11.13,2.72,2.72,0,0,0-2.49-1.72A5.79,5.79,0,0,0,8.59.24a5.87,5.87,0,0,0-2.31,1.3s0,0,0,0A5.23,5.23,0,0,0,4.58,5.3,4.36,4.36,0,0,0,0,9.41a4.35,4.35,0,0,0,4.41,4.28H19.35a4.35,4.35,0,0,0,4.48-4.22A4.11,4.11,0,0,0,22.23,6.28ZM9.48,10.13h.17a1.6,1.6,0,0,0,.8-.19.37.37,0,0,1,.4.06.31.31,0,0,1,.09.21.32.32,0,0,1-.18.29,2.39,2.39,0,0,1-1.15.29A1.7,1.7,0,0,1,7.8,9.23V9.07A1.67,1.67,0,0,1,9.44,7.34h.17a2.44,2.44,0,0,1,1.16.3.3.3,0,0,1,.14.42.35.35,0,0,1-.45.15h0A1.66,1.66,0,0,0,9.65,8a1,1,0,0,0-1.11.91.38.38,0,0,0,0,.15A1,1,0,0,0,9.48,10.13Zm5.39-1.06a1.68,1.68,0,0,1-1.65,1.72h-.16a1.71,1.71,0,0,1-1.81-1.57V9.07A1.74,1.74,0,0,1,13,7.31a1.76,1.76,0,0,1,1.88,1.62A.33.33,0,0,1,14.87,9.07Zm1.25,1.68h-.78a.21.21,0,0,1-.11,0,.18.18,0,0,1-.05-.11.29.29,0,0,1,.07-.2c.17-.23.52-.35.66-.5a.16.16,0,0,0,0-.21c-.1-.08-.37,0-.49,0a.18.18,0,0,1-.16,0,.12.12,0,0,1,0-.18l0,0A.87.87,0,0,1,16,9.42a.39.39,0,0,1,.21.53.16.16,0,0,1,0,.07,2.69,2.69,0,0,1-.6.45l.54,0a.13.13,0,0,1,.16.11A.13.13,0,0,1,16.12,10.75ZM13.19,8h-.13a1,1,0,0,0-1.1.94.57.57,0,0,0,0,.13,1,1,0,0,0,1,1.09,1.06,1.06,0,0,0,1.17-.92V9.07A1,1,0,0,0,13.19,8Z" />
                    </g>
                </g>
            </svg>
        )
    } else {
        return (
            <svg
                viewBox="0 0 23.83 13.69"
                width={size}
                height={size}
                strokeWidth="0.5"
                stroke="#999999"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <path d="M22.23,6.28h0a4.79,4.79,0,0,0-2.88-1,5.18,5.18,0,0,0-1.11.13,2.72,2.72,0,0,0-2.49-1.72A5.79,5.79,0,0,0,8.59.24a5.87,5.87,0,0,0-2.31,1.3s0,0,0,0A5.23,5.23,0,0,0,4.58,5.3,4.36,4.36,0,0,0,0,9.41a4.35,4.35,0,0,0,4.41,4.28H19.35a4.35,4.35,0,0,0,4.48-4.22A4.11,4.11,0,0,0,22.23,6.28ZM9.48,10.13h.17a1.6,1.6,0,0,0,.8-.19.37.37,0,0,1,.4.06.31.31,0,0,1,.09.21.32.32,0,0,1-.18.29,2.39,2.39,0,0,1-1.15.29A1.7,1.7,0,0,1,7.8,9.23V9.07A1.67,1.67,0,0,1,9.44,7.34h.17a2.44,2.44,0,0,1,1.16.3.3.3,0,0,1,.14.42.35.35,0,0,1-.45.15h0A1.66,1.66,0,0,0,9.65,8a1,1,0,0,0-1.11.91.38.38,0,0,0,0,.15A1,1,0,0,0,9.48,10.13Zm5.39-1.06a1.68,1.68,0,0,1-1.65,1.72h-.16a1.71,1.71,0,0,1-1.81-1.57V9.07A1.74,1.74,0,0,1,13,7.31a1.76,1.76,0,0,1,1.88,1.62A.33.33,0,0,1,14.87,9.07Zm1.25,1.68h-.78a.21.21,0,0,1-.11,0,.18.18,0,0,1-.05-.11.29.29,0,0,1,.07-.2c.17-.23.52-.35.66-.5a.16.16,0,0,0,0-.21c-.1-.08-.37,0-.49,0a.18.18,0,0,1-.16,0,.12.12,0,0,1,0-.18l0,0A.87.87,0,0,1,16,9.42a.39.39,0,0,1,.21.53.16.16,0,0,1,0,.07,2.69,2.69,0,0,1-.6.45l.54,0a.13.13,0,0,1,.16.11A.13.13,0,0,1,16.12,10.75ZM13.19,8h-.13a1,1,0,0,0-1.1.94.57.57,0,0,0,0,.13,1,1,0,0,0,1,1.09,1.06,1.06,0,0,0,1.17-.92V9.07A1,1,0,0,0,13.19,8Z" />
                    </g>
                </g>
            </svg>
        )
    }
}
export default MiniCardCarbonIcon
