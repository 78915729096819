import { Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WhiteCross from '../../icon/WhiteCross'
import { setPageLoader } from '../../reducer/applicationInformation'
import MiniLoader from '../common/miniLoader/miniLoader'
import { HandleQuestion } from './HandleQuestion'
import { ProgressBar } from './ProgressBar'
import { useSurveyActionHook } from './useSurveyActionHook'

export const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'left',
        font: 'normal normal normal 21px/37px Roboto',
        fontWeight: '500',
        letterSpacing: 0,
        color: '#111111',
        marginTop: '2%'
    },
    errorBox: {
        background: 'red',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    errorText: {
        margin: 0,
        font: 'normal normal normal 12px/16px Roboto',
        color: 'white',
        fontWeight: 700,
        letterSpacing: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    },
    spacing: {
        padding: 5
    },
    buttonRounded: { borderRadius: 50 },
    buttonRoundedP: {
        borderRadius: 50,
        color: '#4E84C4',
        borderColor: '#4E84C4'
    }
}))

// Component to handle all the footprint survey and show them
export const ShowSurveyQuestions = ({ surveyName, surveyKey, surveyQuestions, submitSurvey, surveyClick }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const showLoader = useSelector((state) => state.applicationInfo.pageLoader)
    const {
        currentQuestionIdx,
        error,
        setError,
        setFUQText,
        selectFUQRadio,
        setRadioOption,
        setTextOption,
        setCheckboxOption,
        surevyClone,
        onSubmit,
        onSkip,
        moveNext,
        moveBack,
        handleArrayQuestions
    } = useSurveyActionHook({
        surveyName,
        surveyQuestions,
        submitSurvey,
        surveyKey,
        surveyClick
    })
    // Edge condition to check if survey is present or not
    if (!surevyClone || surevyClone.length <= 0) {
        return null
    }

    return (
        <>
            {showLoader && <MiniLoader />}
            <div>
                <Typography variant="h3" className={classes.title}>
                    {surveyName} Survey
                </Typography>
                {/* Handle Progress Bar Changes */}
                <ProgressBar
                    current={currentQuestionIdx + 1}
                    total={surevyClone.length}
                    percentage={((currentQuestionIdx + 1) * 100) / surevyClone.length}
                />
                <div>
                    {/* Handle the selected Question */}
                    <HandleQuestion
                        questionNumber={currentQuestionIdx + 1}
                        question={surevyClone[currentQuestionIdx]}
                        handleArrayQuestions={handleArrayQuestions(currentQuestionIdx)}
                        setCheckboxOption={setCheckboxOption(surevyClone[currentQuestionIdx].id)}
                        setRadioOption={setRadioOption(surevyClone[currentQuestionIdx].id)}
                        setTextOption={setTextOption(surevyClone[currentQuestionIdx].id)}
                        selectFUQRadio={selectFUQRadio(surevyClone[currentQuestionIdx].id)}
                        setFUQText={setFUQText(surevyClone[currentQuestionIdx].id)}
                    />

                    {/* Handle the question navigation and submition */}
                    {error && (
                        <Paper className={classes.errorBox}>
                            <Typography variant="body1" className={classes.errorText}>
                                <span style={{ flex: 1 }}>{error}</span>
                                <span onClick={() => setError('')}>
                                    <WhiteCross fill="white" size={20} />
                                </span>
                            </Typography>
                        </Paper>
                    )}
                    <Grid container spacing={0}>
                        <Grid item xs={false} md={3}></Grid>
                        <Grid item xs={6} md={3} className={classes.spacing}>
                            <Button variant="outlined" fullWidth className={classes.buttonRounded} onClick={moveBack}>
                                <Typography variant="button">Back</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={3} className={classes.spacing}>
                            {currentQuestionIdx < surevyClone.length - 1 ? (
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    className={classes.buttonRoundedP}
                                    onClick={moveNext}
                                    style={{
                                        background: '#4E84C4',
                                        color: '#ffffff',
                                        border: 'none'
                                    }}
                                >
                                    <Typography variant="button">Next</Typography>
                                </Button>
                            ) : (
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    className={classes.buttonRoundedP}
                                    onClick={() => {
                                        dispatch(setPageLoader(true))
                                        onSubmit(surveyClick)
                                    }}
                                    style={{
                                        background: '#4E84C4',
                                        color: '#ffffff',
                                        border: 'none'
                                    }}
                                >
                                    <Typography variant="button">Submit</Typography>
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={false} md={3}></Grid>
                    </Grid>
                    {currentQuestionIdx < surevyClone.length - 1 && (
                        <Grid container spacing={0}>
                            <Grid item xs={false} md={3}></Grid>
                            <Grid item xs={12} md={6} className={classes.spacing}>
                                <Button variant="outlined" fullWidth className={classes.buttonRounded} onClick={onSkip}>
                                    <Typography variant="button">Proceed with Default Values</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={false} md={3}></Grid>
                        </Grid>
                    )}
                </div>
            </div>
        </>
    )
}
