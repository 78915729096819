import axios from 'axios'
import { setEvents } from '../reducer/eventsReducer'
import { API_ROUTES } from '../util/routes'

export function getEvents() {
    return async function (dispatch, getState, { endpoint }) {
        const { data } = await axios.post('https://us-central1-tcs-salsa.cloudfunctions.net/webRoutes/event', {
            // headers
        })
        dispatch(setEvents(data))
        return data
    }
}
