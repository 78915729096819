import { Typography } from '@material-ui/core'
import React, { Fragment } from 'react'
import CompleteCustomAccordian from '../Customized'
import { FollowUpQuestion } from './FollowUpQuestion'

export const MainCheckboxOption = ({ option, toggleSelect, selectFUQRadio, setFUQText }) => {
    return (
        <Fragment>
            <CompleteCustomAccordian
                flag={option.isSelected}
                onChange={toggleSelect(option.id)}
                extra={
                    <>
                        {option.isFollowUp &&
                            option.isFollowUp.length > 0 &&
                            option.isFollowUp.map((fuq) => (
                                <FollowUpQuestion
                                    key={fuq.id}
                                    {...{
                                        fuq,
                                        selectFUQRadio: selectFUQRadio(fuq.id),
                                        setFUQText: setFUQText(fuq.id)
                                    }}
                                />
                            ))}
                    </>
                }
            >
                <Typography variant="body1" style={{ fontWeight: 'inherirt', flex: 1 }}>
                    {option.name}
                </Typography>
            </CompleteCustomAccordian>
        </Fragment>
    )
}
