import axios from 'axios'
import { saveGlobalOneFootprintResult } from '../reducer/applicationInformation'
import { API_ROUTES } from '../util/routes'

function submitSurveyThunk(sqno, surveyName, surveyQuestions) {
    return async function (dispatch, getState, { endpoint }) {
        const token = getState().session.token
        const { data } = await axios.post(
            endpoint + API_ROUTES.SUBMIT_SURVEY_QUESTIONS,
            { surveyName, surveyQuestions },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (data.status !== 'success') {
            throw new Error('Invalid Error ' + data.message)
        }
        dispatch(saveGlobalOneFootprintResult(sqno - 1, data.survey_footprint, surveyName.toLowerCase().split(' ')[0]))
        return data
    }
}

export default submitSurveyThunk
