import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import MiniLoader from '../../components/common/miniLoader/miniLoader'
import { SnackbarContext } from '../../Context/SnackbarContext'
import CarbonIcon from '../../icon/svg-icons/Carbon footprint-pink.svg'
import FoodIcon from '../../icon/svg-icons/food-pink.svg'
import PlasticIcon from '../../icon/svg-icons/plastic-pink.svg'
import WaterIcon from '../../icon/svg-icons/water-pink.svg'
import getFootprintSurveyListThunk from '../../redux-thunk/getFootprintSurveyListThunk'
import { ROUTES } from '../../util/routes'
import { SingleSurveyCard } from './SingleSurveyCard'
import { firebaseRemoteConfig } from '../..'
import firebase from 'firebase/app'
import 'firebase/auth'
import moment from 'moment'
import 'firebase/firestore'
const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(0),
        backgroundColor: '#FBFBFB',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4)
        }
    },
    cardsContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    title: {
        margin: '8px 0px',
        letterSpacing: 0,
        font: 'normal normal normal 21px/37px Roboto',
        fontWeight: '700',
        color: '#111111',
        [theme.breakpoints.up('md')]: {
            font: 'normal normal normal 36px/37px Roboto',
            letterSpacing: -0.14
        }
    },
    subTitle: {
        color: '#999999',
        font: 'normal normal normal 16px/19px Roboto',
        margin: 0,
        marginTop: 8,
        marginBottom: 8,
        padding: 0,
        [theme.breakpoints.up('md')]: {
            letterSpacing: 0,
            fontSize: 16
        }
    }
}))

function SurveyListScreen() {
    // Survey Color Theme (In Order)
    const colors = useRef(['#0933A0', '#E92A2A', '#EF9207', '#0F7966'])
    const units = useRef(['Tonnes CO2e', 'Items', 'Liters', '%'])
    const imageRef = useRef([CarbonIcon, PlasticIcon, WaterIcon, FoodIcon])
    const classes = useStyles()
    const token = useSelector((state) => state.session.token)
    const dispatch = useDispatch()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [surveyQuestionsList, setSurveyQuestionsList] = useState(null)
    const [sustainability_disclaimer, setSustainability_disclaimer] = useState('')
    const { emailId, uid } = useSelector((state) => state.session)
    const { handleClick } = useContext(SnackbarContext)
    useEffect(() => {
        setLoading(true)
        dispatch(getFootprintSurveyListThunk(token))
            .then(async ({ surveyList }) => {
                setSurveyQuestionsList(
                    _.map(surveyList, (singleItem) => ({
                        name: singleItem.surveyName,
                        key: singleItem.sequenceNo,
                        order: singleItem.sequenceNo,
                        questions: singleItem.surveyQuestions,
                        status: {
                            lastSubmission: singleItem.lastSubmissionTime,
                            isAllowed: singleItem.isAllowed,
                            lastSubmissionValue: singleItem.lastSubmissionValue,
                            units: units.current[singleItem.sequenceNo - 1]
                        },
                        requiredTime: 'Approximately 2 minutes',
                        image: imageRef.current[singleItem.sequenceNo - 1],
                        color: colors.current[singleItem.sequenceNo - 1]
                    }))
                )
                await firebaseRemoteConfig.fetchAndActivate()
                setSustainability_disclaimer(firebaseRemoteConfig.getValue('sustainability_disclaimer').asString())
            })
            .catch((error) => {
                handleClick({ message: error.message })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [dispatch, handleClick])

    const showResult = useCallback(
        (name, index) => () => {
            var type = name.split(' ')[0].toLowerCase()
            history.push({
                pathname: ROUTES.eachResult,
                state: {
                    type: type,
                    id: `${index}`
                }
            })
        },
        [history]
    )
    const onCardClick = useCallback(
        (index) => () =>
            history.push({
                pathname: ROUTES.takeSurvey,
                params: index
            }),
        [history]
    )

    if (loading) {
        return <MiniLoader />
    }
    return (
        <Box className={classes.wrapper}>
            <Container maxWidth="lg">
                <Typography variant="h5" className={classes.title}>
                    Sustainability Dashboard
                </Typography>
                <Typography variant="body1" className={classes.subTitle}>
                    Please take these surveys to start your sustainability journey.
                    <br />
                    <br />
                </Typography>
                <Box className={classes.cardsContainer}>
                    {surveyQuestionsList &&
                        surveyQuestionsList.map(
                            (data, key) =>
                                //Hiding plastic footprint survey for a requirement
                                data.name !== 'Plastic Footprint' && (
                                    <SingleSurveyCard
                                        key={data.key}
                                        data={data}
                                        onCardClick={onCardClick(data.key)}
                                        showResult={showResult(data.name, data.key)}
                                    />
                                )
                        )}
                </Box>
                <Typography variant="body1" className={classes.subTitle}>
                    <b>Disclaimer: </b>
                    {sustainability_disclaimer}
                </Typography>
            </Container>
        </Box>
    )
}
export default SurveyListScreen
