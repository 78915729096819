import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './chart.css'

function Chart({ data, InternalText, ...props }) {
    const [background, setBackground] = useState()

    useEffect(() => {
        let value = 0
        let backgroundColorString = ''
        data.forEach((element) => {
            backgroundColorString += `${element.color} ${value}% ${value + element.percentage}%,`
            value += element.percentage
        })
        setBackground(
            `radial-gradient(white 65%, transparent 66%), conic-gradient(${backgroundColorString.slice(0, -1)})`
        )
    }, [data])

    return (
        <div className="pieChartRoot" {...props}>
            <div style={{ background }} className="pieChart">
                {InternalText}
            </div>
            <div
                style={{
                    display: 'flex',
                    fontSize: '10px',
                    width: '100%',
                    marginTop: '20px',
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                }}
            >
                {data.map((element, id) => (
                    <div className="label" key={id}>
                        <div className="colorBox" style={{ background: element.color }} />
                        {element.text}
                    </div>
                ))}
            </div>
        </div>
    )
}

Chart.propTypes = {
    data: PropTypes.array.isRequired
}

export default Chart
