import axios from 'axios'
import { saveGlobalPledges } from '../reducer/applicationInformation'
import { API_ROUTES } from '../util/routes'

function fetchPledgeData() {
    return async function (dispatch, getState, { endpoint }) {
        const token = getState().session.token
        const { data } = await axios.get(endpoint + API_ROUTES.FETCH_PLEDGES, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (data.status !== 'success') {
            throw new Error('Invalid User')
        }
        dispatch(saveGlobalPledges(data.pledgesList))
        return data
    }
}
export default fetchPledgeData
