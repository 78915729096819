import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import ShowResult from '../../components/result/ShowResult'
import ResultLevel from '../../components/resultLevels'
import { ROUTES } from '../../util/routes'
const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '4px 4px 12px #DDDDDD99',
        backgroundColor: '#ffffff',
        display: 'flex',
        marginBottom: theme.spacing(2),
        borderRadius: 15,
        padding: 15,
        maxWidth: 500,
        width: '100%',
        flexDirection: 'column',
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            marginRight: 4,
            marginLeft: 4
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 15,
            maxWidth: 'unset',
            width: 250,
            minWidth: 250,
            minHeight: 250,
            height: 250
        }
    },
    cardTopContent: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        borderRadius: 15,
        maxWidth: 500,
        width: '100%',
        flexDirection: 'row',
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            marginRight: 4,
            marginLeft: 4
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 'unset',
            width: 250,
            minWidth: 250
        }
    },

    contentContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center'
        }
    },
    imageContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            flex: 1,
            display: 'block'
        }
    },
    title: {
        font: 'normal normal normal 16px/30px Roboto',
        letterSpacing: 0,
        fontWeight: 'bold',
        color: '#333333',
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center'
        }
    },
    subTitle: {
        font: 'normal normal normal 14px/19px Roboto',
        letterSpacing: 0,
        color: '#999999',
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center'
        }
    },
    icon: {
        height: '3rem',
        [theme.breakpoints.up('lg')]: {
            height: '5rem'
        }
    }
}))

export const SingleSurveyCard = ({ data, onCardClick, showResult }) => {
    const classes = useStyles()
    const history = useHistory()
    const footprintRanges = useSelector((state) => state.applicationInfo.footprintRanges)
    return (
        <>
            <Box
                onClick={
                    (onCardClick && data.status.lastSubmissionValue === null) ||
                    data.status.lastSubmissionValue === undefined
                        ? onCardClick
                        : showResult
                }
                component="button"
                className={classes.card}
            >
                <div className={classes.cardTopContent}>
                    <div className={classes.contentContainer}>
                        <Typography variant="h5" className={classes.title}>
                            {data.name} Survey
                        </Typography>
                        {/* && (data.status.lastSubmissionValue === null || data.status.lastSubmissionValue !== null ) */}
                        {data.status.isAllowed ? (
                            <>
                                <Typography variant="body1" className={classes.subTitle}>
                                    {data.requiredTime}
                                </Typography>
                                {/* The upperlimit, lowerlimit and value will be updated with the api data */}
                            </>
                        ) : (
                            <>
                                <ShowResult
                                    style={{ fontSize: 14, marginBottom: 0 }}
                                    value={data.status.lastSubmissionValue}
                                    unit={data.status.units}
                                    className={classes.title}
                                />
                                {/* {console.log(footprintRanges, data.name.toLowerCase().split(' ')[0])} */}
                                <ResultLevel
                                    upperLimit={footprintRanges[data.name.toLowerCase().split(' ')[0]].max}
                                    lowerLimit={footprintRanges[data.name.toLowerCase().split(' ')[0]].min}
                                    value={
                                        data.name === 'Consumption Footprint'
                                            ? 100 - data.status.lastSubmissionValue
                                            : data.status.lastSubmissionValue
                                    }
                                />
                            </>
                        )}
                    </div>
                    <div className={classes.imageContainer}>
                        <img
                            src={data.image}
                            alt={`${data.name} icon`}
                            className={classes.icon}
                            style={
                                data.status.isAllowed && data.status.lastSubmission !== null
                                    ? { filter: 'grayscale(100%)', opacity: '0.4' }
                                    : {}
                            }
                        />
                    </div>
                </div>
                {data.status.isAllowed && data.status.lastSubmission !== null && (
                    <Typography className={classes.subTitle} style={{ color: '#DB1F1F' }}>
                        {' '}
                        Survey expired{' '}
                    </Typography>
                )}
                {!data.status.isAllowed &&
                    data.status.lastSubmissionValue !== null &&
                    data.status.lastSubmissionValue !== undefined && (
                        <button
                            style={{
                                borderRadius: '12px',
                                width: '100%',
                                padding: '4px',
                                // backgroundColor: '#f27534',
                                // color: '#ffffff',
                                // border: 'none'
                                backgroundColor: 'white',
                                color: '#4E84C4',
                                border: '1px solid #4E84C4',
                                fontSize: '12px'
                            }}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                history.push(ROUTES.pledgeList)
                            }}
                        >
                            {/* Take a Pledge */}
                            Take a Pledge to Reduce Consumption Footprint
                        </button>
                    )}
            </Box>
        </>
    )
}
