import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../util/routes'
import { PledgeProgressBar } from './pledgeProgressBar'

const useStyles = makeStyles(() => ({
    card: {
        background: '#FFFFFF',
        boxShadow: '4px 4px 12px #dddddd',
        borderRadius: '15px',
        padding: '20px',
        margin: '10px 0px 10px 0px'
    },
    duration: {
        fontFamily: 'Roboto',
        fontStyle: ' normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        color: ' #999999'
    },
    text: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#111111'
    },
    button: {
        // border: ' 1px solid #F27535',
        background: '#4E84C4',
        borderRadius: '20px',
        width: '100%',
        display: 'block',
        textAlign: 'center',
        textDecoration: 'none',
        color: 'white',
        padding: '5px'
    }
}))

const ButtonText = (isPledgeEnded) => {
    switch (isPledgeEnded) {
        case false:
            return 'Update the Pledge'
        case true:
            return 'Retake the Pledge'
        default:
            return 'Take the Pledge'
    }
}

const PlegeCard = ({ pledge }) => {
    const classes = useStyles()
    const [percentageValue, setPercent] = useState(0)
    useEffect(() => {
        let temp = (pledge.pledge_recorded_dates.length / pledge.pledge_duration) * 100
        if (temp > 100) {
            setPercent(100)
        } else {
            setPercent(temp)
        }
    }, [setPercent])
    return (
        <div className={classes.card}>
            <p className={classes.text}>{pledge.pledge_name}</p>
            <p className={classes.duration}>
                Duration: {pledge.pledge_duration} Days{' '}
                <span className={classes.duration}>({pledge.pledge_per_points} points)</span>
            </p>
            {pledge.isPledgeTaken && (
                <PledgeProgressBar
                    value={pledge.pledge_recorded_dates.length}
                    total={pledge.pledge_duration}
                    percentageValue={percentageValue}
                />
            )}
            <Link to={`${ROUTES.pledgeScreen}/${pledge.pledge_id}`} className={classes.button}>
                {ButtonText(pledge.isPledgeEnded)}
            </Link>
        </div>
    )
}

export default PlegeCard
