import { IconButton, Snackbar } from '@material-ui/core'
import React, { useCallback } from 'react'
import { Provider } from 'react-redux'
import { HashRouter , BrowserRouter} from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import DemoIcon from './icon/DemoIcon'
import { EntryPoint } from './screens/EntryPoint'
import { SnackbarContext } from './Context/SnackbarContext'
import configureStore from './store'

const { store, persistor } = configureStore()

function App() {
    const [open, setOpen] = React.useState(false)
    const [message, setMessage] = React.useState('500')

    const handleClick = useCallback(({ message = '500: Internal Error' }) => {
        setMessage(message)
        setOpen(true)
    }, [])

    const handleClose = useCallback((_, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }, [])

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <SnackbarContext.Provider value={{ state: open, handleClick, handleClose }}>
                    <BrowserRouter>
                        <EntryPoint />
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            message={message}
                            action={
                                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                    <DemoIcon />
                                </IconButton>
                            }
                        />
                    </BrowserRouter>
                </SnackbarContext.Provider>
            </PersistGate>
        </Provider>
    )
}

export default App

export { SnackbarContext }
