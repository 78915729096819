let initialState = {
    userHabbits: [],
    userPledges: [],
    userSubmitedSurvey: [],
    hasUserHabbits: false,
    hasUserPledges: false,
    hasSubmittedFootprintSurveys: false
}

const reducer$userInfo = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'user-info@@set-habbits':
            return {
                ...state,
                hasUserHabbits: true,
                userHabbits: payload
            }
        case 'user-info@@set-pledges':
            return {
                ...state,
                hasUserPledges: true,
                userPledges: payload
            }

        case 'user-info@@submited-survey':
            return {
                ...state,
                userSubmitedSurvey: payload
            }

        case 'user-info@@submitted-survey':
            return {
                ...state,
                hasSubmittedFootprintSurveys: true
            }
        case 'user-info@@reset':
            return initialState
        default:
            return state
    }
}

const setUserHabbits = (userHabits) => ({
    type: 'user-info@@set-habbits',
    payload: userHabits
})

const setUserPledges = (userPledges) => ({
    type: 'user-info@@set-pledges',
    payload: userPledges
})

const setUserSubmitedSurevy = (userSubmitedSurvey) => ({
    type: 'user-info@@submited-survey',
    payload: userSubmitedSurvey
})

const resetUser = () => ({
    type: 'user-info@@reset'
})

const completedSurveys = () => ({
    type: 'user-info@@submitted-survey'
})

export { setUserHabbits, setUserPledges, resetUser, setUserSubmitedSurevy, completedSurveys }

export default reducer$userInfo
