let initialState = {
    carbonSurveyQuestions: null,
    countries: null,
    isC4LUser: false,
    country: '',
    noOfHouseHolds: '',
    email: '',
    defaultCarbonSurveyValues: null,
    carbonSurveyQuestionsResponse: null,
    footprintResult: null,
    resultMessage: null
}

const extension = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'extension-info@@set-carbon-data':
            return {
                ...state,
                carbonSurveyQuestions: payload.carbonSurvey,
                countries: payload.countries,
                defaultCarbonSurveyValues: payload.defaultCarbonSurvey
            }
        case 'extension-info@@set-response-data':
            return {
                ...state,
                carbonSurveyQuestionsResponse: payload
            }
        case 'extension-info@@set-email':
            return {
                ...state,
                email: payload
            }
        case 'extension-info@@set-noOfHouseHolds':
            // console.log('set no of households', payload)
            return {
                ...state,
                noOfHouseHolds: payload
            }
        case 'extension-info@@set-country':
            return {
                ...state,
                country: payload
            }
        case 'extension-info@@set-footprint':
            return {
                ...state,
                footprintResult: payload
            }
        case 'extension-info@@set-message':
            return {
                ...state,
                resultMessage: payload
            }
        case 'extension-info@@set-isC4LUser':
            return {
                ...state,
                isC4LUser: payload
            }
        case 'extension-info@@reset':
            return initialState
        default:
            return state
    }
}

const setCarbonData = (carbonData) => ({
    type: 'extension-info@@set-carbon-data',
    payload: carbonData
})

const setCarbonResponseData = (carbonResponseData) => ({
    type: 'extension-info@@set-response-data',
    payload: carbonResponseData
})

const setExtensionEmail = (data) => ({
    type: 'extension-info@@set-email',
    payload: data
})

const setExtensionNoOfHouseHolds = (data) => {
    // console.log('set no of households', data)
    return {
        type: 'extension-info@@set-noOfHouseHolds',
        payload: data
    }
}

const setExtensionCountry = (data) => ({
    type: 'extension-info@@set-country',
    payload: data
})

const setExtensionSurveyFootprint = (data) => ({
    type: 'extension-info@@set-footprint',
    payload: data
})

const setExtensionMessage = (data) => ({
    type: 'extension-info@@set-message',
    payload: data
})

const setIsC4LUser = (payload) => ({
    type: 'extension-info@@set-isC4LUser',
    payload
})

export {
    setCarbonData,
    setCarbonResponseData,
    setExtensionEmail,
    setExtensionNoOfHouseHolds,
    setExtensionCountry,
    setExtensionSurveyFootprint,
    setExtensionMessage,
    setIsC4LUser
}

export default extension
