import React from 'react'

function SustainabilityCourse() {
    return (
        <div style={StyledIframe}>
            <iframe src="https://504studios.com/c4l/" height="100%" width="100%" />
        </div>
    )
}

export default SustainabilityCourse
const StyledIframe = {
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100%',
    width: '100%',
    position: 'absolute',
    border:'none',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px'
}
