import { TextField, withStyles } from '@material-ui/core'

const CustomTextField = withStyles(() => ({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderBottom: '2px solid #9f9f9f'
        },
        '& .MuiFilledInput-underline:before': {
            borderBottom: '2px solid #9f9f9f'
        },
        '& .MuiFilledInput-root': {
            backgroundColor: '#4E84C422'
        },
        '& .MuiFilledInput-input': {
            padding: '10px 12px'
        }
    }
}))(TextField)

export default CustomTextField
