import { Box, Button, Container, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ROUTES } from '../../util/routes'
import MiniSurveyCardList from '../ActionItems/MiniSurveyCardList'
import SurveyCardList from '../ActionItems/SurveyCardList'
import Chart from '../chart'
import CustomPaper from '../Customized/CustomPaper'
import ResultLevel from '../resultLevels'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        backgroundColor: '#FBFBFB',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4)
        }
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: 10
    },
    forDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    forMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    title: {
        textTransform: 'capitalize',
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: 30,
        color: 'black',
        textAlign: 'left'
    },
    resultRootBox: {
        padding: 16
    },
    centered: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    spacing: {
        padding: 10
    },
    bottom: {
        '&:hover': {
            backgroundColor: '#4E84C4',
            borderColor: '#4E84C4'
        },
        marginTop: 20,
        background: '#4E84C4',
        borderRadius: 20,
        padding: '10px 20px',
        color: 'white'
    },
    next: {
        borderColor: '#F23268',
        borderRadius: 20,
        padding: '10px 20px',
        color: '#F23268'
    },
    colorBox: {
        width: 20,
        height: 20,
        margin: 4,
        borderRadius: 2,
        display: 'inline-block'
    },
    chart: {
        transform: 'scale(0.8)',
        [theme.breakpoints.up('md')]: {
            transform: 'scale(1)'
        }
    }
}))
function DetailedResult() {
    const history = useHistory()
    // const { type, id } = useParams()
    // const [loading, setLoading] = useState(false)
    const { state: { type, id } = {} } = useLocation()

    const { resultList, allFootprintSurveys } = useSelector((state) => state.applicationInfo)
    // const session = useSelector((state) => state.session.token)
    const footprintRanges = useSelector((state) => state.applicationInfo.footprintRanges)
    // const dispatch = useDispatch()
    const colorLegend = {
        carbon: [
            {
                color: '#27E2B3',
                text: 'Food',
                percentage: (resultList[type]?.footprintCategories['Food'] / resultList[type]?.footprintValue) * 100
            },
            {
                color: '#F8C42D',
                text: 'Transport',
                percentage:
                    (resultList[type]?.footprintCategories['Transport'] / resultList[type]?.footprintValue) * 100
            },
            {
                color: '#F23255',
                text: 'Fuel and power',
                percentage:
                    (resultList[type]?.footprintCategories['Fuel_and_power'] / resultList[type]?.footprintValue) * 100
            }
        ],

        consumption: [
            {
                color: '#F23255',
                text: 'Electronics',
                percentage:
                    (resultList[type]?.footprintCategories['Electronics'] / resultList[type]?.footprintValue) * 100
            },
            {
                color: '#F8C42D',
                text: 'Food',
                percentage: (resultList[type]?.footprintCategories['Food'] / resultList[type]?.footprintValue) * 100
            },
            {
                color: '#27E2B3',
                text: 'Garments',
                percentage: (resultList[type]?.footprintCategories['Garments'] / resultList[type]?.footprintValue) * 100
            }
        ],

        water: [
            {
                color: '#F23255',
                text: 'Personal hygiene',
                percentage:
                    (resultList[type]?.footprintCategories['Personal_hygiene'] / resultList[type]?.footprintValue) * 100
            },
            {
                color: '#F8C42D',
                text: 'Washing clothes',
                percentage:
                    (resultList[type]?.footprintCategories['Washing_clothes'] / resultList[type]?.footprintValue) * 100
            },
            {
                color: '#27E2B3',
                text: 'Washing dishes',
                percentage:
                    (resultList[type]?.footprintCategories['Washing_dishes'] / resultList[type]?.footprintValue) * 100
            }
        ],

        plastic: [
            {
                color: '#A5FBA5',
                text: 'Cosmetic bottles',
                percentage:
                    (resultList[type]?.footprintCategories['Cosmetic_bottles'] / resultList[type]?.footprintValue) * 100
            },
            {
                color: '#999999',
                text: 'Bags',
                percentage: (resultList[type]?.footprintCategories['Bags'] / resultList[type]?.footprintValue) * 100
            },
            {
                color: '#D2EFFF',
                text: 'Bottles',
                percentage: (resultList[type]?.footprintCategories['Bottles'] / resultList[type]?.footprintValue) * 100
            },
            {
                color: '#F23268',
                text: 'Containers',
                percentage:
                    (resultList[type]?.footprintCategories['Containers'] / resultList[type]?.footprintValue) * 100
            },
            {
                color: '#00ACAC',
                text: 'Cutlery',
                percentage: (resultList[type]?.footprintCategories['Cutlery'] / resultList[type]?.footprintValue) * 100
            },
            {
                color: '#F27534',
                text: 'Wrappers',
                percentage: (resultList[type]?.footprintCategories['Wrappers'] / resultList[type]?.footprintValue) * 100
            }
        ]
    }

    const classes = useStyles()

    // useEffect(() => {
    //   setLoading(true)
    //   dispatch(getFootprintSurveyListThunk(session))
    //   .then(() => {
    //     setLoading(false)
    //     setFootprintValue(resultList[type].footprintValue)
    //   })
    // }, [dispatch, session, type, footprintValue])

    // if (loading) {
    //   return <h1>Loading ...</h1>
    // }
    if (!type || !id || resultList[type].footprintValue < 0) {
        return <h1> No Data Found </h1>
    }
    return (
        <Box className={classes.container}>
            <div style={{ height: 20 }}></div>
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={false} md={4}>
                        <div className={classes.forDesktop}>
                            <SurveyCardList activeId={parseInt(id)} showCompleted={false} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.center}>
                        <div className={classes.forMobile}>
                            <MiniSurveyCardList activeId={parseInt(id)} isClickable={true} />
                        </div>
                        <div className={classes.resultRootBox}>
                            <div className={classes.title}>{resultList[type]?.footprintName} Survey</div>
                            <CustomPaper className={classes.centered}>
                                <Chart
                                    data={colorLegend[type]}
                                    InternalText={`${resultList[type]?.footprintValue.toFixed(2)} ${
                                        resultList[type]?.footprintUnits
                                    }`}
                                />
                                <div style={{ margin: '16px' }}>
                                    <ResultLevel
                                        upperLimit={footprintRanges[type]?.max}
                                        lowerLimit={footprintRanges[type]?.min}
                                        value={
                                            type === 'consumption'
                                                ? 100 - resultList[type]?.footprintValue
                                                : resultList[type]?.footprintValue
                                        }
                                    />
                                </div>
                            </CustomPaper>
                            <Box className={`${classes.centered} ${classes.spacing}`}>
                                {allFootprintSurveys[id - 1].isAllowed && (
                                    <Button
                                        className={classes.bottom}
                                        onClick={() => {
                                            history.replace({
                                                pathname: ROUTES.survey
                                            })
                                            history.push({
                                                pathname: ROUTES.takeSurvey,
                                                params: parseInt(id)
                                            })
                                        }}
                                        variant="contained"
                                    >
                                        Take the Survey
                                    </Button>
                                )}
                                <Button
                                    className={classes.bottom}
                                    onClick={() => {
                                        history.push(ROUTES.survey)
                                    }}
                                    variant="contained"
                                >
                                    Go To Sustainability Dashboard
                                </Button>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default DetailedResult
