import { Button, withStyles } from '@material-ui/core'

const OutlinedButton = withStyles(() => {
    return {
        root: {
            background: 'transparent',
            color: '#747474',
            fontWeight: '700',
            minHeight: 42,
            border: '1px solid #d3c7c7',
            borderRadius: 25,
            fontSize: 18,
            lineHeight: '21px',
            textTransform: 'capitalize',
            boxShadow: 'none',
            '&:hover': {
                background: 'inherit',
                boxShadow: 'none'
            },
            '&:focus': {
                background: 'inherit',
                boxShadow: 'none'
            }
        }
    }
})(Button)

export default OutlinedButton
