import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCarbonResponseData } from '../../reducer/extension'
import SurveyCardList from '../ActionItems/SurveyCardList'
import { ShowSurveyQuestions } from './ShowSurveyQuestions'

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        backgroundColor: '#FBFBFB',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4)
        }
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        padding: 10
    },
    forDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    forMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}))

const TakeCarbonSurvey = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [surveyToShow, setSurveyToShow] = useState(null)

    const { carbonSurveyQuestions } = useSelector((state) => state.extension)
    useEffect(() => {
        // console.log(carbonSurveyQuestions)
        setSurveyToShow(carbonSurveyQuestions)
    }, [setSurveyToShow, carbonSurveyQuestions])

    if (!surveyToShow) {
        return null
    }

    return (
        <Box className={classes.container}>
            {/* {console.log(surveyToShow)} */}
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={false} md={4}>
                        <div className={classes.forDesktop}>
                            <SurveyCardList activeId={1} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.center}>
                        <ShowSurveyQuestions
                            surveyName={'Carbon Footprint'}
                            surveyKey={1}
                            surveyQuestions={surveyToShow}
                            submitSurvey={(data) => {
                                return dispatch(setCarbonResponseData(data))
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default TakeCarbonSurvey
