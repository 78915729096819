import { Grid, Typography } from '@material-ui/core'
import CustomSlider from './CustomProgressBar'

const CustomRangeSlider = ({ value, min, max, onChange }) => (
    <Grid container spacing={0} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
            <CustomSlider value={value} min={min} max={max} onChange={onChange} aria-labelledby="continuous-slider" />
        </Grid>
        <Grid item xs={1}>
            <Typography variant="body1" style={{ fontSize: 14, fontWeight: '500' }} align="left">
                {min}
            </Typography>
        </Grid>
        <Grid item xs={10} />
        <Grid item xs={1}>
            <Typography
                style={{
                    display: 'inline-block',
                    fontSize: 14,
                    fontWeight: '500',
                    width: '100%'
                }}
                variant="body1"
                align="right"
            >
                {max}
            </Typography>
        </Grid>
    </Grid>
)

export default CustomRangeSlider
