import { makeStyles, Typography } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import goBackIcon from '../../assets/icons/left-arrow.svg'
import goNextIcon from '../../assets/icons/right-arrow.svg'
import ButtonContainer from '../Customized/ButtonContainer'
import RoundedButton from '../Customized/RoundedButton'
import './rcb.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useSelector } from 'react-redux'
const localizer = momentLocalizer(moment)

const useStyles = makeStyles(() => ({
    isEnabled: {
        backgroundColor: '#fafafa'
    },
    isDisabled: {
        backgroundColor: '#dddddddd'
    },
    calendarHeader: {
        width: '100%',
        height: '100%',
        display: 'grid',
        placeItems: 'center',
        '& > p': {
            margin: '0.4rem',
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: '700',
            fontSize: 14,
            color: '#eb5757'
        }
    },
    toolbar: {
        display: 'grid',
        gridTemplate: '1fr / repeat(3, 1fr)',
        placeItems: 'center',
        marginBottom: '1rem',
        minHeight: 40,
        '& > img': {
            height: 20,
            marginTop: 5
        },
        '& > p': {
            color: '#eb5757',
            fontSize: 20,
            fontWeight: '600',
            margin: 0
        }
    },
    dateCell: {
        width: '100%',
        height: '100%',
        display: 'grid',
        placeItems: 'center',
        '& > p': {
            margin: '0.4rem',
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: '700',
            fontSize: 14,
            color: '#333333'
        }
    },
    event: {
        '& > p': {
            display: 'grid',
            placeItems: 'center',
            background: '#f2994a',
            borderRadius: '50%',
            height: 35,
            width: 35,
            padding: '0px',
            border: '2px solid #eb5757',
            color: 'white'
        }
    }
}))

function changeOffest(momentObject, add = true) {
    return moment(momentObject)
    // .add(
    //   (add ? -1 : 1) * new Date().getTimezoneOffset(),
    //   "minutes"
    // );
}
function CustomCalendar({ startDate, loggedDates, onSave, infoSection, index, goToDashboard, openCamera }) {
    const [selectedDates, setSelectedDates] = useState([])
    const [unSelectedDates, setUnselectedDates] = useState([])
    const { pledgesList } = useSelector((state) => state.applicationInfo)
    const classes = useStyles()
    const toolbarRender = useCallback(
        (data) => (
            <React.Fragment>
                <div className={classes.toolbar}>
                    <button onClick={() => data.onNavigate('PREV')}>
                        <img alt="left-arrow" src={goBackIcon} />
                    </button>
                    <p>{moment(data.date).format('MMM YYYY')}</p>
                    <button onClick={() => data.onNavigate('NEXT')}>
                        <img alt="right-arrow" src={goNextIcon} />
                    </button>
                </div>
                {infoSection}
            </React.Fragment>
        ),
        [infoSection, classes]
    )
    const headerRender = useCallback(
        ({ label }) => (
            <div className={classes.calendarHeader}>
                <p>{label}</p>
            </div>
        ),
        [classes]
    )
    const events = loggedDates
    const dateRender = useCallback(
        ({ label, date }) => {
            const inRange = moment(date).isSameOrBefore(moment()) && moment(date).isSameOrAfter(moment(startDate))
            const hasEvent = events.find(
                (event) =>
                    moment(date).isSame(changeOffest(event, false), 'date') &&
                    moment(date).isSame(changeOffest(event, false), 'month')
            )
            const isPresentInUnselectedDate = !!_.find(unSelectedDates, (singleDate) =>
                changeOffest(singleDate).isSame(changeOffest(date), 'date')
            )
            const isPresentInSelectedDate = !!_.find(selectedDates, (singleDate) =>
                changeOffest(singleDate).isSame(changeOffest(date), 'date')
            )

            return (
                <button
                    onClick={() => {
                        if (!inRange) {
                            return
                        }
                        if (hasEvent) {
                            if (isPresentInUnselectedDate) {
                                setUnselectedDates((pa) =>
                                    pa.filter(
                                        (singleDate) => !changeOffest(singleDate).isSame(changeOffest(date), 'date')
                                    )
                                )
                            } else {
                                setUnselectedDates((pa) => [
                                    ...pa,
                                    moment(changeOffest(date)).format('YYYY-MM-DD') + 'T00:00:00.000Z'
                                ])
                            }
                        } else {
                            if (isPresentInSelectedDate) {
                                setSelectedDates((pa) =>
                                    pa.filter(
                                        (singleDate) => !changeOffest(singleDate).isSame(changeOffest(date), 'date')
                                    )
                                )
                            } else {
                                setSelectedDates((pa) => [
                                    ...pa,
                                    moment(changeOffest(date)).format('YYYY-MM-DD') + 'T00:00:00.000Z'
                                ])
                            }
                        }
                    }}
                    className={`${classes.dateCell} ${
                        (hasEvent && !isPresentInUnselectedDate) || isPresentInSelectedDate ? classes.event : ''
                    } ${inRange ? classes.isEnabled : classes.isDisabled}`}
                    style={{ padding: '0px' }}
                >
                    <p style={{ margin: 0 }}>{label}</p>
                </button>
            )
        },
        [events, unSelectedDates, selectedDates, startDate, classes]
    )

    const isDatesModified = useMemo(() => {
        return selectedDates.length > 0 || unSelectedDates.length > 0
    }, [selectedDates, unSelectedDates])
    return (
        <>
            <Calendar
                localizer={localizer}
                events={events}
                defaultView="month"
                components={{
                    toolbar: toolbarRender,
                    month: {
                        header: headerRender,
                        dateHeader: dateRender
                    }
                }}
                views={['month']}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 450 }}
            />
            {/* && isDatesModified */}
            {pledgesList[index].isPledgeCompleted !== true ? (
                <ButtonContainer>
                    <RoundedButton
                        fullWidth
                        style={{ margin: '10px 0' }}
                        onClick={onSave(selectedDates, unSelectedDates)}
                    >
                        <Typography>Save</Typography>
                    </RoundedButton>
                </ButtonContainer>
            ) : (
                <ButtonContainer>
                    {/* <RoundedButton fullWidth style={{margin:'5px 0'}} onClick={openCamera}>
                        <Typography>Celebrate with a Photo!</Typography>
                    </RoundedButton> */}
                    <RoundedButton fullWidth style={{ margin: '10px 0' }} onClick={goToDashboard}>
                        <Typography>Go to Dashboard</Typography>
                    </RoundedButton>
                </ButtonContainer>
            )}
        </>
    )
}

export default CustomCalendar
