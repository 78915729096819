import React from 'react'
import { useSelector } from 'react-redux'
import Chart from '../components/chart'

import './extensionEntry.css'

function ExtensionResult() {
    const { footprintResult, email, isC4LUser } = useSelector((state) => state.extension)
    const { message, title } = useSelector((state) => state.extension.resultMessage)

    for (var key in footprintResult?.footprintCategories) {
        if (footprintResult.hasOwnProperty(key)) {
            // console.log(key, footprintResult[key])
        }
    }

    return (
        <div className="extensionEntry">
            <div className="text" style={{ marginTop: '64px' }}>
                Thank You!
            </div>
            <div className="text">{title}</div>
            <div className="text" style={{ fontSize: '16px' }}>
                {message}
            </div>
            <div
                style={{
                    background: '#FFFFFF',
                    boxShadow: '4px 4px 12px rgba(191, 45, 99, 0.2)',
                    borderRadius: '25px',
                    width: '300px',
                    height: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Chart
                    data={[
                        {
                            color: '#27E2B3',
                            text: 'Food',
                            percentage:
                                (footprintResult?.footprintCategories.Food / footprintResult?.footprintValue) * 100
                        },
                        {
                            color: '#F8C42D',
                            text: 'Transport',
                            percentage:
                                (footprintResult?.footprintCategories.Transport / footprintResult?.footprintValue) * 100
                        },
                        {
                            color: '#F23255',
                            text: 'Fuel & Power',
                            percentage:
                                (footprintResult?.footprintCategories.Fuel_and_power /
                                    footprintResult?.footprintValue) *
                                100
                        }
                    ]}
                    InternalText={`${footprintResult?.footprintValue.toFixed(2)} ${footprintResult?.footprintUnits}`}
                />
            </div>
            {email.split('@')[1] === 'tcs.com' && !isC4LUser && (
                <>
                    <div className="text" style={{ marginTop: '32px', fontSize: '16px' }}>
                        Scan the QR code below to download the application and unlock more exciting features with TCS
                        Circle4Life
                    </div>
                    <div className="qr-code">
                        <img
                            width="200px"
                            src="https://images-eu.ssl-images-amazon.com/images/I/41c9F9wvYIL.png"
                            alt="QR Code"
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default ExtensionResult
