import { Typography } from '@material-ui/core'
import React from 'react'
import styles from './styles.module.css'

// Component to handle the progress bar changes
export const ProgressBar = ({ percentage, current, total }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1 }} className={styles.progress}>
            <div style={{ width: `${percentage}%` }}></div>
            <div />
        </div>
        <Typography style={{ padding: '0px 20px' }}>
            {current} / {total}
        </Typography>
    </div>
)
