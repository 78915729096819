import React from 'react'
import JotformEmbed from 'react-jotform-embed'
function EmployeeCommute() {
    return (
        <div>
            <JotformEmbed src="https://form.jotform.com/223617364919060" />
        </div>
    )
}

export default EmployeeCommute
