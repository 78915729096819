import { makeStyles } from '@material-ui/core'
import CardCarbonIcon from '../../icon/CardCarbonIcon'
import CardConsumptionIcon from '../../icon/CardConsumptionIcon'
import CardPlasticIcon from '../../icon/CardPlasticIcon'
import CardWaterIcon from '../../icon/CardWaterIcon'
const useStyles = makeStyles(() => ({
    cardContainer: {
        paddingRight: 5,
        paddingLeft: 5
    },
    label: {
        marginBottom: 10,
        padding: 15,
        borderRadius: 15,
        boxShadow: '4px 4px 7px #DDDDDD99',
        display: 'flex',
        textAlign: 'center'
    },
    infoBox: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    font: {
        margin: 0,
        padding: 0,
        textAlign: 'left',
        font: 'normal normal normal 16px/20px Roboto',
        fontWeight: '500'
    },
    mini_font: {
        margin: 0,
        padding: 0,
        textAlign: 'left',
        font: 'normal normal normal 12px/20px Roboto',
        fontWeight: '300'
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const SurveyCard = ({ name, desc, status }) => {
    const classes = useStyles()
    return (
        <div className={classes.makeContained}>
            <label
                className={classes.label}
                style={{
                    background: status === 'active' ? '#4E84C4' : status === 'completed' ? '#01AF7B' : 'white',
                    color: ['completed', 'active'].includes(status) ? 'white' : '#999999'
                }}
            >
                <div className={classes.infoBox}>
                    <p
                        className={classes.font}
                        style={{
                            color: ['completed', 'active'].includes(status) ? 'white' : '#999999'
                        }}
                    >
                        {name}
                    </p>
                    <p
                        className={classes.mini_font}
                        style={{
                            color: ['completed', 'active'].includes(status) ? 'white' : '#999999'
                        }}
                    >
                        {desc}
                    </p>
                </div>
                <div className={classes.center}>
                    {name === 'Carbon' && <CardCarbonIcon size={50} status={status} />}
                    {name === 'Consumption' && <CardConsumptionIcon size={50} status={status} />}
                    {name === 'Water' && <CardWaterIcon size={50} status={status} />}
                    {name === 'Plastic' && <CardPlasticIcon size={50} status={status} />}
                </div>
            </label>
        </div>
    )
}

export default SurveyCard
