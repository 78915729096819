import { AccordionSummary, withStyles } from '@material-ui/core'

const CustomAccordionSummary = withStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
        boxShadow: 'none',
        border: '1px solid #CCCCCC',
        borderRadius: 6,
        background: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 64,
        '& p': {
            display: 'flex',
            alignItems: 'center'
        },
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            boxShadow: '4px 4px 8px #DDDDDD99',
            background: 'white',
            margin: 0,
            border: '1px solid white',
            color: '#4E84C4',
            '& p': {
                fontWeight: '700',
                display: 'flex',
                alignItems: 'center'
            }
        }
    },
    content: {
        margin: '12px 0px',

        '&$expanded': {
            margin: '12px 0px'
        }
    },
    expanded: {}
}))(AccordionSummary)

export default CustomAccordionSummary
