import React from 'react'
const MiniCardWaterIcon = ({ status = 'inactive', size = 24 }) => {
    if (status === 'active') {
        return (
            <svg
                viewBox="0 0 15.79 24.01"
                width={size}
                height={size}
                strokeWidth="0.5"
                stroke="#4E84C4"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <path d="M14.56,11.75C13.9,10.21,13,8.47,11.84,6.41,9.9,3,8,.05,7.9,0S7.4.77,6.66,1.94,4.93,4.69,4,6.41a58.21,58.21,0,0,0-2.72,5.34A12.06,12.06,0,0,0,0,16.1a7.89,7.89,0,0,0,4.82,7.29,8,8,0,0,0,6.15,0,7.89,7.89,0,0,0,4.82-7.29A12.06,12.06,0,0,0,14.56,11.75Zm-5.64,10-.1.05-.1.05c-.05,0-.1,0-.16,0L8.41,22c-.05,0-.1.06-.15.06a.28.28,0,0,0-.2,0H6.61c-.1,0-.25,0-.36,0L6.05,22h-.2a5.66,5.66,0,0,1-2.56-1.53,6.69,6.69,0,0,1-1.54-2.57v-.2l-.05-.2c0-.05-.05-.1-.05-.2a.46.46,0,0,0,0-.2,3.49,3.49,0,0,1-.05-.72c.29-.12.29-.17.29-.23v-.51a.36.36,0,0,1,.06-.2A.17.17,0,0,1,2,15.24c0-.05.05-.1.05-.15s0-.1,0-.16l.05-.1,0-.1h.1v1.33a9.48,9.48,0,0,0,.36,1.29,5.51,5.51,0,0,0,.61,1.18,5.33,5.33,0,0,0,.88,1,7.69,7.69,0,0,0,1,.87l.31.16.15.1.06.05.05.05.15.1.15.05c.06,0,.1.05.16.05L6.3,21a5.67,5.67,0,0,0,1.28.36H8.92Z" />
                    </g>
                </g>
            </svg>
        )
    }
    if (status === 'completed') {
        return (
            <svg
                viewBox="0 0 13.51 24"
                width={size}
                height={size}
                strokeWidth="0.5"
                stroke="#12AF7B"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <path d="M14.56,11.75C13.9,10.21,13,8.47,11.84,6.41,9.9,3,8,.05,7.9,0S7.4.77,6.66,1.94,4.93,4.69,4,6.41a58.21,58.21,0,0,0-2.72,5.34A12.06,12.06,0,0,0,0,16.1a7.89,7.89,0,0,0,4.82,7.29,8,8,0,0,0,6.15,0,7.89,7.89,0,0,0,4.82-7.29A12.06,12.06,0,0,0,14.56,11.75Zm-5.64,10-.1.05-.1.05c-.05,0-.1,0-.16,0L8.41,22c-.05,0-.1.06-.15.06a.28.28,0,0,0-.2,0H6.61c-.1,0-.25,0-.36,0L6.05,22h-.2a5.66,5.66,0,0,1-2.56-1.53,6.69,6.69,0,0,1-1.54-2.57v-.2l-.05-.2c0-.05-.05-.1-.05-.2a.46.46,0,0,0,0-.2,3.49,3.49,0,0,1-.05-.72c.29-.12.29-.17.29-.23v-.51a.36.36,0,0,1,.06-.2A.17.17,0,0,1,2,15.24c0-.05.05-.1.05-.15s0-.1,0-.16l.05-.1,0-.1h.1v1.33a9.48,9.48,0,0,0,.36,1.29,5.51,5.51,0,0,0,.61,1.18,5.33,5.33,0,0,0,.88,1,7.69,7.69,0,0,0,1,.87l.31.16.15.1.06.05.05.05.15.1.15.05c.06,0,.1.05.16.05L6.3,21a5.67,5.67,0,0,0,1.28.36H8.92Z" />
                    </g>
                </g>
            </svg>
        )
    } else {
        return (
            <svg
                viewBox="0 0 13.51 24"
                width={size}
                height={size}
                strokeWidth="0.5"
                stroke="#999999"
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g>
                    <g>
                        <path d="M14.56,11.75C13.9,10.21,13,8.47,11.84,6.41,9.9,3,8,.05,7.9,0S7.4.77,6.66,1.94,4.93,4.69,4,6.41a58.21,58.21,0,0,0-2.72,5.34A12.06,12.06,0,0,0,0,16.1a7.89,7.89,0,0,0,4.82,7.29,8,8,0,0,0,6.15,0,7.89,7.89,0,0,0,4.82-7.29A12.06,12.06,0,0,0,14.56,11.75Zm-5.64,10-.1.05-.1.05c-.05,0-.1,0-.16,0L8.41,22c-.05,0-.1.06-.15.06a.28.28,0,0,0-.2,0H6.61c-.1,0-.25,0-.36,0L6.05,22h-.2a5.66,5.66,0,0,1-2.56-1.53,6.69,6.69,0,0,1-1.54-2.57v-.2l-.05-.2c0-.05-.05-.1-.05-.2a.46.46,0,0,0,0-.2,3.49,3.49,0,0,1-.05-.72c.29-.12.29-.17.29-.23v-.51a.36.36,0,0,1,.06-.2A.17.17,0,0,1,2,15.24c0-.05.05-.1.05-.15s0-.1,0-.16l.05-.1,0-.1h.1v1.33a9.48,9.48,0,0,0,.36,1.29,5.51,5.51,0,0,0,.61,1.18,5.33,5.33,0,0,0,.88,1,7.69,7.69,0,0,0,1,.87l.31.16.15.1.06.05.05.05.15.1.15.05c.06,0,.1.05.16.05L6.3,21a5.67,5.67,0,0,0,1.28.36H8.92Z" />
                    </g>
                </g>
            </svg>
        )
    }
}
export default MiniCardWaterIcon
