import { makeStyles, Typography } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
    result: {
        fontWeight: '700',
        textTransform: 'capitalize',
        marginBottom: theme.spacing(3),
        fontSize: 24
    }
}))

const ShowResult = ({ value = 'NA', unit = '', className = '', style }) => {
    const classes = useStyles()
    return (
        <Typography variant="subtitle1" className={`${classes.result} ${className}`} style={{ ...style }}>
            {value.toFixed(2)} {unit}
        </Typography>
    )
}

export default ShowResult
