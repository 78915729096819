import { makeStyles } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import SurveyCard from './SurveyCard'

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 5px 5px 5px',
        justifyContent: 'flex-start'
    }
}))

function SurveyCardList({ activeId, showCompleted = true }) {
    const classes = useStyles()
    const surveyList = useSelector((state) => state.applicationInfo.allFootprintSurveys)
    const keys = useRef(['', 'Carbon', 'Plastic', 'Water', 'Consumption'])
    const [rendererList, setRendererList] = useState([])
    useEffect(() => {
        setRendererList(
            surveyList.map((singleSurvey) => ({
                id: singleSurvey.id,
                desc: 'Approx 2 min',
                status:
                    !singleSurvey.isAllowed &&
                    singleSurvey.lastSubmissionValue >= 0.0 &&
                    activeId !== singleSurvey.sequenceNo &&
                    showCompleted
                        ? 'completed'
                        : activeId === singleSurvey.sequenceNo
                        ? 'active'
                        : 'inactive',
                name: keys.current[singleSurvey.sequenceNo]
            }))
        )
    }, [surveyList, activeId, showCompleted])
    return (
        <div className={classes.container}>
            {rendererList.map(
                (item) =>
                    // Hiding plastic footprint survey
                    item.name !== 'Plastic' && (
                        <SurveyCard key={item.id} name={item.name} desc={item.desc} status={item.status} />
                    )
            )}
        </div>
    )
}

export default SurveyCardList
