import React from 'react'
import sustainability from '../assets/homepage_assets/sustainability.svg'
import underline_red from '../assets/homepage_assets/underline_red.svg'
import Text from '../components/homepage_components/Text'
import './features.css'
const Features = () => {
    const features = [
        {
            title: 'Sustainability',
            color: '#63C19D',
            description:
                'Sustainability for employees involves adopting practices that prioritize environmental, social, and economic well-being within the workplace. This includes minimizing waste, promoting diversity and inclusion, and fostering a healthy work-life balance. Ultimately, it aims to create a positive impact on both the organization and the broader community. ',
            image: sustainability
        }
        // {
        //   title: "Fitness",
        //   color: "#F15930",
        //   description:
        //     "Fitness for employees encompasses maintaining physical, mental, and emotional well-being to perform optimally in the workplace. This involves regular exercise, proper nutrition, adequate rest, and stress management techniques. By prioritizing fitness, employees can enhance productivity, reduce absenteeism, and improve overall job satisfaction. ",
        //   image: fitness,
        // },
        // {
        //   title: "Purpose",
        //   color: "#F03882",
        //   description:
        //     "Corporate Social Responsibility (CSR) for employees involves creating a sense of purpose and fulfillment by engaging in activities that benefit society and the environment. This includes volunteering, community outreach programs, and initiatives to support social causes.",
        //   image: pupose,
        // },
    ]
    return (
        <div className="features">
            <Text $weight="600" $color="#000000" $font="Houschka Rounded Alt" $size="37px" $line_height="45px">
                Some Excellent{' '}
                <span className="decorated_text">
                    Features
                    <img className="underline_image" alt="red underline for design" src={underline_red} />
                </span>{' '}
                for you
            </Text>
            <div className="features_container">
                {features.map(({ title, color, description, image }, index) => {
                    return (
                        <div className="feature_box" key={index} style={{ backgroundColor: `${color}` }}>
                            <div className="feature_box_inner_container">
                                <img className="feature_img" src={image} alt="image" />
                                <Text
                                    $weight="600"
                                    $size="31px"
                                    $line_height="38px"
                                    $font="Houschka Rounded Alt"
                                    $color="#000000"
                                >
                                    {title}
                                </Text>
                                <Text
                                    $size="16px"
                                    $line_height="25px"
                                    $font="Poppins"
                                    $color="#707070"
                                    $alignment="center"
                                >
                                    {description}
                                </Text>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Features
